/* eslint-disable */
import React from 'react';
import SopPage from '../Pages/Sop/SopPage';
import SopListPage from '../Pages/Sop/SopListPage';
import SopViewPage from '../Pages/Sop/SopViewPage';
import SopTemplatePage from '../Pages/Sop/SopTemplatePage';
import SopTemplateViewPage from '../Pages/Sop/SopTemplateViewPage';
import SopAiPage from '../Pages/Sop/SopAiPage';

const SopRouter = [
    {
        path: '/sop',
        element: <SopPage />,
    },
    {
        path: '/sop/list',
        element: < SopListPage />,
    },

    {
        path: '/sop/list/:id',
        element: <SopViewPage />,
    },

    {
        path: '/sop/templateview/:id',
        element: <SopTemplateViewPage />,
    },

    {
        path: '/sop/template',
        element: <SopTemplatePage />,
    },

    {
        path: '/sop/ai',
        element: <SopAiPage />,
      },
];

export default SopRouter;
