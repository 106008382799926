/* eslint-disable react/prop-types */
import { Box, Button, HStack, Heading, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, SimpleGrid, Spacer, Stack } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { FcTemplate } from 'react-icons/fc'
import CardMyKanban from '../../../Components/Card/CardMyKanban'
import { clientTypessense } from '../../../Api/Typesense'
import { useNavigate } from 'react-router-dom'

function SopModalTemplate({ isOpen, onClose, globalStateProps }) {

  const [data, setData] = useState([])
  const navigate = useNavigate()

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 8;

  const getData = async (q) => {
    const searchParameters = {
      q: q ? q : '*',
      query_by: 'title',
      filter_by: `companyId: ${globalStateProps?.currentCompany} && isTemplate:true`,
      sort_by: '_text_match:desc',
      per_page: q ? 8 : itemsPerPage,
      page: q ? 1 : currentPage

    };
    clientTypessense
      .collections('sop')
      .documents()
      .search(searchParameters)
      .then((x) => {
        const newData = x?.hits?.map((y) => {
          return { ...y.document };
        });
        if (q) {
          setData(newData)
        } else {
          setData([...data, ...newData])
        }
        setTotalPages(x.found);

        // setColumnsData(newData);
      });

  }

  const handleLoadMore = () => {
    setCurrentPage((prev) => prev + 1);
  };


  useEffect(() => {
    getData()

  }, [globalStateProps.currentCompany, currentPage])

  const handleModal = (value) => {
    navigate(`/sop/templateview/${value?.id}`, {state: value})
  }


  return (
    <Stack>
      <Modal isOpen={isOpen} onClose={onClose} size={'3xl'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <HStack>
              <Heading size={'md'}> SOP Template Company</Heading>
              <Spacer />
              <Input placeholder='Search SOP' onChange={(e) => getData(e.target.value)} type='text' w='-moz-min-content' />
            </HStack>
          </ModalHeader>
          <ModalBody>




            <SimpleGrid columns={[2]} gap={3}>
              {data?.map((x, i) =>
                <CardMyKanban key={i} Icon={FcTemplate} title={x.title} onNavigate={() => handleModal(x)} />
              )}
            </SimpleGrid>

            <Stack alignItems={'center'} justifyContent="center" py={5}>
              <Box>
                {data.length < totalPages && (
                  <Button colorScheme={'blue'} fontSize="sm" onClick={handleLoadMore}>
                    Load More
                  </Button>
                )}
              </Box>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='red' mr={3} onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

    </Stack >
  )
}

export default SopModalTemplate