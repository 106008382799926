import React from 'react'
import MainMenuComponent from '../../Components/Menus/MainMenuComponent'

function OkrPage() {
  return (
    <MainMenuComponent menuName='OKR' />

  )
}

export default OkrPage
