/* eslint-disable react/prop-types */
import { Avatar, AvatarBadge, Box, HStack, Icon, Input, Select, SimpleGrid, Stack, Text, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import DynamicButton from '../../../Components/Buttons/DynamicButton'
import ModalInviteUser from '../../../Components/Modals/ModalInviteUser'
import { MdCancel } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import { updateDocumentFirebase } from '../../../Api/firebaseApi';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../../../Config/firebase';
import { FaTag } from 'react-icons/fa';

function KpiTeamComponent({ dataProps, globalStateProps }) {


  const [usersDisplay, setUsersDisplay] = useState([])
  const [users, setUsers] = useState([])
  const [pics, setPics] = useState([]);
  const [dataKpi, setDataKpi] = useState({})
  const [selectedPic, setSelectedPic] = useState('');
  const [selectedProject, setSelectedProject] = useState('')

  const param = useParams()
  const toast = useToast()
  const navigate = useNavigate()


  const [searchResult, setSearchResult] = useState([]);
  const [selectedUserProjectIds, setSelectedUserProjectIds] = useState([]);
  const [isAddingTeam, setIsAddingTeam] = useState(false);
  const [modalProjectUser, setModalProjectUser] = useState(false);
  const [loading, setLoading] = useState(false)

  const [newTags, setNewTags] = useState('');


  const handleNewCategory = (e) => {
    setNewTags((e.target.value).toLowerCase());
  };


  useEffect(() => {

    const unsub = onSnapshot(doc(db, 'kpi', param.id), (doc) => {
      const result = doc.data();
      setDataKpi(result);
      setUsersDisplay(result?.usersDisplay || []);
      setUsers(result?.users || []);
      setPics(result?.pic || []);

    })




    return () => unsub();
  }, [param.id])



  const handleOpenModalProject = () => {
    setModalProjectUser(true);
  };

  const handleRemoveUser = (index) => {
    if (index < 0 || index >= usersDisplay.length) {
      return; // Pastikan index valid
    }

    const updatedUsersDisplay = [...usersDisplay];
    updatedUsersDisplay.splice(index, 1);

    const userToRemove = usersDisplay[index];
    const updatedUsers = users.filter((uid) => uid !== userToRemove.id);
    setUsersDisplay(updatedUsersDisplay)
    setUsers(updatedUsers)

  };

  const addPic = () => {
    if (selectedPic && !pics.find((pic) => pic.uid === selectedPic)) {
      const user = usersDisplay.find((user) => user.id === selectedPic);
      if (user) {
        setPics([
          ...pics,
          { name: user.name, uid: selectedPic, email: user.email },
        ]);
      }
    }
  };

  const removePic = (uid) => {
    const updatedPics = pics.filter((pic) => pic.uid !== uid);
    setPics(updatedPics);
  };

  const handleSubmit = async () => {

    if (globalStateProps.roleCompany !== 'owner' && globalStateProps.roleProject === 'user') {
      return toast({
        title: 'Alert!',
        description: 'You dont have access to edit this project.',
        status: 'warning',
        duration: 9000,
        isClosable: true,
      });
    }
    setLoading(true)



    const updateData = dataKpi;
    updateData.pic = pics || dataKpi.pic;
    updateData.users = users || dataKpi.users
    updateData.usersDisplay = usersDisplay || dataKpi.usersDisplay
    updateData.projectId = selectedProject || dataKpi.projectId

    const collectionName = 'kpi';
    const docName = param.id;

    try {
      await updateDocumentFirebase(
        collectionName,
        docName,
        updateData
      );
      navigate(-1);

      toast({
        status: 'success',
        duration: 9000,
        title: 'Edit Success',
        isClosable: true,
      });

    } catch (error) {
      toast({
        title: 'Error!',
        description: error.message,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }finally{
      setLoading(false)
    }
  };


  const handleRemoveFeature = (index) => {
    const updatedFeatures = [...dataKpi.tags];
    updatedFeatures.splice(index, 1);
    setDataKpi({ ...dataKpi, tags: updatedFeatures });
  };

  const handleAddFeature = async () => {
    const existingFeatures = Array.isArray(dataKpi.tags) ? dataKpi.tags : [];

    const newFeatureList = [...existingFeatures, newTags];
    setDataKpi({ ...dataKpi, tags: newFeatureList });
  };





  return (
    <Stack>

      <Text fontWeight={'bold'}>Project ID</Text>
      <Select
        defaultValue={dataProps?.projectId}
        placeholder="project"
        size="sm"
        onChange={(e) => setSelectedProject(e.target.value)}
      >
        {globalStateProps?.projects?.map((user) => (
          <option key={user.id} value={user.id}>
            {user.name}
          </option>
        ))}
      </Select>

      <Stack>
        <Text fontWeight="bold">Tags</Text>
        <Stack flexDirection={['row']}>
          <Input
            size={'sm'}
            placeholder={'Enter one new tags ...'}
            onChange={handleNewCategory}
          />
          <Stack
            borderRadius={'sm'}
            pos={'relative'}
            borderColor={'gray.300'}
          >
            <DynamicButton
              action={'create'}
              variant='solid'
              size={'sm'}
              onClick={handleAddFeature}
            />
          </Stack>
        </Stack>
        <Box
          shadow="base"
          padding={3}
          border={'0.5px'}
          borderColor={'gray.300'}
          borderRadius={'sm'}
        >
          <HStack
            spacing={2}
            justifyContent={'flex-start'}
            alignItems={'flex-start'}
            w="100%"
          >
            <HStack>

              {dataKpi?.tags?.length > 0 ? (

                <HStack w={'200px'} overflowX={'scroll'} p="2" gap={3}>

                  {dataKpi?.tags?.map((x, i) => (
                    <Stack
                      key={i}
                      p={2}
                      border={'1px'}
                      borderRadius={'sm'}
                      shadow={'base'}
                      pos={'relative'}
                      borderColor={'gray.300'}
                      alignItems="center"
                      minW={'80px'}
                    >
                      <Box
                        cursor={'pointer'}
                        size="xs"
                        borderRadius={'full'}
                        color={'blue.600'}
                        pos={'absolute'}
                        bottom={-2}
                        right={-1}
                      >
                        <FaTag />
                      </Box>
                      <HStack >
                        <Text fontWeight={500} noOfLines={1} fontSize={'2xs'} >
                          {x}
                        </Text>
                        <Icon
                          onClick={() => handleRemoveFeature(i)}
                          cursor="pointer"
                          pos={'absolute'}
                          color={'red.300'}
                          top={-1}
                          right={-1}
                          as={MdCancel}
                          boxSize={4}
                        />
                      </HStack>
                    </Stack>
                  ))}
                </HStack>
              ) : (
                <Text>No Tags data</Text>
              )}
            </HStack>

          </HStack>

        </Box>
      </Stack>



      <SimpleGrid columns={[1]} gap={5}>

        <Stack>
          <Text fontWeight="bold">Team</Text>
          <Box
            shadow="base"
            // bg="white"
            padding={3}
            border={'0.5px'}
            borderColor={'gray.300'}
            borderRadius={'sm'}
          >
            <HStack
              spacing={3}
              justifyContent={'flex-start'}
              alignItems={'flex-start'}
            >
              <HStack>
                <Stack
                  borderRadius={'sm'}
                  pos={'relative'}
                  borderColor={'gray.300'}
                >
                  <DynamicButton
                    action={'create'}
                    variant='solid'
                    size={'sm'}
                    onClick={handleOpenModalProject} />
                </Stack>
                {usersDisplay?.length > 0 ? (

                  <HStack w={'500px'} overflowX={'scroll'} py="2" gap={3}>

                    {usersDisplay?.map((x, i) => (
                      <Stack
                        key={i}
                        p={2}
                        border={'1px'}
                        borderRadius={'sm'}
                        shadow={'base'}
                        pos={'relative'}
                        borderColor={'gray.300'}
                        alignItems="center"
                        minW={'100px'}
                      >
                        <Avatar
                          cursor={'pointer'}
                          size="md"
                          src={x.image || x.name}
                          name={x.name}
                          alt={x}
                        >
                          <AvatarBadge boxSize="1.2em" bg="green.500" />
                        </Avatar>
                        <HStack textTransform={'capitalize'} overflow="hidden">
                          <Text noOfLines={1} fontSize={'2xs'} fontWeight={500} textTransform={'capitalize'}>
                            {x.name}
                          </Text>
                          <Icon
                            onClick={() => handleRemoveUser(i)}
                            cursor="pointer"
                            pos={'absolute'}
                            color={'red.300'}
                            top={-1}
                            right={-1}
                            as={MdCancel}
                            boxSize={4}
                          />
                        </HStack>
                      </Stack>
                    ))}
                  </HStack>
                ) : (
                  <Text>No Team data</Text>
                )}
              </HStack>

            </HStack>


          </Box>
        </Stack>
      </SimpleGrid>

      <Stack>
        <Text fontWeight={'bold'}>PIC</Text>
        <HStack>
          <Select
            value={selectedPic}
            placeholder="user"
            size={'sm'}
            onChange={(e) => setSelectedPic(e.target.value)}
          >
            {usersDisplay?.map((user) => (
              <option key={user.id} value={user.id}>
                {user.name}
              </option>
            ))}
          </Select>
          <DynamicButton size={'sm'} variant={'solid'} action={'create'} onClick={addPic} />
        </HStack>
      </Stack>

      {pics?.map((pic) => {
        return (
          <Box
            key={pic.uid}
            p={2}
            border={'1px'}
            borderRadius={'sm'}
            shadow={'base'}
            pos={'relative'}
            borderColor={'gray.300'}
          >
            <HStack textTransform={'capitalize'} spacing={3}>
              <Avatar
                cursor={'pointer'}
                size="sm"
                src={pic.image || pic.name}
                name={pic.name}
                alt={pic}
              >
                <AvatarBadge boxSize="1.2em" bg="green.500" />
              </Avatar>
              <Text fontWeight={500} textTransform={'capitalize'}>
                {pic.name}
              </Text>
              <Icon
                onClick={() => removePic(pic.uid)}
                cursor="pointer"
                pos={'absolute'}
                color={'red.300'}
                top={-1}
                right={-1}
                as={MdCancel}
                boxSize={4}
              />
            </HStack>
          </Box>
        );
      })}


      <ModalInviteUser
        setSearchResult={setSearchResult}
        setSelectedUserProjectIds={setSelectedUserProjectIds}
        setIsAddingTeam={setIsAddingTeam}
        searchResult={searchResult}
        isAddingTeam={isAddingTeam}
        selectedUserProjectIds={selectedUserProjectIds}
        dataProps={dataProps}
        setModalProjectUser={setModalProjectUser}
        modalProjectUser={modalProjectUser}
        collection={'kpi'}
      />

      <Stack
        pb={5}
      >

        <DynamicButton
          action={'create'}
          title="Save"
          variant={'solid'}
          isLoading={loading}
          onClick={() => handleSubmit()}
        />

      </Stack>

    </Stack>


  )
}

export default KpiTeamComponent