import moment from 'moment';

export default function periodeUtil(dataOkr){
  const { periodType, periodTarget, periodTimestamp } = dataOkr;
    
  let periodStart, periodEnd;
    
  // Mendapatkan objek moment dari periodTimestamp
  const periodMoment = moment.unix(periodTimestamp);
    
  if (periodType === 'Q') {
    const year = periodMoment.year();
    
    let startMonth, endMonth;
    switch (periodTarget) {
    case 0:
      startMonth = 0;
      endMonth = 2;
      break;
    case 1:
      startMonth = 3;
      endMonth = 5;
      break;
    case 2:
      startMonth = 6;
      endMonth = 8;
      break;
    case 3:
      startMonth = 9;
      endMonth = 11;
      break;
    default:
      return;
    }
    
    periodStart = moment([year, startMonth]).unix();
    periodEnd = moment([year, endMonth]).endOf('month').unix();
  }
    
  if (periodType === 'y') {
    const year = periodMoment.year();
    
    periodStart = moment([year, 0]).unix();
    periodEnd = moment([year, 11]).endOf('month').unix();
  }
    
    
  return { periodStart, periodEnd };
}

export function calculateDurationTargetOkr(period, periodStart, periodEnd) {
  let durationTargetOkr = 0;
  
  if (period === 'daily') {
    const durationInDays = Math.round((periodEnd - periodStart) / (24 * 3600));
    durationTargetOkr = durationInDays;
  } else if (period === 'weekly') {
    const durationInWeeks = Math.round(moment.unix(periodEnd).diff(moment.unix(periodStart), 'weeks', true));
    durationTargetOkr = durationInWeeks;
  } else if (period === 'monthly') {
    const startOfMonth = moment.unix(periodStart).startOf('month');
    const endOfMonth = moment.unix(periodEnd).endOf('month');
    const durationInMonths = Math.round(endOfMonth.diff(startOfMonth, 'months', true)); 
    durationTargetOkr = durationInMonths;
  }
  
  return durationTargetOkr;
}

export function calculateAverageProgressPercentage(data) {
  const totalProgressPercentage = data.reduce((total, x) => {
    const limitedStatsProgress = Math.min(x.statsProgress, 100);
    const progressPercentage = limitedStatsProgress / x.durationTargetOkr;
    return total + progressPercentage;
  }, 0);
    
  const averageProgressPercentage = totalProgressPercentage / data.length;
  return averageProgressPercentage;
}

