/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import { AddIcon, CloseIcon } from '@chakra-ui/icons';
import { Avatar, AvatarGroup, Box, Button, Flex, HStack, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spacer, Stack, Text, useToast } from '@chakra-ui/react';
import React from 'react'
import { arrayUnionFirebase } from '../../Api/firebaseApi';
import { clientTypessense } from '../../Api/Typesense';
import useUserStore from '../../Hooks/Zustand/Store';

function ModalInviteUser({
  setSearchResult,
  setSelectedUserProjectIds,
  setIsAddingTeam,
  searchResult,
  isAddingTeam,
  selectedUserProjectIds,
  dataProps,
  setModalProjectUser,
  modalProjectUser,  
  collection
}) {




  const toast = useToast()
  const globalState = useUserStore();


  const handleSearchUsers = (q) => {
    const companyUsers = globalState?.projects?.find(
      (x) => x.id === globalState?.currentProject
    );
    const userChunks = chunkArray(companyUsers?.users, 100);
    
    const searchPromises = userChunks.map((userChunk) => {
      const searchParameters = {
        q: q,
        query_by: 'name,email',
        filter_by: `id: [${userChunk.join(',')}]`,
        sort_by: '_text_match:desc',
      };
    
      return clientTypessense
        .collections('users')
        .documents()
        .search(searchParameters);
    });
    
    Promise.all(searchPromises)
      .then((results) => {
        const combinedResults = results.flatMap((result) => result.hits);
        setSearchResult(combinedResults);
      })
      .catch((error) => {
        toast({
          title: 'Deoapp.com',
          duration: 3000,
          description: error.message,
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });
      });
  };
    
  const chunkArray = (arr, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < arr?.length; i += chunkSize) {
      chunks.push(arr.slice(i, i + chunkSize));
    }
    return chunks;
  };
    
  const handleUserProjectClick = (userId) => {
    setSelectedUserProjectIds((prevIds) => {
      if (prevIds.includes(userId)) {
        return prevIds.filter((id) => id !== userId);
      } else {
        return [...prevIds, userId];
      }
    });
  };
    
  const handleAddTeamProject = async () => {
    try {
      setIsAddingTeam(true);
    
      const promises = selectedUserProjectIds.map(async (x) => {
    
        const data = x;
    
        try {
          await arrayUnionFirebase(
            collection,
            `${dataProps?.id}`,
            'users',
            [`${data?.id}`]
          );
    
          await arrayUnionFirebase(
            collection,
            `${dataProps?.id}`,
            'usersDisplay',
            [data]
          );    

    
        } catch (error) {
          toast({
            title: 'Deoapp.com',
            duration: 3000,
            description: error.message,
            status: 'error',
            position: 'top-right',
            isClosable: true,
          });
        }
      });
    
      await Promise.all(promises);
    
      toast({
        status: 'success',
        title: 'Deoapp Sop',
        description: 'Success adding team to the project',
        duration: 1000,
      });
      setModalProjectUser(false);
      setSelectedUserProjectIds([]);
      setSearchResult([]);
      // getData();
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        duration: 3000,
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    } finally {
      setIsAddingTeam(false);
      // Tambahkan logika atau pesan error yang sesuai di sini
    }
  };


      
  return (
    <Modal
      size={'md'}
      isOpen={modalProjectUser}
      onClose={() => setModalProjectUser(false)}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Project Team</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={1} py={3}>
            <HStack m="1">
              <Input
                type="text"
                placeholder="Search users"
                onChange={(e) => handleSearchUsers(e.target.value)}
              />
            </HStack>

            <Stack>
              <Stack
                maxH={300}
                overflowY={'auto'}
                sx={{
                  '&::-webkit-scrollbar': {
                    w: '2',
                    h: '3',
                  },
                  '&::-webkit-scrollbar-track': {
                    w: '6',
                    h: '5',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    borderRadius: '10',
                    bg: 'gray.200',
                  },
                }}
              >
                {searchResult?.length > 0 ? (
                  searchResult?.map((x, index) => {
                    return (
                      <HStack key={index} p="2" borderBottom="1px">
                        <Avatar
                          name={x.document.name}
                          src={x.document.image ? x.document.image : ''}
                        />
                        <Box>
                          <Text>{x.document.name}</Text>
                          <Text>{x.document.email}</Text>
                        </Box>
                        <Spacer />
                        <Button
                          colorScheme="green"
                          onClick={() => handleUserProjectClick(x.document)}
                        >
                        +
                        </Button>
                      </HStack>
                    );
                  })
                ) : (
                  <></>
                )}
              </Stack>
            </Stack>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Flex gap={5}>
            <AvatarGroup size="sm" gap="1" max={4}>
              {selectedUserProjectIds.length > 0 &&
              selectedUserProjectIds.map((x, i) => (
                <Avatar key={i} name={x?.name} />
              ))}
            </AvatarGroup>
            <Spacer />
            <Button
              isLoading={isAddingTeam}
              leftIcon={<AddIcon boxSize={3} />}
              colorScheme="green"
              onClick={() => handleAddTeamProject()}
            >
            Add Team
            </Button>
            <Button
              leftIcon={<CloseIcon boxSize={3} />}
              colorScheme="red"
              onClick={() => {
                setModalProjectUser(false);
              }}
            >
            Cancel
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ModalInviteUser