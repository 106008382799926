import React from 'react';
import ProjectsPage from '../Pages/Configurations/Projects/ProjectPage';
import ProjectsViewPage from '../Pages/Configurations/Projects/ProjectViewPage';
import UsersPage from '../Pages/Configurations/Users/UsersPage';
import ConfigurationPage from '../Pages/Configurations/ConfigurationPage';
// import CategoriesPage from '../Pages/Configurations/Categories/CategoriesPage';


const ConfigurationRouter = [
  {
    path: '/configuration',
    element: <ConfigurationPage />,
  },
  {
    path: '/configuration/projects',
    element: <ProjectsPage />,
  },
  {
    path: '/configuration/projects/:id',
    element: <ProjectsViewPage />,
  },
  {
    path: '/configuration/users',
    element: <UsersPage />,
  },
  // {
  //   path: '/configuration/categories',
  //   element: <CategoriesPage />,
  // }
];

export default ConfigurationRouter;
