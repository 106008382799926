/* eslint-disable react/prop-types */
import {
  Box,
  Divider, HStack, Heading, Spacer, Icon, Stack, useToast, FormLabel, Textarea,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { collection, onSnapshot, query, where } from 'firebase/firestore'
import { auth, db } from '../../../Config/firebase'
import LineAreaChart from '../../../Components/Charts/ApexChart/LineAreaChart'
import { FcNegativeDynamic, FcPositiveDynamic } from 'react-icons/fc'
// import _ from 'lodash'
import LiveTime from '../../../Components/Timer/LiveTime'
import { addDocumentFirebase, getSingleDocumentFirebase} from '../../../Api/firebaseApi'
import AlertDuplicateDialog from '../../../Components/AlertDialog/AlertDuplicateDialog'
import DynamicButton from '../../../Components/Buttons/DynamicButton'

function KpiTemplateView({ dataProps, globalStateProps}) {
  const toast = useToast()

  const [data, setData] = useState()

  const [period, setPeriod] = useState()
  const [loading, setLoading] = useState(false)
  const [alertDuplicate, setAlertDuplicate] = useState(false)

  const getData = async () => {
    try {
      const res = await getSingleDocumentFirebase('kpi', dataProps?.selectedTemplate?.id)
      let currentPeriod = ''
      if (res.period === 'daily') currentPeriod = ('d')
      if (res.period === 'weekly') currentPeriod = ('w')
      if (res.period === 'monthly') currentPeriod = ('M')
      if (res.period === 'quarterly') currentPeriod = 'Q';
      if (res.period === 'annually') currentPeriod = 'y';

      const updatedPeriod = {
        ...res,
        status: currentPeriod,
        kpiTitle: res.title,
        data: [],
      };

      //create unix time for each currentperiod
      const arrCalculatio = [6, 5, 4, 3, 2, 1, 0]
      arrCalculatio.map((x) =>
        updatedPeriod.data.push({
          title: moment().startOf(currentPeriod).subtract(x, currentPeriod).format(currentPeriod === 'd' ? 'DD-MM-YY' : `${currentPeriod}-YY`),
          startDate: moment().startOf(currentPeriod).subtract(x, currentPeriod).unix(),
          endDate: moment().endOf(currentPeriod).subtract(x, currentPeriod).unix(),
        })
      );
      setPeriod(updatedPeriod);

    } catch (error) {
      toast({
        title: 'Error',
        description: error.message,
        duration: 3000,
        status: 'error',
      });
    }
  }

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    if (!period?.data) return
    const currentUnix = moment().endOf(period.status).subtract(0, period.status).unix()
    const q = query(collection(db, 'kpi_data'),
      where('kpiId', '==', dataProps?.selectedTemplate?.id),
      where('startDate', '>=', parseInt(period?.data[0].startDate)),
      where('startDate', '<=', parseInt(currentUnix))
    )


    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const datas = [];
      querySnapshot.forEach((doc) => {
        datas.push({ id: doc.id, ...doc.data() });
      });

      const result = [];
      datas.reduce(function (res, value) {
        if (!res[value.title]) {
          res[value.title] = { title: value.title, actual: 0, uid: value.uid ? value.uid : undefined };
          result.push(res[value.title])
        }
        res[value.title].actual += value.actual;
        return res;
      }, {});
      setData(result)



    });
    return () => { unsubscribe() }
  }, [period?.data])

  const handleOpenAlert = () => {
    setAlertDuplicate(true)
  }

  const handleCloseAlert = () => {
    setAlertDuplicate(false)
  }

  const handleDuplicate = async () => {
    setLoading(true)

    if (
      globalStateProps?.currentCompany === undefined ||
      globalStateProps?.currentProject === undefined
    ) {
      toast({
        title: 'Deoapp.com',
        description: 'you must set company and project data',
        status: 'warning',
        position: 'top-right',
        isClosable: true,
      });
      return;
    }
    
    const updateData = dataProps?.selectedTemplate
    updateData.companyId = globalStateProps.currentCompany
    updateData.projectId = globalStateProps.currentProject
    updateData.users = [];
    updateData.kpi = [];
    updateData.statsAverage = 0;
    updateData.statsProgress = 0;
    updateData.statsTarget = 0;
    updateData.statsTotalActual = 0;
    updateData.statsTotalTarget = 0;
    (updateData.users = [globalStateProps.uid]);
    (updateData.usersDisplay = [
      {
        id: auth?.currentUser?.uid,
        image: auth.currentUser?.photoURL,
        name: auth?.currentUser?.displayName,
        email: auth?.currentUser?.email,
      },
    ]);
    delete updateData.embedding
    delete updateData.id

    try {
      await addDocumentFirebase(
        'kpi',
        updateData,
        globalStateProps?.currentCompany
      );
      toast({
        status: 'success',
        duration: 2000,
        title: 'Success duplicate this template to your company',
        isClosable: true,
      });
    } catch (error) {
      toast({
        status: 'error',
        duration: 2000,
        title: error.message,
        isClosable: true,
      });
    } finally {
      setLoading(false)
      handleCloseAlert()
    }
  }

  return (
    <Stack >
     
      <HStack flexDir={['column', 'row', 'row']}>
        <Box maxW={['auto', '500px', '700px']}>
          <Heading noOfLines={2} size={'md'} >KPI {period?.kpiTitle}</Heading>

        </Box>
        <Spacer />
        <Stack spacing={0}>

          <HStack flexDir={['column', 'row', 'row']}>
            <Stack spacing={0}>
              {period?.achivement === 'high' ?
                <HStack>
                  <Heading fontSize='md'>Higher is better</Heading>
                  <Icon as={FcPositiveDynamic} boxSize={9} />
                </HStack> :
                <HStack>
                  <Heading fontSize='md'>Lower is better</Heading>
                  <Icon as={FcNegativeDynamic} boxSize={9} />
                </HStack>
              }
              <DynamicButton action={'create'} size='sm' variant={'solid'} title={'Use this template'} onClick={handleOpenAlert} />
             
            </Stack>

          </HStack>


        </Stack>

        <Stack bgColor={'blue.500'} shadow="md" p={2} borderRadius="md">
          <LiveTime color={'white'} />
        </Stack>


      </HStack>
      <Stack>
        <FormLabel>Description</FormLabel>
        <Textarea size={'sm'} rows={3} isDisabled resize="none" defaultValue={dataProps?.selectedTemplate?.description} overflowY={'auto'}
          sx={{
            '&::-webkit-scrollbar': {
              w: '2',
              h: '3',
            },
            '&::-webkit-scrollbar-track': {
              w: '6',
              h: '5',
            },
            '&::-webkit-scrollbar-thumb': {
              borderRadius: '10',
              bg: 'gray.200',
            },
          }}/>

      </Stack>
      <Divider m='2' />
      <Stack p={[1, 1, 5]} shadow='base' borderRadius='md'>

        <LineAreaChart data={data ? {
          data: data,
          period: period
        } : null} />

      </Stack>

      <AlertDuplicateDialog
        isLoading={loading}
        isOpen={alertDuplicate}
        onClose={handleCloseAlert}
        title={'Do you want to duplicate this file to your company'}
        onAction={handleDuplicate}
      />

    </Stack>
  )
}

export default KpiTemplateView