/* eslint-disable react/react-in-jsx-scope */
import { useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, fetchToken } from './Config/firebase';
import {
  arrayUnionFirebase,
  getCollectionFirebase,
  getSingleDocumentFirebase,
} from './Api/firebaseApi';
import useUserStore from './Hooks/Zustand/Store';
import { decryptToken } from './Utils/encrypToken';
import {
  Stack,
} from '@chakra-ui/react';
import AuthRouter from './Router/AuthRouter';
import LoadingOverlay from './Components/Loader/LoadingOverlay';
import EmailVerification from './Pages/Auth/EmailVerification';
import MainRouter from './Router/MainRouter';
import MiddlewareRouter from './Router/MiddlewareRouter';
import { getBillingApps } from './Utils/billingsAccess';

function App() {
  const globalState = useUserStore();

  const handleUsersDisplay = async (companies, currentCompany) => {
    try {
      const findCompany = companies.find((x) => x.id === currentCompany);

      if (!findCompany) {
        return;
      }
    

      const dataUsers = findCompany.users;
      const dataUsersDisplay = [];

      await Promise.all(
        dataUsers.map(async (userId) => {
          try {
            const result = await getSingleDocumentFirebase('users', userId);

            const user = {
              id: userId || '',
              name: result.name || result.displayName,
              email: result.email || '',
              phoneNumber: result.phoneNumber || result.phone,
              image: result.image || '',
            };

            dataUsersDisplay.push(user);
          } catch (error) {
            throw new Error(error.message, 'Failed to send  error message');
          }
        })
      );

      globalState.setUsers(dataUsersDisplay);
    } catch (error) {
      throw new Error(error.message, 'Failed to send  error message');
    }
  };

  const fetchProjectsAndCompanies = async (uid) => {
    let fetchCompanyId = localStorage.getItem('currentCompany');
    let fetchProjectId = localStorage.getItem('currentProject');

    const conditions = [
      {
        field: 'users',
        operator: 'array-contains',
        value: uid,
      },
    ];

    try {
      const [companies, projects] = await Promise.all([
        getCollectionFirebase('companies', conditions),
        getCollectionFirebase('projects', conditions),
      ]);

      if (!fetchCompanyId) {
        // Set data to localStorage only if it's not already set
        fetchCompanyId = companies[0]?.id; // Set the fetched ID to the variable
        const userRoleInCompany = getUserRole(
          companies,
          uid,
          companies[0]?.id,
          'company'
        );

        localStorage.setItem('currentCompany', fetchCompanyId);
        globalState.setCompanies(companies);
        globalState.setCurrentCompany(fetchCompanyId);
        globalState.setCurrentXenditId(companies[0]?.xenditId);
        globalState.setRoleCompany(userRoleInCompany);
        await handleUsersDisplay(companies, fetchCompanyId);

      } else {
        globalState.setCurrentCompany(fetchCompanyId);

        const userRoleInCompany = getUserRole(
          companies,
          uid,
          fetchCompanyId,
          'company'
        );
        globalState.setCompanies(companies);
        globalState.setCurrentXenditId(companies[0]?.xenditId);
        globalState.setRoleCompany(userRoleInCompany);

        await handleUsersDisplay(companies, fetchCompanyId);

      }

      if (!fetchProjectId) {
        if (companies[0]?.id) {
          fetchProjectId = projects[0]?.id;
          const userRoleInProject = getUserRole(
            projects,
            uid,
            fetchProjectId,
            'project'
          );
          localStorage.setItem('currentProject', fetchProjectId);
          globalState.setProjects(projects);
          globalState.setCurrentProject(fetchProjectId);
          globalState.setRoleProject(userRoleInProject);  
              
        }
      } else {
        const userRoleInProject = getUserRole(
          projects,
          uid,
          fetchProjectId,
          'project'
        );

        globalState.setProjects(projects);
        globalState.setCurrentProject(fetchProjectId);
        globalState.setRoleProject(userRoleInProject);


      }
    } catch (error) {
      throw new Error(error.message, 'Failed to send  error message');
    }



  };

  const getMiddle = async () => {
    if(globalState.currentProject !== undefined && globalState?.projects?.length === 0) return
    const resMiddleware = await getBillingApps('O9CWYvOSR6yizvQRrzFl', globalState, globalState?.currentProject)
    let expiredAll = true
    if(resMiddleware?.isExpired === false && resMiddleware?.isProjectApp === true){
      expiredAll = false
    }

    globalState.setExpired(expiredAll)
  }

  useEffect(() => {
    getMiddle()
  
    return () => {
    }
  }, [globalState?.projects])
  

  const getUserRole = (data, uid, dataId) => {
    let findData = {};
    findData = data.find((x) => x.id === dataId);

    if (findData && findData.owner?.includes(uid)) {
      return 'owner';
    } else if (findData && findData?.managers?.includes(uid)) {
      return 'managers';
    } else {
      return 'user';
    }
  };

  const uploadTokenToFirebase = async (token, user) => {
    if (token !== '') {
      const collectionName = 'users';
      const docName = user.uid;
      const field = 'tokenId';
      const values = [token];

      try {
        await arrayUnionFirebase(collectionName, docName, field, values);
      } catch (error) {
        return 'Error occured:', error;
      }
    }
  };

  const getAccessToken = async () => {
    try {
      const result = await getSingleDocumentFirebase('token', 'dropbox');
      const resultData = decryptToken(result?.access_token);
      globalState.setAccessToken(resultData);
    } catch (error) {
      return error;
    }
  };

  const refreshTokenHourly = async () => {
    const user = auth.currentUser;
    if (user) {
      const tokenFirebase = await user.getIdToken(true);
      globalState.setAuthFirebaseToken(tokenFirebase);
      localStorage.setItem('tokenFirebase', JSON.stringify(tokenFirebase));
      getAccessToken();
    }
  };

  useEffect(() => {

    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const token = await fetchToken();
        if (token) {
          await uploadTokenToFirebase(token, user);
        }

        await getAccessToken();
        const tokenFirebase = await user.getIdToken(true);

        globalState.setAuthFirebaseToken(tokenFirebase);
        localStorage.setItem('tokenFirebase', JSON.stringify(tokenFirebase));

        fetchProjectsAndCompanies(user?.uid);

        globalState.setIsLoggedIn(true);
        globalState.setUid(user.uid);
        globalState.setName(user.displayName);
        globalState.setEmail(user.email);


      } else {
        globalState.setIsLoggedIn(false);
      }
    });

    const intervalId = setInterval(refreshTokenHourly, 60 * 60 * 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);
  

  return (
    <Stack position={'relative'} overflow="hidden">
      {globalState?.isLoggedIn ? (
        auth?.currentUser?.emailVerified ? (
          globalState?.expired ? (
            <Stack position={'relative'}>
              <LoadingOverlay />
              <MiddlewareRouter />
            </Stack>
          ) : (
            <Stack position={'relative'}>
              <LoadingOverlay />
              <MainRouter />
            </Stack>
          )
        ) : globalState?.email?.includes('@testing-company.com') === true ? (
          <Stack position={'relative'}>
            <LoadingOverlay />
            <MainRouter />
          </Stack>
        ) : (
          <EmailVerification />
        )
      ) : (
        <AuthRouter />
      )}
    </Stack>
  );
}

export default App;
