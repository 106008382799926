import {
  Box, HStack, Heading, Input, SimpleGrid, Spacer, 
  Button,
  Stack,
  useToast,
  Text,
  Select,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import BackButtons from '../../Components/Buttons/BackButtons'
import CardMyKanban from '../../Components/Card/CardMyKanban'
import { FcFolder, FcImport, FcMoneyTransfer, FcPackage, FcTemplate } from 'react-icons/fc'
import { onGetClientTypesenseFront } from '../../Api/Typesense'
import useUserStore from '../../Hooks/Zustand/Store'
import { useLocation, useNavigate } from 'react-router-dom'
import { getCollectionFirebase } from '../../Api/firebaseApi'
import DynamicButton from '../../Components/Buttons/DynamicButton'

function SopTemplatePage() {
  const globalState = useUserStore();
  const location = useLocation();
  const [data, setData] = useState({template : [], typeActive:'all',search:'', totalPages:1, currentPage:1, typeFilter:{type:'', categoryId: location?.state?.categoryId || ''}, categories: location?.state?.categories || []});
  
  const toast = useToast();
  const navigate = useNavigate();
  
  // hardcode data
  const dataType = ['all', 'income', 'product',  'expense' ];
  const adminId = '0BZDboI1UP5SoXFV1Z4n';
  const isTemplate = true;
  const itemsPerPage = 12;
  
  const getData = async () => {
    if(location?.state?.categories !== undefined) return;
    try{
      globalState.setIsLoading(true);
      const resCategories = await getCollectionFirebase('sop_categories', [{ field: 'companyId', operator: '==', value: adminId }], null, null, null)
      setData((prev) => ({...prev, categories:resCategories}))
      globalState.setIsLoading(false);
    } catch (error) {
      globalState.setIsLoading(false);
      toast({
        title: 'Deoapp.com',
        duration: 3000,
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  const handleTypesenseSearch = async (q) => {
    globalState.setIsLoading(true);
    //typesense parameters
    const query = q ? q : '*';
    let fieldName = 'title, tags';
    const sort = '_text_match:desc';
    let filter = `companyId:=[${adminId}, ${globalState?.currentCompany}] && isTemplate: ${isTemplate}`;
 
    if (data?.typeFilter?.type) {
      fieldName += ',type' ;
      filter += ` && type:="${data?.typeFilter?.type}"`;
    }
    if (data?.typeFilter?.categoryId) {
      fieldName += ',categoryId' ;
      filter += ` && categoryId:=[${data?.typeFilter?.categoryId || location?.state?.categoryId}]`;
    }
    try {
      const searchResults = await onGetClientTypesenseFront(
        query,
        'sop',
        fieldName,
        filter,
        itemsPerPage,
        data?.currentPage,
        sort
      );

      const hits = searchResults.hits.map((hit) => hit.document);
      if (data?.currentPage === 1) {
        setData((prev) => ({
          ...prev,
          template: hits,
          totalPages: Math.ceil(searchResults.found / itemsPerPage)
        }));
      } else {
        setData((prev) => ({
          ...prev,
          template: [...prev.template, ...hits],
          totalPages: Math.ceil(searchResults.found / itemsPerPage)
        }));
      }
      globalState.setIsLoading(false);
    } catch (error) {
      globalState.setIsLoading(false);
      toast({
        title: 'Deoapp.com',
        duration: 3000,
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  const handleLoadMore = () => {
    setData((prev) => ({...prev, currentPage: (prev.currentPage + 1)}))
  };

  useEffect(() => {
    getData()
  }, [])
  
  const handleViewTemplate = (value) => {
    navigate(`/sop/templateview/${value.id}`, { state: {template: value, categories: data?.categories} });
  };

  useEffect(() => {
    // handlie fetch when location state change
    setData((prev) => ({...prev, typeFilter: {...prev.typeFilter, categoryId: location?.state?.categoryId}, currentPage:1}))
  }, [location?.state?.categoryId]);

  useEffect(() => {
    handleTypesenseSearch(data?.search)
  }, [globalState.currentCompany, data?.currentPage, data?.typeFilter]);


  return (
    <Stack p={[1, 1, 5]}>
      <HStack flexDirection={['column', 'column', 'row']} align={'start'} mt={5}>
        <BackButtons />
        <Heading size={'md'}>
          Semua Template SOP Perusahaan
        </Heading>
        <Spacer />
        <Stack align={'end'}>
          <HStack>
            <Input
              variant={'outline'}
              placeholder={'Search Sop'}
              onChange={(e) => setData((prev) => ({...prev, search: e.target.value, currentPage:1}))}
              type="text"
              w="sm"
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleTypesenseSearch(e.target.value);
                }
              }}
            />
            <DynamicButton  action={'read'} variant={'outline'} onClick={() => handleTypesenseSearch(data?.search)} />
          </HStack>
          <Select  maxW={{
            md: 'md',
          }}
          value={data?.typeFilter?.categoryId}
          placeholder="Filter All Categories" onChange={(e) => {
            setData((prev) => ({...prev, typeFilter: {...prev.typeFilter, categoryId: e.target.value}, currentPage:1}))
          }
          }
          >
            {data?.categories?.map((category, i) => { return(
              <option key={i} value={category?.id}>{category?.name}</option>
            )})}
          </Select>
        </Stack>
      </HStack>
      <Stack p={[1, 1, 5]} >
        {dataType.length > 0 && (
          <SimpleGrid columns={[1, 2, 4]} gap={5}>
            {dataType.map((x, index) => {
              const dataView =
                x === 'product' ? (
                  <FcPackage  size={50} />
                ) : x === 'income' ? (
                  <FcMoneyTransfer  size={50} />
                )  : x === 'expense' ? (
                  <FcImport  size={50} />
                )  : (
                  <FcFolder   size={50} />
                );
              return (
                <Stack
                  borderRadius={'md'}
                  borderWidth={2}
                  shadow={'md'}
                  borderColor={x === data?.typeActive ? '#ffd600' : 'blackAlpha.300'}
                  key={index}
                  cursor="pointer"
                  onClick={() => setData((prev) => ({...prev, typeActive: x, currentPage:1, typeFilter: {...prev.typeFilter, type: x === 'all' ? '' : x}}))}
                >
                  <Stack
                    alignItems={'center'}
                    h="150px"
                    justifyContent="center"
                  >
                    <Box>{dataView}</Box>
                    <Text textTransform={'capitalize'}>{x}</Text>
                  </Stack>
                </Stack>
              );
            })}
          </SimpleGrid>
        )}
      </Stack>
      <SimpleGrid columns={{base: 1, sm: 2,md: 3, xl: 4}} gap={3}>
        {data?.template?.map((x, i) => (
          <CardMyKanban
            key={i}
            Icon={FcTemplate}
            title={x.title}
            onNavigate={() => handleViewTemplate(x)}
            tags={x.tags}
            categories={data?.categories}
            categoryId={x.categoryId}
            type={x.type}
          />
        ))}
      </SimpleGrid>

      <Stack alignItems={'center'} justifyContent="center" py={5}>
        <Box>
          {data?.currentPage < data?.totalPages && (
            <Button colorScheme={'blue'} fontSize="sm" onClick={handleLoadMore}>
              {('Load More')}
            </Button>
          )}
        </Box>
      </Stack>
    </Stack>
  );
}

export default SopTemplatePage;
