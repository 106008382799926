import React from 'react';
import OkrPage from '../Pages/Okr/OkrPage';
import OkrListPage from '../Pages/Okr/OkrListPage';
import OkrViewPage from '../Pages/Okr/OkrViewPage';
import OkrTemplatePage from '../Pages/Okr/OkrTemplatePlage';

const OkrRouter = [
  {
    path: '/okr',
    element: <OkrPage />,
  },
  {
    path: '/okr/list',
    element: <OkrListPage />,
  },
  {
    path: '/okr/view/:id',
    element: <OkrViewPage />,
  },
  {
    path: '/okr/template',
    element: <OkrTemplatePage />,
  },

];

export default OkrRouter;
