import moment from 'moment';
import { getCollectionFirebase, getSingleDocumentFirebase } from '../Api/firebaseApi';


export const getAppsDetail = async (appId) => {
  try {
    const resultApps = await getSingleDocumentFirebase(
      'apps',
      appId,
    );
  
    
    return resultApps;
  } catch (err) {
    return err.message
  }
};

export const getBillingApps = async (appId, globalState, projectId) => {

  const conditions = [
    { field: 'companyId', operator: '==', value: globalState?.currentCompany },
    { field: 'appId', operator: '==', value: appId },
    {
      field: 'transaction_status',
      operator: '==',
      value: 'settlement',
    },
    {
      field: 'expiredApp',
      operator: '>=',
      value:  new Date(),
    }
  ];
  
  const sortBy = { field: 'createdAt', direction: 'desc' };
  const limitValue = 1

      
  try {
    const result = await getCollectionFirebase(
      'billings',
      conditions,
      sortBy,
      limitValue
    );


    const resApps = await getAppsDetail(appId)
    const dataRes = {...resApps, ...result[0]}

    const projectData = globalState?.projects?.find((x) => x?.id === projectId)


    const isProjectApp = projectData?.apps?.includes(appId)
    const isExpired = dataRes?.expiredApp === null || dataRes?.expiredApp === undefined ? true : dataRes?.expiredApp?.seconds >= moment(new Date()).valueOf() ? true : false
    const dataReturn = {
      ...dataRes,
      isExpired,
      isProjectApp,
    };



    return dataReturn;
  } catch (err) {
    return err.message
  }
};



