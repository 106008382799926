import { Avatar, AvatarBadge, Box, Button, FormControl, FormLabel, HStack, Icon, Input, Select, Stack, Text } from '@chakra-ui/react'
import React, { useState } from 'react'
import { MdArrowDropDown, MdCancel } from 'react-icons/md'
import { PropTypes } from 'prop-types'


const BoxSelectedComponent = ({ children }) => {
  return (
    <Stack
      p={2}
      border={'1px'}
      borderRadius={'sm'}
      shadow={'base'}
      pos={'relative'}
      borderColor={'gray.300'}
      alignItems="center"
      minW={'100px'}
    >
      {children}
    </Stack>
  )
}

const DynamicMultiSelectComponent = ({
  title,
  listOptions,
  selectedOptions,
  masterData,
  avatar,
  handleAddSelection,
  hanldeDeleteSelection,
  select,
  textInput,
  stringKey
}) => {
  const [inputValue, setInputValue] = useState('');

  return (
    <>
      <FormControl id="category">
        <FormLabel>{title}</FormLabel>
        <Box width={'full'}>
          {select ? <Select
            icon={<MdArrowDropDown />}
            onChange={(e) => handleAddSelection(e.target.value, stringKey)}
            placeholder={`Select multiple ${title}`}
          >
            {listOptions?.map((x, i,) => (
              <option key={i} value={x.id}>{x.title}</option>

            ))}
          </Select>
            :

            textInput ?
              <HStack gap={5}>
                <Input
                  onChange={(e) => setInputValue(e.target.value)}
                  value={inputValue}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      handleAddSelection(inputValue, stringKey);
                      setInputValue('');
                    }
                  }}
                />
                <Button
                  onClick={() => handleAddSelection(inputValue, stringKey)}
                  colorScheme='green'
                >Add</Button>
              </HStack>
              :
              <></>}
        </Box>
      </FormControl>




      <HStack>
        {selectedOptions?.length > 0 ? (
          <HStack maxW={'300px'} overflowX={'scroll'} p="2" gap={3}>

            {masterData ? selectedOptions?.map((x, i) => {
              const findTitle = masterData && masterData?.find((y) => y.id === x)
              return (
                <BoxSelectedComponent key={i}>
                  {avatar && <Avatar
                    cursor={'pointer'}
                    size="md"
                    src={x}
                    name={x}
                    alt={x}
                  >
                    <AvatarBadge boxSize="1.2em" bg="green.500" />
                  </Avatar>}
                  <HStack textTransform={'capitalize'}>


                    <Text fontWeight={500} noOfLines={1} fontSize={'2xs'} textTransform={'capitalize'}>
                      {findTitle?.title}
                    </Text>
                    <Icon
                      onClick={() => hanldeDeleteSelection(i, stringKey)}
                      cursor="pointer"
                      pos={'absolute'}
                      color={'red.300'}
                      top={-1}
                      right={-1}
                      as={MdCancel}
                      boxSize={4}
                    />
                  </HStack>
                </BoxSelectedComponent>
              )
            }) :
              selectedOptions?.map((y, i) => (
                <BoxSelectedComponent key={i}>
                  <Text key={i} fontWeight={500} noOfLines={1} fontSize={'2xs'} textTransform={'capitalize'}>
                    {y}
                  </Text>
                  <Icon
                    onClick={() => hanldeDeleteSelection(i, stringKey)}
                    cursor="pointer"
                    pos={'absolute'}
                    color={'red.300'}
                    top={-1}
                    right={-1}
                    as={MdCancel}
                    boxSize={4}
                  />
                </BoxSelectedComponent>
              ))
            }
          </HStack>
        ) : (
          <Text>No {title} data</Text>
        )}
      </HStack>
    </>
  )
}

export default DynamicMultiSelectComponent



DynamicMultiSelectComponent.propTypes = {
  title: PropTypes.string,
  listOptions: PropTypes.array,
  selectedOptions: PropTypes.array,
  masterData: PropTypes.array,
  avatar: PropTypes.boolean,
  handleAddSelection: PropTypes.func,
  hanldeDeleteSelection: PropTypes.func,
  select: PropTypes.boolean,
  textInput: PropTypes.boolean,
  stringKey: PropTypes.string,
}


BoxSelectedComponent.propTypes = {
  children: PropTypes.func
}