/* eslint-disable no-unsafe-optional-chaining */
import { Box,FormControl, FormLabel, Grid, HStack, Heading, Icon, Select, Spacer, Stack, Text, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import BackButtons from '../../Components/Buttons/BackButtons'
import DynamicButton from '../../Components/Buttons/DynamicButton';
import { MdCancel } from 'react-icons/md'
import { FaTag } from 'react-icons/fa'
import { useLocation, useParams } from 'react-router-dom';
import useUserStore from '../../Hooks/Zustand/Store';
import AlertDuplicateDialog from '../../Components/AlertDialog/AlertDuplicateDialog';
import { addDocumentFirebase, getCollectionFirebase, getSingleDocumentFirebase} from '../../Api/firebaseApi';
import SopPrintButton from './Component/SopPrintButton';

function SopTemplateViewPage() {
  const location = useLocation();
  const globalState = useUserStore();
  const [data, setData] = useState({template: location?.state?.template || {}, categories: location?.state?.categories || []});
  const [loading, setLoading] = useState(false);
  const [alertDuplicate, setAlertDuplicate] = useState(false);
  const param = useParams()
  const toast = useToast();


  const companyIdAdmin = '0BZDboI1UP5SoXFV1Z4n'

  const handleOpenAlert = () => {
    setAlertDuplicate(true);
  };

  const handleCloseAlert = () => {
    setAlertDuplicate(false);
  };

  const getData = async () => {
    if(location?.state?.template !== undefined && location?.state?.template !== null && location?.state?.categories !== undefined && location?.state?.categories !== null) return

    try {
      const result = await getSingleDocumentFirebase('sop', param.id)
      const resCategories = await getCollectionFirebase('sop_categories', [{ field: 'companyId', operator: '==', value: companyIdAdmin }], null, null, null)
      setData((prev) => ({...prev, template: result, categories: resCategories}))
    } catch (error) {
      toast({
        status: 'error',
        duration: 2000,
        title: error.message,
        isClosable: true,
      });
    }
  }

  
  const handleDuplicate = async () => {
    setLoading(true);

    if (
      globalState?.currentCompany === undefined ||
      globalState?.currentProject === undefined
    ) {
      setLoading(false);
      toast({
        title: 'Deoapp.com',
        description: 'you must set company and project data',
        status: 'warning',
        position: 'top-right',
        isClosable: true,
      });
      return;
    }

    const updateData = data;
    updateData.companyId = globalState.currentCompany;
    updateData.projectId = globalState.currentProject;

    delete updateData.embedding;
    delete updateData.id;

    try {
      await addDocumentFirebase('sop', updateData, globalState?.currentCompany);
      toast({
        status: 'success',
        duration: 2000,
        title: 'Success duplicate this template to your company',
        isClosable: true,
      });
    } catch (error) {
      toast({
        status: 'error',
        duration: 2000,
        title: error.message,
        isClosable: true,
      });
    } finally {
      setLoading(false);
      handleCloseAlert();
    }
  };


  useEffect(() => {
    getData()
    return () => {
    }
  }, [])
  

  return (
    <Stack spacing={5} p={[1, 1, 5]}>
      <HStack>
        <BackButtons />
        <Heading size={'md'}> {'Sop View'}</Heading>
        <Spacer />
        <SopPrintButton data={data?.template} globalState={globalState} />
      </HStack>

      <Stack spacing={1}>
        <Heading
          as="h1"
          size="xl"
          p={1}
          contentEditable
          placeholder={'Input title Doc'}
          onBlur={(e) => setData({ ...data, title: e.target.textContent })}
        >
          {data?.template?.title || 'Untitled'}
        </Heading>
        <Text
          p={1}
          contentEditable
          placeholder={'inut description document'}
          onBlur={(e) =>
            setData({ ...data, description: e.target.textContent })
          }
        >
          {data?.template?.description ? data.description : 'Page Description (Optional)'}
        </Text>

        <Grid templateColumns={{ base: '1fr', md: '3fr 1fr' }} gap={3}>
          <Box width="full" m="2">
            <div
              dangerouslySetInnerHTML={{ __html: data?.template?.dataHtml || data?.template?.data }}
              style={{ fontSize: '13px', width: '100%', overflow: 'hidden' }}
            />
          </Box>

          <Stack
            w="full"
            m="2"
            shadow={'md'}
            p="3"
            borderRadius="md"
            alignSelf="flex-start"
          >
            <Stack>
              <Text fontWeight="bold">Tags</Text>
              <Box
                shadow="base"
                // bg="white"
                padding={3}
                border={'0.5px'}
                borderColor={'gray.300'}
                borderRadius={'sm'}
              >
                <HStack
                  spacing={2}
                  p={2}
                  justifyContent={'flex-start'}
                  alignItems={'flex-start'}
                  w="100%"
                >
                  <HStack>
                    <Stack
                      borderRadius={'sm'}
                      pos={'relative'}
                      borderColor={'gray.300'}
                    ></Stack>
                    {data?.template?.tags?.length > 0 ? (
                      <HStack w={'300px'} overflowX={'scroll'} p="2" gap={3}  overflow={'auto'}
                        sx={{
                          '&::-webkit-scrollbar': {
                            w: '2',
                            h: '2',
                          },
                          '&::-webkit-scrollbar-track': {
                            w: '6',
                            h: '5',
                          },
                          '&::-webkit-scrollbar-thumb': {
                            borderRadius: '10',
                            bg: 'gray.200',
                          },
                        }}>
                        {data?.template?.tags?.map((x, i) => (
                          <Stack
                            key={i}
                            p={2}
                            border={'1px'}
                            borderRadius={'sm'}
                            shadow={'base'}
                            pos={'relative'}
                            borderColor={'gray.300'}
                            alignItems="center"
                            minW={'80px'}
                          >
                            <Box
                              cursor={'pointer'}
                              size="xs"
                              borderRadius={'full'}
                              color={'blue.600'}
                              pos={'absolute'}
                              bottom={-2}
                              right={-1}
                            >
                              <FaTag />
                            </Box>
                            <HStack>
                              <Text
                                fontWeight={500}
                                noOfLines={1}
                                fontSize={'2xs'}
                              >
                                {x}
                              </Text>
                              <Icon
                                cursor="pointer"
                                pos={'absolute'}
                                color={'red.300'}
                                top={-1}
                                right={-1}
                                as={MdCancel}
                                boxSize={4}
                              />
                            </HStack>
                          </Stack>
                        ))}
                      </HStack>
                    ) : (
                      <Text fontSize={'sm'}> {'No Tag Data'}</Text>
                    )}
                  </HStack>
                </HStack>
              </Box>
              <FormControl>
                <FormLabel mt="8px" fontWeight={'bold'}> Type</FormLabel>
                {data?.template?.type ? (
                  <Select  maxW={{
                    md: '4xl',
                  }}
                  value={data?.template?.type}
                  placeholder={'Type'}
                  >
                    <option value={data?.template?.type}>{data?.template?.type || 'Type'}</option>
                  </Select>
                ) : ( <Text fontSize={'sm'}> {'No Type Data'}</Text>)}</FormControl>
              
              <>
                <Text fontWeight="bold">Categories</Text>
                <Box
                  shadow="base"
                  // bg="white"
                  padding={3}
                  border={'0.5px'}
                  borderColor={'gray.300'}
                  borderRadius={'sm'}
                >
                  <HStack
                    spacing={2}
                    p={2}
                    justifyContent={'flex-start'}
                    alignItems={'flex-start'}
                    w="100%"
                  >
                    <HStack>
                      <Stack
                        borderRadius={'sm'}
                        pos={'relative'}
                        borderColor={'gray.300'}
                      ></Stack>
                      {data?.template?.categoryId?.length > 0 ? (
                        <HStack w={'300px'} overflowX={'scroll'} p="2" gap={3}  overflow={'auto'}
                          sx={{
                            '&::-webkit-scrollbar': {
                              w: '2',
                              h: '2',
                            },
                            '&::-webkit-scrollbar-track': {
                              w: '6',
                              h: '5',
                            },
                            '&::-webkit-scrollbar-thumb': {
                              borderRadius: '10',
                              bg: 'gray.200',
                            },
                          }}>
                          {data?.template?.categoryId?.map((category, i) => (
                            <Stack
                              key={i}
                              p={2}
                              border={'1px'}
                              borderRadius={'sm'}
                              shadow={'base'}
                              pos={'relative'}
                              borderColor={'gray.300'}
                              alignItems="center"
                              minW={'80px'}
                            >
                              <Box
                                cursor={'pointer'}
                                size="xs"
                                borderRadius={'full'}
                                color={'blue.600'}
                                pos={'absolute'}
                                bottom={-2}
                                right={-1}
                              >
                                <FaTag />
                              </Box>
                              <HStack>
                                <Text
                                  fontWeight={500}
                                  noOfLines={1}
                                  fontSize={'2xs'}
                                >
                                  {data?.categories?.find((x) => x?.id === category)?.name}
                                </Text>
                                <Icon
                                  cursor="pointer"
                                  pos={'absolute'}
                                  color={'red.300'}
                                  top={-1}
                                  right={-1}
                                  as={MdCancel}
                                  boxSize={4}
                                />
                              </HStack>
                            </Stack>
                          ))}
                        </HStack>
                      ) : (
                        <Text fontSize={'sm'}> {'No Categories Data'}</Text>
                      )}
                    </HStack>
                  </HStack>
                </Box>
              </>
              
             
              <DynamicButton
                action={'create'}
                variant={'solid'}
                title={('Use This Template')}
                onClick={handleOpenAlert}
              />
             
            </Stack>
          </Stack>
        </Grid>
      </Stack>

      <AlertDuplicateDialog
        isLoading={loading}
        isOpen={alertDuplicate}
        onClose={handleCloseAlert}
        title={('Do you want to duplicate this file to your company')}
        onAction={handleDuplicate}
      />
    </Stack>
  );
}

export default SopTemplateViewPage;
