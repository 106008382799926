/* eslint-disable no-undef */
import {
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import React, {  } from 'react';
import { AiOutlineAlert } from 'react-icons/ai';
import useUserStore from '../../../Hooks/Zustand/Store';
import DynamicButton from '../../../Components/Buttons/DynamicButton';
import _axiosReadme from '../../../Api/AxiosBarrierReadme';

function BillingsUserPage() {

  const globalState = useUserStore();
  const toast = useToast()


  const handleNavigate = async () => {
    const data = {
      uid: globalState?.uid,
    }


    try {
      const res = await _axiosReadme.post('/user-createCustomToken', data)
      if (res.status === true) {
        // window.open(`${dataTypesense?.redirectUrl}?appId=${filteredApps[0]}&companyId=${deoappState?.currentCompany}&projectId=${projectId}&sessions=${res.customToken}`, '_blank')
        // console.log(`${dataTypesense?.redirectUrl}?appId=${filteredApps[0].appId}&companyId=${deoappState?.currentCompany}&projectId=${projectId}&sessions=${res.customToken}`, '_blank')
        window.open(`https://app-deoapp.web.app/home?companyId=${globalState?.currentCompany}&sessions=${res.customToken}`, '_blank')
      }
    } catch (error) {
      toast({
        title: 'Error!',
        description: error.message,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }

  }
  
  return (
    <Stack minH="70vh" alignItems={'center'} justifyContent="center" spacing={3}>
      <AiOutlineAlert color="gray" size={150} />
      <Text textAlign="center" color={'gray.500'} fontSize="2xl" fontWeight={'bold'}>Warning!</Text>
      <Text fontWeight={500} fontSize="lg" textAlign="center" color={'gray.500'}>Project kamu tidak memiliki akses untuk module ini,</Text>
      <Text fontWeight={500} fontSize="lg" textAlign="center" color={'gray.500'} pb={5}>kamu dapat mengunjungi app.deoapp.com</Text>
      <DynamicButton  onClick={() => handleNavigate()} variant={'solid'} size={'md'} action={'read'} title={'Kunjungi Aplikasi'}/>
    </Stack>

  );
}

export default BillingsUserPage;
