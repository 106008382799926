import React from 'react';
import LoginEmail from '../Pages/Auth/LoginEmail';
import SignUpPage from '../Pages/Auth/SignUpPage';
import RedirectElement from '../Pages/Auth/RedirectElement';
import ForgotPassword from '../Pages/Auth/ForgotPassword';
import EmailVerification from '../Pages/Auth/EmailVerification';
import RedirectElementSessions from '../Pages/Auth/RedirectElementSessions';

const AuthenticationRouter = [

  {
    path: '/:segmen1',
    element: <RedirectElementSessions />,
  },
  {
    path: '/:segmen1/:segmen2',
    element: <RedirectElementSessions />,
  },

  {
    path: '/:segmen1/:segmen2/:segmen3',
    element: <RedirectElementSessions />,
  },

  {
    path: '/:segmen1/:segmen2/:segmen3/:segmen4',
    element: <RedirectElementSessions />,
  },
  {
    path: '/:segmen1/:segmen2/:segmen3/:segmen4/:segmen5',
    element: <RedirectElementSessions />,
  },

  {
    path: '/',
    element: <RedirectElement />,
  },
  {
    path: '/login',
    element: <LoginEmail />,
  },
  {
    path: '/signup',
    element: <SignUpPage />,
  },

  {

    path: '/verificationEmail',
    element: <EmailVerification />,
  },
  {
    path: '/reset-password',
    element: <ForgotPassword />,
  },
];

export default AuthenticationRouter;
