/* eslint-disable react/prop-types */
import {
  HStack,
  Heading,
  Input,
  Stack,
  Text,
  Tooltip,
  useToast,
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { formatFrice } from '../../../Utils/Helper';
import DynamicButton from '../../../Components/Buttons/DynamicButton';
import { auth } from '../../../Config/firebase';
import { addDocumentFirebase } from '../../../Api/firebaseApi';
import { ArrowDownIcon, ArrowUpIcon } from '@chakra-ui/icons';

function BudgetMarketingKpi({
  targetValue,
  budgetValue,
  cacValue,
  quantityProduct,
  globalState,
}) {
  const [closingRatio, setClosingRatio] = useState([30, 50, 70]);
  const [leadsConvertionRate, setLeadsConvertionRate] = useState([30, 50, 70]);
  const [lpRatio, setLpRatio] = useState([70, 50, 30]);
  const [ctr, setCtr] = useState([1, 2, 3]);
  const [frequency, setFrequency] = useState([2, 2, 2]);
  const [kpiData, setKpiData] = useState([]);

  const [loading, setLoading] = useState(false);

  const toast = useToast();

  const calculateValues = (index) => {
    const paid = +(
      (quantityProduct / (closingRatio[index] / 100)) *
      (closingRatio[index] / 100)
    ).toFixed(2);
    const leads = +(quantityProduct / (closingRatio[index] / 100)).toFixed(2);
    const costPerLead = +(budgetValue / leads).toFixed(2);
    const lpViews = +(leads / (leadsConvertionRate[index] / 100)).toFixed(2);
    const outboundClicks = +(lpViews / (lpRatio[index] / 100)).toFixed(2);
    const costPerOutboundClicks = +(budgetValue / outboundClicks).toFixed(2);
    const impression = +(outboundClicks / (ctr[index] / 100)).toFixed(2);
    const cpm = +(budgetValue / (impression / 1000)).toFixed(2);
    const reach = +(impression / frequency[index]).toFixed(2);

    return {
      paid,
      closingRatio: closingRatio[index],
      leads,
      costPerLead,
      leadsConvertionRate: leadsConvertionRate[index],
      lpViews,
      lpRatio: lpRatio[index],
      outboundClicks,
      costPerOutboundClicks,
      ctr: ctr[index],
      impression,
      cpm,
      frequency: frequency[index],
      reach,
    };
  };

  const handleStateChange = (setState, index, newValue) => {
    setState((prevState) => {
      const newState = [...prevState];
      newState[index] = newValue;
      return newState;
    });
  };

  const width = window.innerWidth;

  const handleGenerateKpi = async () => {
    if (
      globalState.currentCompany === undefined ||
      globalState.currentProject === undefined
    ) {
      toast({
        title: 'Deoapp.com',
        description: 'you must set company and project data',
        status: 'warning',
        position: 'top-right',
        isClosable: true,
      });
      return;
    }

    if (kpiData.length === 0) {
      toast({
        title: 'Deoapp.com',
        description: 'you must set kpi',
        status: 'warning',
        position: 'top-right',
        isClosable: true,
      });
      return;
    }

    setLoading(true);

    try {
      const promises = kpiData.map(async (x) => {
        const dataKpiNew = {
          companyId: globalState?.currentCompany,
          projectId: globalState?.currentProject,
          users: [globalState?.uid],
          usersDisplay: [
            {
              id: auth?.currentUser?.uid,
              image: auth.currentUser?.photoURL,
              name: auth?.currentUser?.displayName,
              email: auth?.currentUser?.email,
            },
          ],
          statsAverage: 0,
          statsProgress: 0,
          statsTarget: 0,
          statsTotalActual: 0,
          statsTotalTarget: 0,
          title: x?.label,
          description: 'Automation from simulator',
          target: Math.round(x?.value || 0).toString(),
          isAvarage: x?.isAvarage || false,
          format: x?.model || 'number',
          achivement: x?.achivement || 'hight',
          period: x?.period || 'daily',
        };
        return addDocumentFirebase(
          'kpi',
          dataKpiNew,
          globalState.currentCompany
        );
      });

      const results = await Promise.all(promises);

      results.forEach((res) => {
        toast({
          title: 'Success',
          description: `Data updated ID:${res}`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      });
    } catch (error) {
      toast({
        title: ('Error!'),
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
      setKpiData([]);
    }
  };

  const labels = [
    {
      label: 'Paid',
      type: 'text',
      model: 'number',
      achivement: 'high',
      period: 'daily',
      key: 'paid',
      isAvarage: false,
    },
    {
      label: 'Closing Ratio',
      type: 'input',
      model: 'percentage',
      achivement: 'high',
      period: 'daily',
      setState: setClosingRatio,
      state: closingRatio,
      key: 'closingRatio',
      isAvarage: false,
    },
    {
      label: 'Leads',
      type: 'text',
      model: 'number',
      achivement: 'high',
      period: 'daily',
      key: 'leads',
      isAvarage: false,
    },
    {
      label: 'Cost Per Lead',
      type: 'text',
      model: 'idr',
      achivement: 'low',
      period: 'daily',
      key: 'costPerLead',
      isAvarage: false,
    },
    {
      label: 'Lead Convertion Rate',
      type: 'input',
      model: 'percentage',
      achivement: 'hight',
      period: 'daily',
      setState: setLeadsConvertionRate,
      state: leadsConvertionRate,
      key: 'leadsConvertionRate',
      isAvarage: false,
    },
    {
      label: 'Lp Views',
      type: 'text',
      model: 'number',
      achivement: 'high',
      period: 'daily',
      key: 'lpViews',
      isAvarage: false,
    },
    {
      label: 'Lp Ratio',
      type: 'input',
      model: 'percentage',
      achivement: 'high',
      period: 'daily',
      setState: setLpRatio,
      state: lpRatio,
      key: 'lpRatio',
      isAvarage: false,
    },
    {
      label: 'Outbond Clicks',
      type: 'text',
      model: 'number',
      achivement: 'high',
      period: 'daily',
      key: 'outboundClicks',
      isAvarage: false,
    },
    {
      label: 'Cost Per Outbond Clicks',
      type: 'text',
      model: 'idr',
      achivement: 'low',
      period: 'daily',
      key: 'costPerOutboundClicks',
      isAvarage: false,
    },
    {
      label: 'CTR',
      type: 'input',
      model: 'percentage',
      achivement: 'high',
      period: 'daily',
      setState: setCtr,
      state: ctr,
      key: 'ctr',
      isAvarage: false,
    },
    {
      label: 'Impression',
      type: 'text',
      model: 'number',
      achivement: 'high',
      period: 'daily',
      key: 'impression',
      isAvarage: false,
    },
    {
      label: 'CPM',
      type: 'text',
      model: 'idr',
      achivement: 'low',
      period: 'high',
      key: 'cpm',
    },
    {
      label: 'Frequency',
      type: 'input',
      model: 'number',
      achivement: 'hight',
      period: 'daily',
      setState: setFrequency,
      state: frequency,
      key: 'frequency',
      isAvarage: false,
    },
    {
      label: 'Reach',
      type: 'text',
      model: 'number',
      achivement: 'high',
      period: 'daily',
      key: 'reach',
      isAvarage: false,
    },
  ];

  const handleKpiData = (data, index) => {
    const isSelected = kpiData.some((item) => item.label === data.label);

    if (isSelected) {
      setKpiData((prevData) =>
        prevData.filter((item) => item.label !== data.label)
      );
    } else {
      setKpiData((prevData) => {
        const newData = [...prevData];
        // Hapus semua data yang memiliki indeks yang sama dengan indeks saat ini
        newData.filter((item) => item.index !== index);
        return [
          ...newData,
          { ...data, index, value: calculateValues(index)[data.key] },
        ];
      });
    }
  };
  return (
    <Accordion defaultIndex={[0]} allowMultiple>
      <AccordionItem>
        <Text>
          <AccordionButton>
            <Box as="span" flex="1" textAlign="left">
              <Heading size={'md'} fontWeight={500}>
                {('Budget Marketing')}
              </Heading>
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </Text>
        <AccordionPanel pb={4}>
          <Stack>
            <HStack
              justifyContent={'space-between'}
              fontSize={'sm'}
              fontWeight={500}
            >
              <Stack>
                <Text>Target</Text>
                <Heading size={'md'} textAlign="left">
                  <Text
                    fontSize="sm"
                    verticalAlign="top"
                    display="inline"
                    fontWeight={500}
                    color={'gray.500'}
                  >
                    Rp.
                  </Text>{' '}
                  {formatFrice(targetValue)}
                </Heading>
              </Stack>
              <Stack>
                <Text>{('Budget Ads')}</Text>
                <Heading size={'md'} textAlign="left">
                  <Text
                    fontSize="sm"
                    verticalAlign="top"
                    display="inline"
                    fontWeight={500}
                    color={'gray.500'}
                  >
                    Rp.
                  </Text>{' '}
                  {formatFrice(budgetValue)}
                </Heading>
              </Stack>
              <Stack>
                <Text>{('CAC Value')}</Text>
                <Heading size={'md'} textAlign="left">
                  <Text
                    fontSize="sm"
                    verticalAlign="top"
                    display="inline"
                    fontWeight={500}
                    color={'gray.500'}
                  >
                    Rp.
                  </Text>{' '}
                  {formatFrice(cacValue)}
                </Heading>
              </Stack>
              <Stack>
                <Text>{('Quantity Product')}</Text>
                <Heading size={'md'} textAlign="left">
                  {formatFrice(quantityProduct)}
                </Heading>
              </Stack>
            </HStack>
            <Stack>
              <Stack maxW={width} overflowX={'scroll'} spacing={5}>
                {[0, 1, 2].map((index) => (
                  <HStack
                    w={'full'}
                    key={index}
                    fontSize={'md'}
                    justifyContent={'space-between'}
                    spacing={2}
                  >
                    {labels.map((x, i) => {
                      const isSelected = kpiData.some(
                        (item) => item.label === x.label && item.index === index
                      );
                      const bgColor = isSelected ? 'blue.500' : 'transparent';

                      return (
                        <Stack
                          key={i}
                          onClick={() => handleKpiData(x, index)}
                          borderRadius={'md'}
                          borderWidth={1}
                          p={2}
                          borderColor={bgColor}
                        >
                          <Tooltip label={x.label} aria-label="Name">
                            <Text noOfLines={1} cursor={'pointer'}>
                              {x.label}
                            </Text>
                          </Tooltip>
                          <HStack w={'full'}>
                            {x.achivement === 'low' ? (
                              <ArrowDownIcon color={'red'} />
                            ) : (
                              <ArrowUpIcon color={'green'} />
                            )}
                            {x.type === 'text' ? (
                              <Text fontWeight={'bold'}>
                                {x.model === 'idr' && 'Rp.'}
                                {formatFrice(calculateValues(index)[x.key])}
                              </Text>
                            ) : (
                              <HStack fontWeight={'bold'}>
                                <Input
                                  fontWeight={'bold'}
                                  type="number"
                                  size={'xs'}
                                  w="50px"
                                  value={x.state[index]}
                                  onChange={(e) =>
                                    handleStateChange(
                                      x.setState,
                                      index,
                                      parseInt(e.target.value)
                                    )
                                  }
                                />
                                {x.model === 'percentage' && <Text>%</Text>}
                              </HStack>
                            )}
                          </HStack>
                        </Stack>
                      );
                    })}
                  </HStack>
                ))}

                <Stack alignItems={'center'} justifyContent={'center'}>
                  <DynamicButton
                    isLoading={loading}
                    action={'create'}
                    title={'Generate KPI'}
                    variant={'solid'}
                    onClick={() => handleGenerateKpi()}
                  />
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}

export default BudgetMarketingKpi;
