import {
  FcBriefcase,
  FcCollaboration,
  FcManager,
  FcTodoList,
  FcDisplay,
  FcElectricalSensor,
  FcTemplate,
  FcCalculator,
  FcParallelTasks,
  FcFlashOn
} from 'react-icons/fc';

export const dataMenu = [
 
  {
    name: 'SOP',
    lowerName: 'sop',
    icon: FcTodoList,
    link: '/sop',
    description:
      'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
    submenu: [
      {
        name: 'SOP Lists',
        icon: FcTodoList,
        link: '/sop/list',
        description: 'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
      },
      {
        name: 'SOP Template',
        icon: FcTemplate,
        link: '/sop/template',
        description:
          'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
      },
      {
        name: 'AI SOP Master',
        icon: FcFlashOn,
        link: '/sop/ai',
        status: 'beta',
        description:
          'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
      },
    ],
  },

  {
    name: 'KPI',
    lowerName: 'kpi',
    icon: FcDisplay,
    link: '/kpi',
    description:
      'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
    submenu: [
      {
        name: 'KPI',
        icon: FcDisplay,
        link: '/kpi/list',
        description: 'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
      },
      {
        name: 'KPI Template',
        icon: FcTemplate,
        link: '/kpi/template',
        description:
          'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
      },

      {
        name: 'KPI simulator',
        icon: FcCalculator,
        link: '/kpi/simulator',
        description:
          'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
      },
    ],
  },

  {
    name: 'OKR',
    icon: FcElectricalSensor,
    link: '/okr',
    description:
      'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
    submenu: [
      {
        name: 'OKR Lists',
        icon: FcElectricalSensor,
        link: '/okr/list',
        description: 'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',

      },
      {
        name: 'Workflow',
        icon: FcParallelTasks,
        link: '/workflow',
        status: 'beta',
        description:
          'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
      },
    ],
  },


  {
    name: 'Configuration',
    icon: FcCollaboration,
    link: '/configuration',
    description:
      'All-in-one HRIS solution that simplifies HR tasks, enhances compliance, and provides valuable data-driven insights for strategic decision-making.',
    submenu: [
      {
        name: 'Projects',
        icon: FcBriefcase,
        link: '/configuration/projects',
      },

      {
        name: 'Users',
        icon: FcManager,
        link: '/configuration/users',
        description:
          'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
      },
      // {
      //   name: 'Kategori',
      //   icon: FcParallelTasks,
      //   link: '/configuration/categories',
      //   description:
      //     'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
      // },
    ],
  },
];
