import {
  Box, Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  TableContainer, Divider, HStack, Heading, Input, Spacer, Icon, useColorMode, Stack, Avatar, useToast, Text, FormLabel, Textarea
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import BackButtons from '../../Components/Buttons/BackButtons'
import moment from 'moment'
import useUserStore from '../../Hooks/Zustand/Store'
import { deleteDocumentFirebase, getSingleDocumentFirebase, setDocumentFirebase } from '../../Api/firebaseApi'
import { useParams } from 'react-router-dom'
import { collection, onSnapshot, query, where } from 'firebase/firestore'
import { db } from '../../Config/firebase'
import LineAreaChart from '../../Components/Charts/ApexChart/LineAreaChart'
import { FcNegativeDynamic, FcPositiveDynamic } from 'react-icons/fc'
// import _ from 'lodash'
import KpiTeamComponent from './Component/KpiTeamComponent'
import { getUserByUid } from '../../Utils/getUser'
import DynamicButton from '../../Components/Buttons/DynamicButton'
import LiveTime from '../../Components/Timer/LiveTime'
import { IoPeople } from 'react-icons/io5';
import { MdNavigateBefore, MdNavigateNext } from 'react-icons/md'
import DatePickerKpi from '../../Components/DatePicker/DatePickerKpi'
import { formatFrice } from '../../Utils/numberUtil'



function KpiViewPage() {
  const [select, setSelect] = useState(6);
  const toast = useToast();

  const [data, setData] = useState();
  const [dataGroup, setDataGroup] = useState();
  const [rawData, setRawData] = useState();
  const [period, setPeriod] = useState();

  //   const [targetData,setTargetData]=useState()
  const globalState = useUserStore();
  const param = useParams();
  const { colorMode } = useColorMode();
  const bgColor = colorMode === 'light' ? 'gray.50' : 'gray.900';

  const [modalEdit, setModalEdit] = useState(false);

  const handleSave = async () => {
    if (period?.data[select]?.actual === undefined) {
      return toast({
        title: 'Deoapp.com',
        duration: 3000,
        description: 'you must set this data',
        status: 'warning',
        position: 'top-right',
        isClosable: true,
      });
    }
    // do calculation before saving
    const stats = {
      statsTotalActual: parseInt(period.data[select].actual || 0),
      statsTotalTarget: parseInt(period.target || 0),
      statsCounter: 1,
    };
    rawData.map((x) => {
      stats.statsTotalActual += x.actual;
      stats.statsCounter++;
      stats.statsTotalTarget += parseInt(period?.target);
    });
    // add averageg stats
    stats.statsAverage = stats?.statsTotalActual / stats?.statsCounter;

    // create progress bar
    let percent = 0;
    period.achivement === 'high'
      ? (percent = (stats?.statsTotalActual / stats?.statsTotalTarget) * 100)
      : (percent =
          100 -
          ((stats?.statsTotalActual / stats?.statsTotalTarget) * 100 - 100));

    stats.statsProgress = parseInt(period?.target === '0' ? 100 : percent);

    try {
      //prepare data for kpi collection
      const updateDataForKPI = {
        ...period?.data[select],
        ...stats,
        kpiId: param?.id,
        title: period?.kpiTitle,
      };
      delete updateDataForKPI['target'];
      delete updateDataForKPI['createdAt'];
      await setDocumentFirebase('kpi', `${param.id}`, updateDataForKPI, {
        merge: true,
      });

      //prefpare data for kpi_data collection
      const updateDataForData = {
        ...period.data[select],
        kpiId: param?.id,
        uid: globalState?.uid,
      };

      await setDocumentFirebase(
        'kpi_data',
        `${param?.id}-${globalState?.uid}-${period?.status}-${updateDataForData?.title}`,
        updateDataForData
      );

      toast({
        title: 'Deoapp',
        description: 'success add data kpi',
        duration: 3000,
        status: 'success',
        position: 'top-right',
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: ('Error!'),
        description: error.message,
        duration: 3000,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  const getData = async () => {
    try {
      globalState.setIsLoading(true);
      const res = await getSingleDocumentFirebase('kpi', param.id);
      let currentPeriod = '';
      if (res.period === 'daily') currentPeriod = 'd';
      if (res.period === 'weekly') currentPeriod = 'w';
      if (res.period === 'monthly') currentPeriod = 'M';
      if (res.period === 'quarterly') currentPeriod = 'Q';
      if (res.period === 'annually') currentPeriod = 'y';

      const updatedPeriod = {
        ...res,
        status: currentPeriod,
        kpiTitle: res.title,
        data: [],
      };


      //create unix time for each currentperiod
      const arrCalculatio = [6, 5, 4, 3, 2, 1, 0];
      arrCalculatio.map((x) =>
        updatedPeriod.data.push({
          title: moment()
            .startOf(currentPeriod)
            .subtract(x, currentPeriod)
            .format(currentPeriod === 'd' ? 'DD-MM-YY' : `${currentPeriod}-YY`),
          startDate: moment()
            .startOf(currentPeriod)
            .subtract(x, currentPeriod)
            .unix(),
          endDate: moment()
            .endOf(currentPeriod)
            .subtract(x, currentPeriod)
            .unix(),
        })
      );
      setPeriod(updatedPeriod);
    } catch (error) {
      toast({
        title: ('Error!'),
        description: error.message,
        duration: 3000,
        status: 'error',
      });
    } finally {
      globalState.setIsLoading(false);
    }
  };

  const handlePeriodChange = (changeValue) => {
    const updatedData = period.data.map((item) => ({
      title: moment(
        item?.title,
        period.status === 'd' ? 'DD-MM-YY' : `${period?.status}-YY`
      )
        .add(changeValue, period?.status)
        .format(period.status === 'd' ? 'DD-MM-YY' : `${period?.status}-YY`),
      startDate: moment
        .unix(item.startDate)
        .add(changeValue, period.status)
        .unix(),
      endDate: moment.unix(item.endDate).add(changeValue, period?.status).unix(),
    }));
    setPeriod((prevPeriod) => ({ ...prevPeriod, data: updatedData }));
  };


  const handleDateRangeChange = async (newStartDate, newEndDate) => {
    const startDate = moment(newStartDate, 'ddd MMM DD YYYY HH:mm:ss Z').startOf(
      'day'
    ); 
    const endDate = moment(newEndDate, 'ddd MMM DD YYYY HH:mm:ss Z').endOf('day'); 
    const manyDays = endDate.diff(startDate, 'days') + 1;

    let changeValue = 1;
    if (period.status === 'd') {
      changeValue = manyDays;
    } else if (period.status === 'w') {
      changeValue = Math.ceil(manyDays / 7);
    } else if (period.status === 'M') {
      changeValue = Math.ceil(manyDays / 30);
    } else if (period.status === 'Q') {
      changeValue = Math.ceil(manyDays / 90);
    } else if (period.status === 'y') {
      changeValue = Math.ceil(manyDays / 365);
    }

    const updatedData = [];
    const currentDate = moment(startDate);
    for (let i = 0; i < changeValue; i++) {
      if (currentDate.isBetween(startDate, endDate, null, '[]')) {
        updatedData.push({
          title: moment(currentDate).format(
            period.status === 'd' ? 'DD-MM-YY' : `${period.status}-YY`
          ),
          startDate: currentDate.unix(),
          endDate: moment(currentDate).endOf(period.status).unix(), 
        });
      }
      currentDate.add(1, period.status);
    }

    setPeriod((prevPeriod) => ({ ...prevPeriod, data: updatedData }));
  };

  const findProject = globalState?.projects?.find(
    (x) => x.id === globalState?.currentProject
  );

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (!period?.data) return;
    const currentUnix = moment()
      .endOf(period?.status)
      .subtract(0, period?.status)
      .unix();
    const q = query(
      collection(db, 'kpi_data'),
      where('kpiId', '==', param?.id),
      where('startDate', '>=', parseInt(period?.data[0]?.startDate)),
      where('startDate', '<=', parseInt(currentUnix))
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const datas = [];
      querySnapshot.forEach((doc) => {
        datas.push({ id: doc.id, ...doc.data() });
      });
      setRawData(datas);

      const result = [];
      datas.reduce(function (res, value) {
        if (!res[value.title]) {
          res[value.title] = {
            title: value.title,
            actual: 0,
            uid: value.uid ? value.uid : undefined,
          };
          result.push(res[value.title]);
        }
        res[value.title].actual += value.actual;
        return res;
      }, {});
      setData(result);
      //get users who has input data
      const unique = [...new Set(datas.map(({ uid }) => uid))];
      setDataGroup(unique);
    });
    return () => {
      unsubscribe();
    };
  }, [period?.data]);

  const handleModalEdit = () => {
    if(data.createdBy !== globalState.uid){
      if (
        globalState.roleCompany !== 'owner' &&
        globalState.roleProject === 'user'
      ) {
        return toast({
          title: ('Alert!'),
          description: ('Im sorry, but based on your role,  You dont have access.'),
          status: 'warning',
          duration: 9000,
          isClosable: true,
        });
      }
    }
    setModalEdit(true);
  };

  const handleModalEditClose = () => {
    setModalEdit(false);
  };

  const handleReset = async () => {
    // Menginisialisasi statistik

    const rawSelect = rawData.find(
      (x) => x?.title === period?.data[select]?.title
    );

    const stats = {
      statsTotalActual:
        parseInt(period.statsTotalActual || 0) -
        parseInt(rawSelect?.actual || 0),
      statsTotalTarget:
        parseInt(period.statsTotalTarget || 0) - parseInt(period.target || 0),
      statsCounter: parseInt(period.statsCounter || 0) - 1,
    };

    // Menghindari pembagian oleh 0
    stats.statsAverage =
      stats.statsCounter !== 0
        ? stats.statsTotalActual / stats.statsCounter
        : 0;

    // Membuat progress bar
    let percent = 0;
    if (stats.statsTotalTarget !== 0) {
      percent =
        period.achivement === 'high'
          ? (stats.statsTotalActual / stats.statsTotalTarget) * 100
          : 100 -
            ((stats.statsTotalActual / stats.statsTotalTarget) * 100 - 100);
    }
    stats.statsProgress = parseInt(period.target === '0' ? 100 : percent);

    try {
      // Persiapkan data untuk koleksi kpi
      const updateDataForKPI = {
        ...period.data[select],
        ...stats,
        kpiId: param.id,
        title: period.kpiTitle,
      };
      delete updateDataForKPI['target'];
      delete updateDataForKPI['createdAt'];

      // Persiapkan data untuk koleksi kpi_data
      const updateDataForData = {
        ...period.data[select],
        kpiId: param.id,
        uid: globalState.uid,
      };

      await setDocumentFirebase('kpi', `${param.id}`, updateDataForKPI, {
        merge: true,
      });

      await deleteDocumentFirebase(
        'kpi_data',
        `${param.id}-${globalState.uid}-${period.status}-${updateDataForData.title}`
      );
      toast({
        title: 'Deoapp',
        description: 'success reset data kpi',
        duration: 3000,
        status: 'success',
        position: 'top-right',
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: ('Error!'),
        description: error.message,
        duration: 3000,
        status: 'error',
      });
    }
  };

  return (
    <Stack p={[1, 1, 5]}>
      <HStack flexDir={['column', 'row', 'row']}>
        <BackButtons />
        <Box maxW={['auto', '500px', '700px']}>
          <Heading noOfLines={1} size={'md'}>
            KPI {period?.kpiTitle}
          </Heading>
          <Text fontSize="2xs" color={'gray.500'}>
            ID :{param.id}
          </Text>
          <Text fontSize="2xs" color={'gray.500'}>
            {('Project')} : {findProject?.name}
          </Text>
        </Box>
        <Spacer />
        <Stack spacing={0}>
          {period?.achivement === 'high' ? (
            <HStack>
              <Heading fontSize="md">Higher is better</Heading>
              <Icon as={FcPositiveDynamic} boxSize={9} />
            </HStack>
          ) : (
            <HStack>
              <Heading fontSize="md">Lower is better</Heading>
              <Icon as={FcNegativeDynamic} boxSize={9} />
            </HStack>
          )}
          <DynamicButton
            title={('Team')}
            size={'sm'}
            action="custom"
            icon={IoPeople}
            color={'blue'}
            variant={'solid'}
            onClick={handleModalEdit}
          />
        </Stack>

        <Stack bgColor={'blue.500'} shadow="md" p={2} borderRadius="md">
          <LiveTime color={'white'} />
        </Stack>
      </HStack>

      <Stack>
        <FormLabel>{('Description')}</FormLabel>
        <Textarea
          size={'sm'}
          rows={3}
          isDisabled
          resize="none"
          defaultValue={period?.description}
        />
      </Stack>

      <Divider m="2" />
      <Stack p={[1, 1, 5]} shadow="base" borderRadius="md">
        <Stack alignItems={'flex-start'}>
          <DatePickerKpi handleDateRangeChange={handleDateRangeChange} />
        </Stack>
        <LineAreaChart
          data={
            data
              ? {
                data: data,
                period: period,
              }
              : null
          }
        />

        <Stack alignItems={'center'}>
          <HStack>
            <DynamicButton
              size={'sm'}
              action={'custom'}
              icon={MdNavigateBefore}
              onClick={() => handlePeriodChange(-6)}
              color={'blue'}
              variant={'solid'}
            />
            <DynamicButton
              size={'sm'}
              action={'custom'}
              icon={MdNavigateNext}
              onClick={() => handlePeriodChange(6)}
              color={'blue'}
              variant={'solid'}
            />
          </HStack>
        </Stack>
        <HStack fontWeight={500}>
          <Text>Format : </Text>

          <Text textTransform={'uppercase'}>{period?.format}</Text>
        </HStack>

        {/* check if uid exist, if not then show the current user */}
        {dataGroup?.filter((x) => x === globalState.uid)?.length ? (
          dataGroup
            .filter((x) => x === globalState.uid)
            .map((z, i) => {
              return (
                <Box key={i} mt="2" shadow="base" p="2">
                  <HStack spacing={3}>
                    <Avatar
                      name={
                        getUserByUid(globalState?.users, z)?.name || 'UNKNOWN'
                      }
                      src={
                        getUserByUid(globalState?.users, z)?.image || 'UNKNOWN'
                      }
                    />
                    <Heading size="lg" textTransform="capitalize">
                      {z ? getUserByUid(globalState?.users, z)?.name : 'UNKNOWN'}
                    </Heading>
                  </HStack>
                  <TableContainer w="full">
                    <Table variant="striped" colorScheme="blue">
                      <Thead>
                        <Tr>
                          <Th></Th>
                          {period?.data?.map((x, i) => (
                            <Th
                              textAlign="center"
                              cursor="pointer"
                              onClick={() => setSelect(i)}
                              bgColor={select === i ? 'gray.100' : ''}
                              key={i}
                            >
                              {period?.status} {x.title}
                            </Th>
                          ))}
                        </Tr>
                      </Thead>
                      <Tbody>
                        <Tr>
                          <Td>{('Actual')}</Td>
                          {period?.data?.map((x, i) => (
                            <Td
                              key={i}
                              cursor="pointer"
                              bgColor={select === i ? 'gray.100' : ''}
                              onClick={() => setSelect(i)}
                            >
                              <HStack>
                                <Input
                                  bgColor={bgColor}
                                  isDisabled={select === i ? false : true}
                                  type="number"
                                  onChange={(e) =>
                                    (period.data[select]['actual'] = parseFloat(
                                      e.target.value
                                    ))
                                  }
                                  placeholder={
                                    rawData?.find(
                                      (y) =>
                                        y.title === x.title &&
                                        y.uid === globalState.uid
                                    )
                                      ? formatFrice(Number(rawData.find(
                                        (y) =>
                                          y.title === x.title &&
                                            y.uid === globalState.uid
                                      ).actual || 0))
                                      : ('No Data')
                                  }
                                />
                              </HStack>
                            </Td>
                          ))}
                        </Tr>
                        <Tr>
                          <Td>Target </Td>
                          {period?.data?.map((x, i) => (
                            <Td
                              key={i}
                              cursor="pointer"
                              bgColor={select === i ? 'gray.100' : ''}
                            >
                              <Input
                                bgColor={bgColor}
                                isDisabled={true}
                                type="number"
                                onChange={(e) =>
                                  (period.data[select]['target'] = parseFloat(
                                    e.target.value
                                  ))
                                }
                                placeholder={
                                  rawData?.find((y) => y.title === x.title)
                                    ?.target
                                    ? formatFrice(Number(rawData.find((y) => y.title === x.title)
                                      .target) || 0)
                                    : formatFrice(Number(period?.target || 0))
                                }
                              />
                            </Td>
                          ))}
                        </Tr>
                        <Tr>
                          <Td></Td>
                          {period?.data?.map((x, i) => (
                            <Td
                              key={i}
                              bgColor={select === i ? 'gray.100' : ''}
                            >
                              {rawData?.find(
                                (y) =>
                                  y.title === x.title &&
                                    y.uid === globalState.uid
                              ) ? (
                                  <DynamicButton
                                    title={'reset'}
                                    size={'sm'}
                                    variant={'solid'}
                                    disabled={select === i ? false : true}
                                    action={'delete'}
                                    onClick={() => handleReset()}
                                  />
                                ) : (
                                  <DynamicButton
                                    title={('Save')}
                                    size={'sm'}
                                    variant={'solid'}
                                    disabled={select === i ? false : true}
                                    action={'create'}
                                    onClick={() => handleSave()}
                                  />
                                )}
                            </Td>
                          ))}
                        </Tr>
                      </Tbody>
                    </Table>
                  </TableContainer>
                </Box>
              );
            })
        ) : (
          <Box mt="2" shadow="base" p="2">
            <HStack>
              <Avatar
                name={getUserByUid(globalState.users, globalState.uid)?.name}
                src={getUserByUid(globalState.users, globalState.uid)?.image}
              />
              <Heading textTransform="capitalize">
                {getUserByUid(globalState.users, globalState.uid)?.name}
              </Heading>
            </HStack>
            <TableContainer w="full">
              <Table variant="striped" colorScheme="blue">
                <Thead>
                  <Tr>
                    <Th></Th>
                    {period?.data?.map((x, i) => (
                      <Th
                        textAlign="center"
                        cursor="pointer"
                        onClick={() => setSelect(i)}
                        bgColor={select === i ? 'gray.100' : ''}
                        key={i}
                      >
                        {period?.status} {x.title}
                      </Th>
                    ))}
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td>{('Actual')}</Td>
                    {period?.data?.map((x, i) => (
                      <Td
                        key={i}
                        cursor="pointer"
                        bgColor={select === i ? 'gray.100' : ''}
                        onClick={() => setSelect(i)}
                      >
                        {/* <Text>{x.title}</Text> */}
                        <Input
                          bgColor={bgColor}
                          isDisabled={select === i ? false : true}
                          type="number"
                          onChange={(e) =>
                            (period.data[select]['actual'] = parseFloat(
                              e.target.value
                            ))
                          }
                          placeholder={
                            rawData?.find(
                              (y) =>
                                y.title === x.title && y.uid === globalState.uid
                            )
                              ? formatFrice(Number(rawData.find(
                                (y) =>
                                  y.title === x.title &&
                                    y.uid === globalState.uid
                              ).actual || 0))
                              : ('No Data')
                          }
                        />
                      </Td>
                    ))}
                  </Tr>
                  <Tr>
                    <Td>Target </Td>
                    {period?.data?.map((x, i) => (
                      <Td
                        key={i}
                        cursor="pointer"
                        bgColor={select === i ? 'gray.100' : ''}
                      >
                        <Input
                          bgColor={bgColor}
                          isDisabled={true}
                          type="number"
                          onChange={(e) =>
                            (period.data[select]['target'] = parseFloat(
                              e.target.value
                            ))
                          }
                          placeholder={
                            data?.find((y) => y.title === x.title)?.target
                              ? formatFrice(Number(data.find((y) => y.title === x.title).target || 0) )
                              : formatFrice(Number(period?.target))
                          }
                        />
                      </Td>
                    ))}
                  </Tr>
                  <Tr>
                    <Td></Td>
                    {period?.data?.map((x, i) => (
                      <Td key={i} bgColor={select === i ? 'gray.100' : ''}>
                        {rawData?.find(
                          (y) =>
                            y.title === x.title && y.uid === globalState.uid
                        ) ? (
                            <DynamicButton
                              title={'reset'}
                              variant={'solid'}
                              action={'delete'}
                              size={'sm'}
                              disabled={select === i ? false : true}
                              onClick={() => handleReset()}
                            />
                          ) : (
                            <DynamicButton
                              title={('Save')}
                              variant={'solid'}
                              action={'create'}
                              size={'sm'}
                              disabled={select === i ? false : true}
                              onClick={() => handleSave()}
                            />
                          )}
                      </Td>
                    ))}
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        )}

        {dataGroup
          ?.filter((x) => x !== globalState.uid)
          .map((z, i) => (
            <Box key={i} mt="2" shadow="base" p="2">
              <HStack spacing={3}>
                <Avatar
                  name={getUserByUid(globalState.users, z)?.name || 'UNKNOWN'}
                  src={getUserByUid(globalState.users, z)?.image || 'UNKNOWN'}
                />
                <Heading size="lg" textTransform="capitalize">
                  {z ? getUserByUid(globalState.users, z)?.name : 'UNKNOWN'}
                </Heading>
              </HStack>
              <TableContainer w="full">
                <Table variant="striped" colorScheme="blue">
                  <Thead>
                    <Tr>
                      <Th></Th>
                      {period?.data?.map((x, i) => (
                        <Th
                          textAlign="center"
                          cursor="pointer"
                          onClick={() => setSelect(i)}
                          bgColor={select === i ? 'gray.100' : ''}
                          key={i}
                        >
                          {period?.status} {x.title}
                        </Th>
                      ))}
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td>{('Actual')}</Td>
                      {period?.data?.map((x, i) => (
                        <Td
                          key={i}
                          cursor="pointer"
                          bgColor={select === i ? 'gray.100' : ''}
                          onClick={() => setSelect(i)}
                        >
                          {/* <Text>{x.title}</Text> */}
                          <Input
                            bgColor={bgColor}
                            isDisabled
                            type="number"
                            onChange={(e) =>
                              (period.data[select]['actual'] = parseFloat(
                                e.target.value
                              ))
                            }
                            placeholder={
                              rawData?.find(
                                (y) => y.title === x.title && y.uid === z
                              )
                                ? formatFrice(Number(rawData.find(
                                  (y) => y.title === x.title && y.uid === z
                                ).actual) || 0)
                                : ('No Data')
                            }
                          />
                        </Td>
                      ))}
                    </Tr>
                    <Tr>
                      <Td>Target </Td>
                      {period?.data?.map((x, i) => (
                        <Td
                          key={i}
                          cursor="pointer"
                          bgColor={select === i ? 'gray.100' : ''}
                        >
                          <Input
                            bgColor={bgColor}
                            isDisabled
                            type="number"
                            onChange={(e) =>
                              (period.data[select]['target'] = parseFloat(
                                e.target.value
                              ))
                            }
                            placeholder={
                              data?.find((y) => y.title === x.title)?.target
                                ? formatFrice(Number(data.find((y) => y.title === x.title).target || 0) )
                                : formatFrice(Number(period?.target))
                            }
                          />
                        </Td>
                      ))}
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>
            </Box>
          ))}
      </Stack>

      <Modal isOpen={modalEdit} onClose={handleModalEditClose} size="2xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{('Edit Kpi')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack>
              <KpiTeamComponent
                dataProps={period}
                globalStateProps={globalState}
              />
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Stack>
  );
}

export default KpiViewPage;