import { Icon, Stack, useToast } from '@chakra-ui/react'
import React from 'react'
import { IoHome } from 'react-icons/io5'
import useUserStore from '../../Hooks/Zustand/Store';
import _axiosReadme from '../../Api/AxiosBarrierReadme';

function NavigateHomeApp() {


  const globalState = useUserStore();
  const toast = useToast()


  const handleNavigate = async () => {
    const data = {
      uid: globalState?.uid,
    }


    try {
      const res = await _axiosReadme.post('/user-createCustomToken', data)
      if (res.status === true) {
        // window.open(`${dataTypesense?.redirectUrl}?appId=${filteredApps[0]}&companyId=${deoappState?.currentCompany}&projectId=${projectId}&sessions=${res.customToken}`, '_blank')
        // console.log(`${dataTypesense?.redirectUrl}?appId=${filteredApps[0].appId}&companyId=${deoappState?.currentCompany}&projectId=${projectId}&sessions=${res.customToken}`, '_blank')
        window.open(`https://app-deoapp.web.app/home?companyId=${globalState?.currentCompany}&sessions=${res.customToken}`, '_blank')
      }
    } catch (error) {
      toast({
        title: 'Error!',
        description: error.message,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }

  }


  return (
    <Stack
      align={'center'}
      onClick={() => handleNavigate()}
      cursor={'pointer'}
    >
      <Icon as={IoHome} boxSize={6} color="subtle" />
    </Stack>
  )
}

export default NavigateHomeApp