/* eslint-disable no-undef */
import _axiosReadme from './AxiosBarrierReadme';

const getTokenSessions = async (globalState) => {
  const data = {
    uid: globalState.uid,
    additionalClaims : {
      companyId: globalState?.currentCompany,
      projectId: globalState?.currentProject,
    }
  }

  try {
    const res = await _axiosReadme.post('/user-createCustomToken', data)
    if (res.status === true) {
      return res
    }
  } catch (error) {
    return new Error(error.message)
  }
}


export {
  getTokenSessions,
};
