import {
  Box,
  Grid,
  HStack,
  Heading,
  Input,
  Spacer,
  Stack,
  Text,
  SimpleGrid,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import BackButtons from '../../Components/Buttons/BackButtons';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import BudgetMarketingKpi from './Component/BudgetMarketingKpi';
import { formatFrice } from '../../Utils/Helper';
import useUserStore from '../../Hooks/Zustand/Store';
import { ArrowDownIcon, ArrowUpIcon } from '@chakra-ui/icons';
import ChartsPieKpi from '../../Components/Charts/ChartsPieKpi';

function KpiSimulatorPage() {
  const globalState = useUserStore();

  const [generate, setGenerate] = useState(false);
  const [inputCompany, setInputCompany] = useState({
    target: 0,
    product_price: 0,
    cogs: 0,
    income_head: 0,
    salary_head: 0,
    laba: 24,
  });

  const [calculatedResults, setCalculatedResults] = useState({
    totalSalary: 0,
    qtyTeamNeeded: 0,
    totalExpenseProduct: 0,
    qtyProduct: 0,
    nettProfit: 0,
    budgetMarketing: 0,
    targetCAC: 0,
  });

  const handleChange = (key, value) => {
    setInputCompany((prevState) => ({ ...prevState, [key]: value }));
  };

  const calculateResults = () => {
    const totalSalary = +(
      inputCompany.salary_head *
      (inputCompany.target / inputCompany.income_head)
    ).toFixed(2);
    const qtyTeamNeeded = inputCompany.target / inputCompany.income_head;
    const totalExpenseProduct =
      (inputCompany.target / inputCompany.product_price) * inputCompany.cogs;
    const qtyProduct = inputCompany.target / inputCompany.product_price;
    const nettProfit = (inputCompany.laba * inputCompany.target) / 100;
    const budgetMarketing =
      inputCompany.target -
      inputCompany.cogs -
      totalSalary -
      totalExpenseProduct -
      nettProfit;
    const targetCAC =
      budgetMarketing / (inputCompany.target / inputCompany.product_price);

    setCalculatedResults({
      totalSalary: isNaN(totalSalary) ? 0 : totalSalary,
      qtyTeamNeeded: isNaN(qtyTeamNeeded) ? 0 : qtyTeamNeeded,
      totalExpenseProduct: isNaN(totalExpenseProduct) ? 0 : totalExpenseProduct,
      qtyProduct: isNaN(qtyProduct) ? 0 : qtyProduct,
      nettProfit: isNaN(nettProfit) ? 0 : nettProfit,
      budgetMarketing: isNaN(budgetMarketing) ? 0 : budgetMarketing,
      targetCAC: isNaN(targetCAC) ? 0 : targetCAC,
    });
  };

  const handleGenerateCompany = () => {
    calculateResults();
    setGenerate(true);
  };

  const dataProps = {
    budgetMarketing: calculatedResults.budgetMarketing || 0,
    nettProfit: calculatedResults.nettProfit || 0,
    expenseProduct: calculatedResults.totalExpenseProduct || 0,
    expenseTeam: calculatedResults.totalSalary || 0,
  };

  return (
    <Stack p={[1, 1, 5]} spacing={5}>
      <Stack>
        <HStack>
          <BackButtons />
          <Box>
            <Heading size={'md'}>{('Simulator Kpi')}</Heading>
          </Box>
          <Spacer />
        </HStack>
      </Stack>
      <Stack spacing={5}>
        <Grid templateColumns={{ base: '1fr', md: '1fr 2fr' }} gap={5}>
          <Stack spacing={3}>
            <Stack>
              {Object.entries(inputCompany).map(([key, value]) => (
                <Stack key={key}>
                  <Text textTransform={'capitalize'}>
                    {(key.replace(/_/g, ' '))}
                  </Text>
                  <Input
                    placeholder={key}
                    defaultValue={value}
                    type="number"
                    onChange={(e) =>
                      handleChange(key, parseInt(e.target.value))
                    }
                  />
                </Stack>
              ))}
            </Stack>
            <Stack>
              <DynamicButton
                action={'create'}
                title={'Generate'}
                variant={'solid'}
                onClick={() => handleGenerateCompany()}
              />
            </Stack>
          </Stack>
          <Stack>
            <Text>Simulator</Text>

            <SimpleGrid
              columns={{ base: 1, md: 2 }}
              gap={5}
              borderRadius={'lg'}
              border={'1px'}
              borderColor={'gray.200'}
              p={4}
            >
              <Stack justifyContent={'center'} alignItems={'start'}>
                <Text fontWeight={'bold'} fontSize={'xl'}>
                  {('Income')}
                </Text>
                <HStack
                  borderRadius={'lg'}
                  border={'1px'}
                  borderColor={'gray.200'}
                  p={4}
                  justifyContent={'space-between'}
                  w={'100%'}
                >
                  <Text>Target</Text>
                  <HStack>
                    <ArrowUpIcon fontSize={20} color={'green'} />
                    <Heading size={'md'} textAlign="left">
                      <Text
                        fontSize="sm"
                        verticalAlign="top"
                        display="inline"
                        fontWeight={'semibold'}
                        color={'gray.500'}
                      >
                        Rp.
                      </Text>{' '}
                      {formatFrice(inputCompany.target)}
                    </Heading>
                  </HStack>
                </HStack>
                <HStack
                  borderRadius={'lg'}
                  border={'1px'}
                  borderColor={'gray.200'}
                  p={4}
                  justifyContent={'space-between'}
                  w={'100%'}
                >
                  <Text>PNL</Text>
                  <HStack alignItems={'center'}>
                    <ArrowUpIcon fontSize={20} color={'green'} />
                    <Heading size={'md'} fontWeight={'semibold'}>
                      {inputCompany.laba}%{' '}
                    </Heading>
                    <Text alignSelf="flex-end" fontWeight={500} fontSize="xs">
                      {('Times')} {('Profit')}
                    </Text>
                  </HStack>
                </HStack>
                <HStack
                  borderRadius={'lg'}
                  border={'1px'}
                  borderColor={'gray.200'}
                  p={4}
                  justifyContent={'space-between'}
                  w={'100%'}
                >
                  <Text>{('Nett Profit Total')}</Text>
                  <HStack>
                    <ArrowUpIcon fontSize={20} color={'green'} />
                    <Heading size={'md'} textAlign="left">
                      <Text
                        fontSize="sm"
                        verticalAlign="top"
                        display="inline"
                        fontWeight={'semibold'}
                        color={'gray.500'}
                      >
                        Rp.
                      </Text>{' '}
                      {formatFrice(calculatedResults.nettProfit)}
                    </Heading>
                  </HStack>
                </HStack>
              </Stack>
              <ChartsPieKpi data={dataProps} generate={generate} />
            </SimpleGrid>

            <SimpleGrid columns={{ base: 1, md: 2 }} gap={4} my={4}>
              <Stack gap={5}>
                <Text fontWeight={'bold'} fontSize={'xl'}>
                  {('Expense Product')}
                </Text>
                <Stack>
                  <HStack
                    borderRadius={'lg'}
                    border={'1px'}
                    borderColor={'gray.200'}
                    p={4}
                    justifyContent={'space-between'}
                    w={'100%'}
                  >
                    <Text>Total</Text>
                    <Heading size={'md'} textAlign="left">
                      <Text
                        fontSize="sm"
                        verticalAlign="top"
                        display="inline"
                        fontWeight={500}
                        color={'gray.500'}
                      >
                        Rp.
                      </Text>{' '}
                      {formatFrice(calculatedResults.totalExpenseProduct)}
                    </Heading>
                  </HStack>
                  <HStack
                    borderRadius={'lg'}
                    border={'1px'}
                    borderColor={'gray.200'}
                    p={4}
                    justifyContent={'space-between'}
                    w={'100%'}
                  >
                    <Text>{('Quantity Product')}</Text>
                    <Heading size={'md'}>
                      {formatFrice(calculatedResults.qtyProduct)}
                    </Heading>
                  </HStack>
                </Stack>
              </Stack>
              <Stack gap={5}>
                <Text fontWeight={'bold'} fontSize={'xl'}>
                  {('Expense Ads')}
                </Text>
                <Stack spacing={3} fontSize="sm">
                  <HStack
                    borderRadius={'lg'}
                    border={'1px'}
                    borderColor={'gray.200'}
                    p={4}
                    justifyContent={'space-between'}
                    w={'100%'}
                  >
                    <Text>{('Budget Marketing')}</Text>
                    <Heading size={'md'} textAlign="left">
                      <Text
                        fontSize="sm"
                        verticalAlign="top"
                        display="inline"
                        fontWeight={500}
                        color={'gray.500'}
                      >
                        Rp.
                      </Text>{' '}
                      {formatFrice(calculatedResults.budgetMarketing)}
                    </Heading>
                  </HStack>
                  <HStack
                    borderRadius={'lg'}
                    border={'1px'}
                    borderColor={'gray.200'}
                    p={4}
                    justifyContent={'space-between'}
                    w={'100%'}
                  >
                    <Text>Target CAC</Text>
                    <Heading size={'md'} textAlign="left">
                      <Text
                        fontSize="sm"
                        verticalAlign="top"
                        display="inline"
                        fontWeight={500}
                        color={'gray.500'}
                      >
                        Rp.
                      </Text>{' '}
                      {formatFrice(calculatedResults.targetCAC)}
                    </Heading>
                  </HStack>
                </Stack>
              </Stack>
            </SimpleGrid>

            <Stack spacing={0}>
              <Text fontWeight={'bold'} fontSize={'xl'}>
                {('Expense Team')}
              </Text>
              <SimpleGrid columns={{ base: 1, md: 2 }} gap={4} my={4}>
                <HStack
                  borderRadius={'lg'}
                  border={'1px'}
                  borderColor={'gray.200'}
                  p={4}
                  justifyContent={'space-between'}
                  w={'100%'}
                >
                  <Text>{('Total Salary')}</Text>
                  <HStack>
                    <ArrowDownIcon fontSize={15} color={'red'} />
                    <Heading size={'md'} textAlign="left">
                      <Text
                        fontSize="sm"
                        verticalAlign="top"
                        display="inline"
                        fontWeight={500}
                        color={'gray.500'}
                      >
                        Rp.
                      </Text>{' '}
                      {formatFrice(calculatedResults.totalSalary)}
                    </Heading>
                  </HStack>
                </HStack>
                <HStack
                  borderRadius={'lg'}
                  border={'1px'}
                  borderColor={'gray.200'}
                  p={4}
                  justifyContent={'space-between'}
                  w={'100%'}
                >
                  <Text>{('Quantity Team Needed')}</Text>
                  <HStack>
                    <ArrowUpIcon fontSize={15} color={'green'} />
                    <Heading size={'md'}>
                      {formatFrice(calculatedResults.qtyTeamNeeded)}
                    </Heading>
                  </HStack>
                </HStack>
                <HStack
                  borderRadius={'lg'}
                  border={'1px'}
                  borderColor={'gray.200'}
                  p={4}
                  justifyContent={'space-between'}
                  w={'100%'}
                >
                  <Text>
                    {('Income')} / {('head')}
                  </Text>
                  <HStack>
                    <ArrowUpIcon fontSize={15} color={'green'} />
                    <Heading size={'md'} textAlign="left">
                      <Text
                        fontSize="sm"
                        verticalAlign="top"
                        display="inline"
                        fontWeight={500}
                        color={'gray.500'}
                      >
                        Rp.
                      </Text>{' '}
                      {formatFrice(inputCompany.income_head)}/{('month')}
                    </Heading>
                  </HStack>
                </HStack>
                <HStack
                  borderRadius={'lg'}
                  border={'1px'}
                  borderColor={'gray.200'}
                  p={4}
                  justifyContent={'space-between'}
                  w={'100%'}
                >
                  <Text>
                    {('Salary')} / {('head')}
                  </Text>
                  <HStack>
                    <ArrowDownIcon fontSize={15} color={'red'} />
                    <Heading size={'md'} textAlign="left">
                      <Text
                        fontSize="sm"
                        verticalAlign="top"
                        display="inline"
                        fontWeight={500}
                        color={'gray.500'}
                      >
                        Rp.
                      </Text>{' '}
                      {formatFrice(inputCompany.salary_head)}/{('month')}
                    </Heading>
                  </HStack>
                </HStack>
              </SimpleGrid>
            </Stack>

            <Stack spacing={0}>
              <Text fontWeight={'bold'} fontSize={'xl'}>
                {('Product')}
              </Text>
              <SimpleGrid columns={{ base: 1, md: 3 }} gap={4} my={4}>
                <Stack>
                  <HStack
                    borderRadius={'lg'}
                    border={'1px'}
                    borderColor={'gray.200'}
                    p={4}
                    justifyContent={'space-between'}
                    w={'100%'}
                  >
                    <Text>Target {('Value')}</Text>
                    <Heading size={'md'}>2-5x COGS</Heading>
                  </HStack>
                </Stack>
                <HStack
                  borderRadius={'lg'}
                  border={'1px'}
                  borderColor={'gray.200'}
                  p={4}
                  justifyContent={'space-between'}
                  w={'100%'}
                >
                  <Text>{('Product Price')}</Text>
                  <HStack>
                    <ArrowDownIcon fontSize={15} color={'red'} />
                    <Heading size={'md'} textAlign="left">
                      <Text
                        fontSize="sm"
                        verticalAlign="top"
                        display="inline"
                        fontWeight={500}
                        color={'gray.500'}
                      >
                        Rp.
                      </Text>{' '}
                      {formatFrice(inputCompany.product_price)}
                    </Heading>
                  </HStack>
                </HStack>
                <HStack
                  borderRadius={'lg'}
                  border={'1px'}
                  borderColor={'gray.200'}
                  p={4}
                  justifyContent={'space-between'}
                  w={'100%'}
                >
                  <Text>COGS</Text>
                  <HStack>
                    <ArrowUpIcon fontSize={15} color={'green'} />
                    <Heading size={'md'} textAlign="left">
                      <Text
                        fontSize="sm"
                        verticalAlign="top"
                        display="inline"
                        fontWeight={500}
                        color={'gray.500'}
                      >
                        Rp.
                      </Text>{' '}
                      {formatFrice(inputCompany.cogs)}
                    </Heading>
                  </HStack>
                </HStack>
              </SimpleGrid>
            </Stack>
          </Stack>
        </Grid>

        <Stack spacing={5}>
          <BudgetMarketingKpi
            targetValue={inputCompany.target || 0}
            budgetValue={calculatedResults.budgetMarketing || 0}
            cacValue={calculatedResults.targetCAC || 0}
            quantityProduct={calculatedResults.qtyProduct || 0}
            globalState={globalState}
          />
        </Stack>
      </Stack>
    </Stack>
  );
}

export default KpiSimulatorPage;
