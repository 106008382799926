/* eslint-disable react/prop-types */
import { Box, FormLabel, HStack, Input, Select, Text, Textarea, useToast, Checkbox, Stack, AvatarGroup, Avatar, Button, Spacer, Divider, Icon } from '@chakra-ui/react'
import React, { useState } from 'react'
import { addDocumentFirebase } from '../../../Api/firebaseApi';
import useUserStore from '../../../Hooks/Zustand/Store';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../../Config/firebase';
import DynamicButton from '../../../Components/Buttons/DynamicButton';
import DynamicMultiSelectComponent from '../../../Components/Select/DynamicMultiSelectComponent';
import { clientTypessense } from '../../../Api/Typesense';
import { MdCancel } from 'react-icons/md';
import { FaTag } from 'react-icons/fa';


function KpiNewPage({ dataSop }) {
  const [data, setData] = useState()
  const toast = useToast();
  const globalState = useUserStore()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [newTags, setNewTags] = useState('');


  const [searchResult, setSearchResult] = useState([]);
  const [selectedUserProjectIds, setSelectedUserProjectIds] = useState([]);



  const handleNewCategory = (e) => {
    setNewTags((e.target.value).toLowerCase());
  };

  const handleRemoveTags = (index) => {
    const updatedFeatures = [...data.tags];
    updatedFeatures.splice(index, 1);
    setData({ ...data, tags: updatedFeatures });
  };

  const handleAddFeature = async () => {
    const existingFeatures = Array.isArray(data?.tags) ? data?.tags : [];

    const newFeatureList = [...existingFeatures, newTags];
    setData({ ...data, tags: newFeatureList });
  };





  const handleAdd = async () => {

    if (
      globalState.currentCompany === undefined ||
      globalState.currentProject === undefined
    ) {
      toast({
        title: 'Deoapp.com',
        description: 'you must set company and project data',
        status: 'warning',
        position: 'top-right',
        isClosable: true,
      });
      return;
    }


    if(selectedUserProjectIds?.length === 0){
      return toast({
        title: 'Warning!',
        description: 'Please add users to kpi',
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
    }

    const dataIdUsers = selectedUserProjectIds?.length > 0 && selectedUserProjectIds.map((x) => x.id);
    const dataUsers = selectedUserProjectIds?.length > 0 && selectedUserProjectIds.map((x) => x);
    
    // Filter UID dan email tertentu yang tidak perlu dimasukkan
    const filteredDataIdUsers = dataIdUsers.filter((uid) => uid !== globalState.uid);
    const filteredDataUsers = dataUsers.filter((user) => user.email !== auth?.currentUser?.email);




    if (!data.achivement || !data.description || !data.format || !data.period || !data.target || !data.title) {
      return toast({
        title: 'Error',
        description: 'Please fill all form',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }

    if (data.target === '0') {
      return toast({
        title: 'Error',
        description: 'Target cannot be 0',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
    setIsLoading(true)

    try {
      const newData = data
      newData.companyId = globalState?.currentCompany
      newData.projectId = globalState?.currentProject
      newData.users = []
      newData.kpi = []
      newData.statsAverage = 0
      newData.statsProgress = 0
      newData.statsTarget = 0
      newData.statsTotalActual = 0
      newData.statsTotalTarget = 0
      newData.users = [globalState.uid, ...filteredDataIdUsers],
      newData.usersDisplay = [
        {
          id: auth?.currentUser?.uid,
          image: auth.currentUser?.photoURL,
          name: auth?.currentUser?.displayName,
          email: auth?.currentUser?.email,
        },
        ...filteredDataUsers
      ]


      const res = await addDocumentFirebase('kpi', data, globalState.currentCompany)
      setData({})
      toast({
        title: 'Success',
        description: `Data updated ID:${res}`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      return navigate(`/kpi/list/${res}`)

    } catch (error) {
      return toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false)
    }
  };



  const handleAddValuesToArray = (sopId) => {
    setData((prevData) => {
      const newData = { ...prevData };

      if (!('sopId' in newData)) {
        newData.sopId = [];
      }

      if (!newData.sopId.includes(sopId)) {
        newData.sopId.push(sopId);
      }

      return newData;
    });
  };

  const handleRemoveFeature = (index, key) => {
    let array = data[key];
    if (array?.length === 1) {
      array = []
    } else {
      array.splice(index, 1);
    }
    setData({
      ...data,
      [key]: array
    })
  };


  const handleSearchUsers = (q) => {
    const companyUsers = globalState?.projects?.find(
      (x) => x.id === globalState?.currentProject
    );
    const userChunks = chunkArray(companyUsers?.users, 100);

    const searchPromises = userChunks.map((userChunk) => {
      const searchParameters = {
        q: q,
        query_by: 'name,email',
        filter_by: `id: [${userChunk.join(',')}]`,
        sort_by: '_text_match:desc',
      };

      return clientTypessense
        .collections('users')
        .documents()
        .search(searchParameters);
    });

    Promise.all(searchPromises)
      .then((results) => {
        const combinedResults = results.flatMap((result) => result.hits);
        setSearchResult(combinedResults);
      })
      .catch((error) => {
        toast({
          title: 'Deoapp.com',
          duration: 3000,
          description: error.message,
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });
      });
  };

  const chunkArray = (arr, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      chunks.push(arr.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const handleUserProjectClick = (userId) => {
    setSelectedUserProjectIds((prevIds) => {
      if (prevIds.includes(userId)) {
        return prevIds.filter((id) => id !== userId);
      } else {
        return [...prevIds, userId];
      }
    });
  };

  return (


    <Stack spacing={3} >


      <Stack>
        <FormLabel>KPI Name</FormLabel>
        <Input size={'sm'} placeholder='KPI name' onChange={(e) => setData({ ...data, title: e.target.value })} />
      </Stack>

      <Stack>
        <FormLabel>Description</FormLabel>
        <Textarea size={'sm'} rows={3} resize="none" onChange={(e) => setData({ ...data, description: e.target.value })} />

      </Stack>

      <Stack>
        <Text fontWeight="bold">Tags</Text>
        <Stack flexDirection={['row']}>
          <Input
            size={'sm'}
            placeholder={'Enter one new tags ...'}
            onChange={handleNewCategory}
          />
          <Stack
            borderRadius={'sm'}
            pos={'relative'}
            borderColor={'gray.300'}
          >
            <DynamicButton
              action={'create'}
              variant='solid'
              size={'sm'}
              onClick={handleAddFeature}
            />
          </Stack>
        </Stack>
        <Box
          shadow="base"
          padding={3}
          border={'0.5px'}
          borderColor={'gray.300'}
          borderRadius={'sm'}
        >
          <HStack
            spacing={2}
            justifyContent={'flex-start'}
            alignItems={'flex-start'}
            w="100%"
          >
            <HStack>

              {data?.tags?.length > 0 ? (

                <HStack w={'200px'} overflowX={'scroll'} p="2" gap={3}>

                  {data?.tags?.map((x, i) => (
                    <Stack
                      key={i}
                      p={2}
                      border={'1px'}
                      borderRadius={'sm'}
                      shadow={'base'}
                      pos={'relative'}
                      borderColor={'gray.300'}
                      alignItems="center"
                      minW={'80px'}
                    >
                      <Box
                        cursor={'pointer'}
                        size="xs"
                        borderRadius={'full'}
                        color={'blue.600'}
                        pos={'absolute'}
                        bottom={-2}
                        right={-1}
                      >
                        <FaTag />
                      </Box>
                      <HStack >
                        <Text fontWeight={500} noOfLines={1} fontSize={'2xs'} >
                          {x}
                        </Text>
                        <Icon
                          onClick={() => handleRemoveTags(i)}
                          cursor="pointer"
                          pos={'absolute'}
                          color={'red.300'}
                          top={-1}
                          right={-1}
                          as={MdCancel}
                          boxSize={4}
                        />
                      </HStack>
                    </Stack>
                  ))}
                </HStack>
              ) : (
                <Text>No Tags data</Text>
              )}
            </HStack>

          </HStack>

        </Box>
      </Stack>



      <Divider />

      <Stack >
        <Stack>
          <FormLabel>Teams</FormLabel>

          <Input
            type="text"
            size={'sm'}
            placeholder="Search users"
            onChange={(e) => handleSearchUsers(e.target.value)}
          />
        </Stack>

        <Stack>
          <Stack
            maxH={300}
            overflowY={'auto'}
            sx={{
              '&::-webkit-scrollbar': {
                w: '2',
                h: '3',
              },
              '&::-webkit-scrollbar-track': {
                w: '6',
                h: '5',
              },
              '&::-webkit-scrollbar-thumb': {
                borderRadius: '10',
                bg: 'gray.200',
              },
            }}
          >
            {searchResult.length > 0 ? (
              searchResult.map((x, index) => {
                return (
                  <HStack key={index} p="2" borderBottom="1px">
                    <Avatar
                      name={x.document.name}
                      src={x.document.image ? x.document.image : ''}
                    />
                    <Box>
                      <Text>{x.document.name}</Text>
                      <Text>{x.document.email}</Text>
                    </Box>
                    <Spacer />
                    <Button
                      colorScheme="green"
                      onClick={() => handleUserProjectClick(x.document)}
                    >
                      +
                    </Button>
                  </HStack>
                );
              })
            ) : (
              <></>
            )}
          </Stack>
        </Stack>
      </Stack>

      <Divider />

      <HStack spacing={5}>

        <Stack>
          <FormLabel>Kpi Target</FormLabel>
          <HStack spacing={3}>
            <Stack>
              <Input size={'sm'} placeholder='KPI Target' type='number' onChange={(e) => setData({ ...data, target: e.target.value })} />
            </Stack>
            <Stack>
              <Select size={'sm'} placeholder='Format' w='50' onChange={(e) => setData({ ...data, format: e.target.value })}>
                <option value='number'>123.00</option>
                <option value='percentage'>123.00%</option>
                <option value='idr'>IDR 123</option>
                <option value='usd'>USD 123.00</option>
              </Select>
            </Stack>

          </HStack>
        </Stack>


        <Stack>
          <FormLabel>Avarage</FormLabel>
          <Checkbox onChange={(e) => setData({ ...data, isAvarage: e.target.checked })} />
        </Stack>
        <Stack >
          <FormLabel>Is Template</FormLabel>
          <Checkbox onChange={(e) => setData({ ...data, isTemplate: e.target.checked })} />
        </Stack>
      </HStack>



      <Stack flexDirection={['column', 'row', 'row']} gap={3} >
        <Stack w={'full'}>
          <FormLabel>Kpi Period</FormLabel>
          <Select size={'sm'} placeholder='Select option' w='auto' onChange={(e) => setData({ ...data, period: e.target.value })}>
            <option value='daily'>Daily</option>
            <option value='weekly'>Weekly</option>
            <option value='monthly'>Monthly</option>

          </Select>
        </Stack>

        <Stack w={'full'}>
          <FormLabel>KPI Achievement</FormLabel>
          <Select size={'sm'} placeholder='Select option' w='-moz-min-content' onChange={(e) => setData({ ...data, achivement: e.target.value })}>
            <option value='high'>Higher better</option>
            <option value='low'>Lower better</option>
          </Select>
        </Stack>
      </Stack>


      <Stack>
        <DynamicMultiSelectComponent
          title={'SOPs for this KPI'}
          listOptions={dataSop}
          selectedOptions={data?.sopId}
          masterData={dataSop}
          avatar={false}
          handleAddSelection={handleAddValuesToArray}
          hanldeDeleteSelection={handleRemoveFeature}
          select={true}
          stringKey={'sopId'}
        />


      </Stack>






      <HStack
        pb={5}
        alignItems={'flex-end'}
        justifyContent={'flex-end'}
      >

        <AvatarGroup size="sm" gap="1" max={4}>
          {selectedUserProjectIds.length > 0 &&
            selectedUserProjectIds.map((x, i) => (
              <Avatar key={i} name={x?.name} />
            ))}
        </AvatarGroup>

        <DynamicButton isLoading={isLoading} action={'create'} variant={'solid'} onClick={() => handleAdd()} title={'Save'} />

      </HStack>

    </Stack>


  )
}

export default KpiNewPage
