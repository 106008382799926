import React, { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import Layout from '../Layouts';
import HomeRouter from './HomeRouter';
import SettingRouter from './SettingRouter';
import AuthenticationMainRouter from './AuthenticationMainRouter';
import SopRouter from './SopRouter';
import KpiRouter from './KpiRouter';
import OkrRouter from './OkrRouter';
import ConfigurationRouter from './ConfigurationRouter';
import WorkflowRouter from './WorkflowRouter';
import WorkflowDetailPage from '../Pages/Workflow/WorkflowDetailPage';



function MainRouter() {

  const location = useLocation()
  const navigate = useNavigate()

  const allRouter = [
    ...AuthenticationMainRouter,
    ...SettingRouter,
    ...ConfigurationRouter,
    ...SopRouter,
    ...KpiRouter,
    ...OkrRouter,
    ...HomeRouter,
    ...ConfigurationRouter,
    ...WorkflowRouter
  ];


  useEffect(() => {
    const path = location.pathname;

    if (path === '/billings') {
      return navigate('/');
    }

    return () => {
      // Cleanup, if needed
    };
  }, [location.pathname]);



  return (
    <Routes>
      <Route
        path="/workflow/:id"
        element={<WorkflowDetailPage />}
      />
      {allRouter.map((item, index) => {
        return <Route key={index} path={item.path} element={<Layout>{item.element}</Layout>} />;
      })}
    </Routes>
  );
}

export default MainRouter;
