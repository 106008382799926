/* eslint-disable react/prop-types */
import React, { useRef } from 'react';
import { Button, HStack, Text } from '@chakra-ui/react';
import { FaDownload } from 'react-icons/fa';
import SopPrintPdf from './SopPrintTemplate';

const SopPrintButton = ({ data, globalState }) => {
  const sopPrintPdfRef = useRef(null);

  const searchCompanyName = globalState?.companies?.find(
    (x) => x.id === globalState?.currentCompany
  );

  const handleWindowPrint = () => {
    const content = sopPrintPdfRef.current.innerHTML;
    const printWindow = window.open('', '_blank');
    const style = `
    <style>
      @page {
        size: auto;
        margin: 0mm;
      }
      body {
        margin-top: 20px; /* Add margin to the top of each page */
      }
    </style>
  `;

    printWindow.document.write(
      `<html><head>${style}<title>${searchCompanyName?.name}</title></head><body>`
    );

    printWindow.document.write(
      `<html><head><title>${searchCompanyName?.name}</title></head><body>`
    );
    printWindow.document.write(content);
    printWindow.document.write('</body></html>');
    printWindow.document.close();

    printWindow.print();
  };
  return (
    <>
      <Button colorScheme="blue" size={'sm'} onClick={handleWindowPrint}>
        <HStack>
          <FaDownload />
          <Text>{'Download'}</Text>
        </HStack>
      </Button>

      <div
        className="sop-print-pdf"
        ref={sopPrintPdfRef}
        style={{ display: 'none' }}
      >
        <SopPrintPdf data={data} globalState={globalState} company={searchCompanyName?.name} />
      </div>
    </>
  );
};

export default SopPrintButton;
