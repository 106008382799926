/* eslint-disable react/prop-types */
import {
  Box,
  FormLabel,
  HStack,
  Input,
  Select,
  Text,
  Textarea,
  useToast,
  Checkbox,
  Stack,
  Divider,
  Icon,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { updateDocumentFirebase } from '../../../Api/firebaseApi';
import DynamicButton from '../../../Components/Buttons/DynamicButton';
import DynamicMultiSelectComponent from '../../../Components/Select/DynamicMultiSelectComponent';
import { MdCancel } from 'react-icons/md';
import { FaTag } from 'react-icons/fa';

function KpiEditComponent({ dataSop, dataKpi, onClose, globalState }) {
  const [data, setData] = useState(dataKpi || {});
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [newTags, setNewTags] = useState('');

  const handleNewCategory = (e) => {
    setNewTags(e.target.value.toLowerCase());
  };

  const handleRemoveTags = (index) => {
    const updatedFeatures = [...data.tags];
    updatedFeatures.splice(index, 1);
    setData({ ...data, tags: updatedFeatures });
  };

  const handleAddFeature = async () => {
    const existingFeatures = Array.isArray(data?.tags) ? data?.tags : [];

    const newFeatureList = [...existingFeatures, newTags];
    setData({ ...data, tags: newFeatureList });
  };

  const handleAdd = async () => {
    if (data.createdBy !== globalState.uid) {
      if (
        globalState.roleCompany !== 'owner' &&
        globalState.roleProject === 'user'
      ) {
        return toast({
          title: 'Alert',
          description: 'You dont hace any access',
          status: 'warning',
          duration: 9000,
          isClosable: true,
        });
      }
    }

    if (
      !data.achivement ||
      !data.description ||
      !data.format ||
      !data.period ||
      !data.target ||
      !data.title
    ) {
      return toast({
        title: ('Error'),
        description: 'Please fill all form',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }

    setIsLoading(true);
    const newData = data;
    newData.lastUpdated = new Date();

    try {
      const res = await updateDocumentFirebase('kpi', data.id, newData);
      toast({
        title: 'Success',
        description: `Data updated ID:${res}`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      setData({});

      return onClose();
    } catch (error) {
      return toast({
        title: ('error'),
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddValuesToArray = (sopId) => {
    setData((prevData) => {
      const newData = { ...prevData };

      if (!('sopId' in newData)) {
        newData.sopId = [];
      }

      if (!newData.sopId.includes(sopId)) {
        newData.sopId.push(sopId);
      }

      return newData;
    });
  };

  const handleRemoveFeature = (index, key) => {
    let array = data[key];
    if (array?.length === 1) {
      array = [];
    } else {
      array.splice(index, 1);
    }
    setData({
      ...data,
      [key]: array,
    });
  };

  return (
    <Stack spacing={3}>
      <Stack>
        <FormLabel>KPI Name</FormLabel>
        <Input
          size={'sm'}
          placeholder="KPI name"
          defaultValue={data.title}
          onChange={(e) => setData({ ...data, title: e.target.value })}
        />
      </Stack>

      <Stack>
        <FormLabel>Description</FormLabel>
        <Textarea
          size={'sm'}
          rows={3}
          resize="none"
          defaultValue={data.description}
          onChange={(e) => setData({ ...data, description: e.target.value })}
        />
      </Stack>

      <Stack>
        <Text fontWeight="bold">Tags</Text>
        <Stack flexDirection={['row']}>
          <Input
            size={'sm'}
            placeholder={'Enter one new tags ...'}
            onChange={handleNewCategory}
          />
          <Stack borderRadius={'sm'} pos={'relative'} borderColor={'gray.300'}>
            <DynamicButton
              action={'create'}
              variant="solid"
              size={'sm'}
              onClick={handleAddFeature}
            />
          </Stack>
        </Stack>
        <Box
          shadow="base"
          padding={3}
          border={'0.5px'}
          borderColor={'gray.300'}
          borderRadius={'sm'}
        >
          <HStack
            spacing={2}
            justifyContent={'flex-start'}
            alignItems={'flex-start'}
            w="100%"
          >
            <HStack>
              {data?.tags?.length > 0 ? (
                <HStack w={'200px'} overflowX={'scroll'}  sx={{
                  '&::-webkit-scrollbar': {
                    w: '2',
                    h: '3',
                  },
                  '&::-webkit-scrollbar-track': {
                    w: '6',
                    h: '5',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    borderRadius: '10',
                    bg: 'gray.200',
                  },
                }} p="2" gap={3}>
                  {data?.tags?.map((x, i) => (
                    <Stack
                      key={i}
                      p={2}
                      border={'1px'}
                      borderRadius={'sm'}
                      shadow={'base'}
                      pos={'relative'}
                      borderColor={'gray.300'}
                      alignItems="center"
                      minW={'80px'}
                    >
                      <Box
                        cursor={'pointer'}
                        size="xs"
                        borderRadius={'full'}
                        color={'blue.600'}
                        pos={'absolute'}
                        bottom={-2}
                        right={-1}
                      >
                        <FaTag />
                      </Box>
                      <HStack>
                        <Text fontWeight={500} noOfLines={1} fontSize={'2xs'}>
                          {x}
                        </Text>
                        <Icon
                          onClick={() => handleRemoveTags(i)}
                          cursor="pointer"
                          pos={'absolute'}
                          color={'red.300'}
                          top={-1}
                          right={-1}
                          as={MdCancel}
                          boxSize={4}
                        />
                      </HStack>
                    </Stack>
                  ))}
                </HStack>
              ) : (
                <Text>No Tags data</Text>
              )}
            </HStack>
          </HStack>
        </Box>
      </Stack>

      <Divider />

      <HStack spacing={5}>
        <Stack>
          <FormLabel>Kpi Target</FormLabel>
          <HStack spacing={3}>
            <Stack>
              <Input
                size={'sm'}
                placeholder="KPI Target"
                type="number"
                defaultValue={data.target}
                onChange={(e) => setData({ ...data, target: e.target.value })}
              />
            </Stack>
            <Stack>
              <Select
                size={'sm'}
                placeholder="Format"
                w="50"
                defaultValue={data.format}
                onChange={(e) => setData({ ...data, format: e.target.value })}
              >
                <option value="number">123.00</option>
                <option value="percentage">123.00%</option>
                <option value="idr">IDR 123</option>
                <option value="usd">USD 123.00</option>
              </Select>
            </Stack>
          </HStack>
        </Stack>

        <Stack>
          <FormLabel>Avarage</FormLabel>
          <Checkbox
            defaultChecked={data.isAvarage}
            onChange={(e) => setData({ ...data, isAvarage: e.target.checked })}
          />
        </Stack>
        <Stack>
          <FormLabel>Is Template</FormLabel>
          <Checkbox
            defaultChecked={data.isTemplate}
            onChange={(e) => setData({ ...data, isTemplate: e.target.checked })}
          />
        </Stack>
      </HStack>

      <Stack flexDirection={['column', 'row', 'row']} gap={3}>
        <Stack w={'full'}>
          <FormLabel>Kpi Period</FormLabel>
          <Select
            size={'sm'}
            placeholder="Select option"
            w="auto"
            defaultValue={data.period}
            onChange={(e) => setData({ ...data, period: e.target.value })}
          >
            <option value="daily">Daily</option>
            <option value="weekly">Weekly</option>
            <option value="monthly">Monthly</option>
          </Select>
        </Stack>

        <Stack w={'full'}>
          <FormLabel>KPI Achievement</FormLabel>
          <Select
            size={'sm'}
            placeholder="Select option"
            w="-moz-min-content"
            defaultValue={data.achivement}
            onChange={(e) => setData({ ...data, achivement: e.target.value })}
          >
            <option value="high">Higher better</option>
            <option value="low">Lower better</option>
          </Select>
        </Stack>
      </Stack>

      <Stack>
        <DynamicMultiSelectComponent
          title={'SOPs for this KPI'}
          listOptions={dataSop}
          selectedOptions={data?.sopId}
          masterData={dataSop}
          avatar={false}
          handleAddSelection={handleAddValuesToArray}
          hanldeDeleteSelection={handleRemoveFeature}
          select={true}
          stringKey={'sopId'}
        />
      </Stack>

      <HStack pb={5} alignItems={'flex-end'} justifyContent={'flex-end'}>
        <DynamicButton
          isLoading={isLoading}
          action={'create'}
          variant={'solid'}
          onClick={() => handleAdd()}
          title={'Save'}
        />
      </HStack>
    </Stack>
  );
}

export default KpiEditComponent;
