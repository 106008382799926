import React from 'react'
import ReactApexChart from 'react-apexcharts';

import PropTypes from 'prop-types';
import { formatFrice } from '../../../Utils/numberUtil';
LineAreaChart.propTypes = {
  data: PropTypes.object.isRequired,
};

function LineAreaChart(props) {

  const propsData = props.data;

  const categories = propsData?.period?.data?.map((x) => `${propsData.period.status.toUpperCase()} ${x.title}`)

  const dataSetActual= propsData?.period?.data?.map((x) => {
    const search = propsData?.data?.find((y) => y?.title===x?.title)?.actual
    if(search) return search
    else return 0
  })

  const dataSetTarget= propsData?.period?.data?.map((x) => {
    const search = propsData?.data?.find((y) => y?.title===x?.title)?.target
    if(search) return formatFrice(search)
    else return propsData.period.target
  })


  const options = {
    series: [{
      name: 'Actual',
      type: 'area',
      data: dataSetActual
    }, {
      name: 'Target',
      type: 'line',
      data: dataSetTarget
    }],
    options: {
      chart: {
        height: 350,
        type: 'line',
      },
      stroke: {
        curve: 'smooth'
      },
      fill: {
        type:'solid',
        opacity: [0.35, 1],
      },
      labels: categories,
      markers: {
        size: 0
      },
      //   yaxis: [
      //     {
      //       title: {
      //         text: 'Series A',
      //       },
      //     },
      //     {
      //       opposite: true,
      //       title: {
      //         text: 'Series B',
      //       },
      //     },
      //   ],
      tooltip: {
        shared: true,
        intersect: false,
        y: {
          formatter: function (y) {
            if(typeof y !== 'undefined') {
              return  y.toFixed(0);
            }
            return y;
          }
        }
      }
    },
      
      
  }
  return (
    <ReactApexChart options={options.options} series={options.series} type="line" height={350} />

  )
}

export default LineAreaChart
