import React from 'react';
import WorkflowPage from '../Pages/Workflow/WorkflowPage';

const WorkflowRouter = [
  {
    path: '/workflow',
    element: <WorkflowPage />,
  }
];

export default WorkflowRouter;
