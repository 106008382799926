import { Box, HStack, Heading, 
  Progress,
  Avatar,
  Icon,
  AvatarGroup,
  Stack, 
  useToast} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import BackButtons from '../../Components/Buttons/BackButtons'
import { FcElectricalSensor } from 'react-icons/fc'
import KpiTableComponent from '../Kpi/Component/KpiTableComponent'
import { useParams } from 'react-router-dom'
import { colorSchemeNumberUtil } from '../../Utils/colorUtil'
import { doc, onSnapshot, } from 'firebase/firestore'
import { db } from '../../Config/firebase'
  
function OkrViewPage() {
  const [data,setData]=useState({})
  const param = useParams()
  const [dataTeam, setDataTeam] = useState({})
  const toast = useToast()



  useEffect(() => {

    let unsub = ''
    try {
      unsub = onSnapshot(doc(db, 'okr', param.id), (doc) => {
        doc.metadata.hasPendingWrites ? 'Local' : 'Server';
        setData(doc.data())
      })
  
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error?.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }

    return () => {
      unsub()
      setData()
    }
  }, [])




  return (
    <Stack p={[1, 1, 5]} spacing={5}>
      <HStack>
        <BackButtons/>
        <Heading>OKR </Heading>
      </HStack>

      <Box p='5'  shadow='base' >
        <HStack>
          <Icon as={FcElectricalSensor} boxSize={10} />
          <Box w='full'>
            <Heading >{data?.title} </Heading>
          </Box>
          {dataTeam?.teamArr?.length > 0 && (
            <AvatarGroup
              cursor="pointer"
              max={7}
              size={'sm'}
            >
              {dataTeam?.teamArr?.map((user, i) => {
                return (
                  <Avatar key={i} size={'sm'} name={user?.name} src={user?.image || user?.name} />
                )
              })}
            </AvatarGroup>
          )}      
         
        </HStack>

        <HStack>
          <Progress borderRadius='md'  value={dataTeam?.teamProgress} colorScheme={colorSchemeNumberUtil(parseInt(dataTeam?.teamProgress))} w='full'/>
          <Heading >{dataTeam?.teamProgress?parseInt(dataTeam?.teamProgress):0}% </Heading>
        </HStack>

        <KpiTableComponent data={data} setData={setData} setDataTeam={setDataTeam}/>

      </Box>

    
      

    </Stack>
  )
}

export default OkrViewPage
