import React from 'react'
import MainMenuComponent from '../../Components/Menus/MainMenuComponent'

function KpiPage() {
  return (
    <MainMenuComponent menuName='KPI' />

  )
}

export default KpiPage
