/* eslint-disable react/prop-types */
import { Box, Button, Flex, FormLabel, HStack, Icon, Input, Select, Stack, Text, useToast } from '@chakra-ui/react'
import React, { useState } from 'react'
import moment from 'moment'
import { updateDocumentFirebase } from '../../../Api/firebaseApi'
import DynamicButton from '../../../Components/Buttons/DynamicButton'
import { FaTag } from 'react-icons/fa'
import { MdCancel } from 'react-icons/md'

function OkrEditComponent({dataOkr, onClose}) {
  const[data,setData]=useState(dataOkr || {})
  const toast = useToast();
  const [newTags, setNewTags] = useState('');
  const [loading, setLoading] = useState(false)



  const handleAdd = async() => {
    if (!data.period || !data.title) {
      return toast({
        title: 'Error',
        description: 'Please fill all form',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
    setLoading(true)
    const newPeriod = JSON.parse(data.period)
    const newData = {...data,...newPeriod}
    delete newData['period'];
    delete newData.dataTeam
    

    try {
      await updateDocumentFirebase('okr',data?.id,newData)
      
      toast({
        title: 'Success',
        description: 'OKR updated!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      onClose()
    } catch (error) {
      return toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });  
    }finally{
      setLoading(false)
    }

  }

  const handleNewCategory = (e) => {
    setNewTags((e.target.value).toLowerCase());
  };

  const handleRemoveTags = (index) => {
    const updatedFeatures = [...data.tags];
    updatedFeatures.splice(index, 1);
    setData({ ...data, tags: updatedFeatures });
  };

  const handleAddFeature = async () => {
    const existingFeatures = Array.isArray(data?.tags) ? data?.tags : [];

    const newFeatureList = [...existingFeatures, newTags];
    setData({ ...data, tags: newFeatureList });
  };


  return (
    <Stack bg="bg.surface" borderRadius="lg" spacing={5}  >

      <Stack>  
        <FormLabel>OKR Name</FormLabel>
        <Input size={'sm'} placeholder='OKR name' defaultValue={data?.title} onChange={(e) => setData({...data,title:e.target.value})}/>
      </Stack>
      <Stack>
        <FormLabel>OKR Periode</FormLabel>
        <Select size={'sm'}  defaultValue={data.period} placeholder='Select option' w='auto' onChange={(e) => setData({...data,period:e.target.value})}>
          <option value={JSON.stringify({periodType:'Q',periodTarget:0,periodTimestamp:moment().unix()})}>Q{moment().startOf('Q').add(0, 'Q').format('Q-YY')}</option>
          <option value={JSON.stringify({periodType:'Q',periodTarget:1,periodTimestamp:moment().unix()})}>Q{moment().startOf('Q').add(1, 'Q').format('Q-YY')}</option>
          <option value={JSON.stringify({periodType:'Q',periodTarget:2,periodTimestamp:moment().unix()})}>Q{moment().startOf('Q').add(2, 'Q').format('Q-YY')}</option>
          <option value={JSON.stringify({periodType:'Q',periodTarget:3,periodTimestamp:moment().unix()})}>Q{moment().startOf('Q').add(3, 'Q').format('Q-YY')}</option>
          <option value={JSON.stringify({periodType:'y',periodTarget:0,periodTimestamp:moment().unix()})}>Y{moment().startOf('y').add(0, 'y').format('YYYY')}</option>
          <option value={JSON.stringify({periodType:'y',periodTarget:1,periodTimestamp:moment().unix()})}>Y{moment().startOf('y').add(1, 'y').format('YYYY')}</option>
        </Select>
      </Stack>  
      <Stack>
        <FormLabel>Tags</FormLabel>
        <Stack flexDirection={['row']}>
          <Input
            size={'sm'}
            placeholder={'Enter one new tags ...'}
            onChange={handleNewCategory}
          />
          <Stack
            borderRadius={'sm'}
            pos={'relative'}
            borderColor={'gray.300'}
          >
            <DynamicButton
              action={'create'}
              variant='solid'
              size={'sm'}
              onClick={handleAddFeature}
            />
          </Stack>
        </Stack>
        <Box
          shadow="base"
          padding={3}
          border={'0.5px'}
          borderColor={'gray.300'}
          borderRadius={'sm'}
        >
          <HStack
            spacing={2}
            justifyContent={'flex-start'}
            alignItems={'flex-start'}
            w="100%"
          >
            <HStack>

              {data?.tags?.length > 0 ? (

                <HStack w={'200px'} overflowX={'scroll'}  sx={{
                  '&::-webkit-scrollbar': {
                    w: '2',
                    h: '3',
                  },
                  '&::-webkit-scrollbar-track': {
                    w: '6',
                    h: '5',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    borderRadius: '10',
                    bg: 'gray.200',
                  },
                }} p="2" gap={3}>

                  {data?.tags?.map((x, i) => (
                    <Stack
                      key={i}
                      p={2}
                      border={'1px'}
                      borderRadius={'sm'}
                      shadow={'base'}
                      pos={'relative'}
                      borderColor={'gray.300'}
                      alignItems="center"
                      minW={'80px'}
                    >
                      <Box
                        cursor={'pointer'}
                        size="xs"
                        borderRadius={'full'}
                        color={'blue.600'}
                        pos={'absolute'}
                        bottom={-2}
                        right={-1}
                      >
                        <FaTag />
                      </Box>
                      <HStack >
                        <Text fontWeight={500} noOfLines={1} fontSize={'2xs'} >
                          {x}
                        </Text>
                        <Icon
                          onClick={() => handleRemoveTags(i)}
                          cursor="pointer"
                          pos={'absolute'}
                          color={'red.300'}
                          top={-1}
                          right={-1}
                          as={MdCancel}
                          boxSize={4}
                        />
                      </HStack>
                    </Stack>
                  ))}
                </HStack>
              ) : (
                <Text>No Tags data</Text>
              )}
            </HStack>

          </HStack>

        </Box>
      </Stack>

      <Flex
        direction="row-reverse"
        py="4"
        px={{
          base: '4',
          md: '6',
        }}
      >
        <Button colorScheme='green' w='full' onClick={() => handleAdd()} isLoading={loading}>Save</Button>

      </Flex>

    </Stack>
  )
}

export default OkrEditComponent
