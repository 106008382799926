/* eslint-disable no-undef */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/prop-types */
import { Text, HStack, Heading, Spacer, Stack, Button } from '@chakra-ui/react';
import React, { useState } from 'react';
import BackButtons from '../../Components/Buttons/BackButtons';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import ChatViewSop from './Component/ChatViewSop';
import useUserStore from '../../Hooks/Zustand/Store';
import moment from 'moment';
import { setDocumentFirebase } from '../../Api/firebaseApi';
import { FaQuestion, FaWhatsapp } from 'react-icons/fa';

function SopAiPage() {
  const [step, setStep] = useState('start');

  const globalState = useUserStore();

  const userId = globalState?.uid;
  const companyId = globalState?.currentCompany;
  const projectId = globalState?.currentProject;
  const accessTokenDb = globalState?.accesToken;

  const [idMessage, setIdMessage] = useState('');
  const [openChat, setOpenChat] = useState(false);

  const [notif] = useState(0);

  const handleChatButtonClick = async () => {
    handleNewChat();
    setOpenChat((prev) => !prev);
    setStep('chat');
  };

  const handleBackButtonClick = () => {
    setOpenChat((prev) => !prev);
    setStep('start');
  };

  const handleNewChat = async () => {
    const collectionName = 'chat_customer';
    const docName = `${userId}-${projectId}`;
    const data = {
      companyId: companyId,
      projectId: projectId,
      karyawanAi: '',
      createdAt: moment(new Date()).unix(),
      user: globalState.uid,
    };

    try {
      await setDocumentFirebase(collectionName, docName, data, companyId);
      setIdMessage(docName);
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  const handleOpenChat = () => {
    handleChatButtonClick();
  };

  const handleWhatsapp = () => {
    const message = encodeURIComponent(('Hi, Please leave your message, and our Support Team will reply soon 😊'));
    const source = encodeURIComponent(window.location.href);
    const url = `https://api.whatsapp.com/send?phone=6282211429125&text=${message}%0A%0ASource:%20${source}`;
    window.open(url, '_blank');
  };

  return (
    <Stack p={[1, 1, 5]}>
      <Stack spacing={5}>
        <HStack flexDirection={['column', 'column', 'row']}>
          <BackButtons />
          <Heading size={'md'}>SOP AI</Heading>
          <Spacer />

          {step === 'chat' ? (
            <DynamicButton
              action={'custom'}
              title={('Close')}
              variant={'solid'}
              color={'red'}
              onClick={handleBackButtonClick}
            />
          ) : (
            <></>
          )}
          <Button colorScheme="green" onClick={handleWhatsapp}>
            <HStack>
              <FaWhatsapp size={25} />
              <Text fontSize={'sm'}>{('Help')}</Text>
            </HStack>
          </Button>
        </HStack>

        {step === 'chat' ? (
          <ChatViewSop
            step={step}
            openChat={openChat}
            id={idMessage}
            companyId={companyId}
            projectId={projectId}
            notif={notif}
            userId={userId}
            accessTokenDb={accessTokenDb}
            globalState={globalState}
          />
        ) : (
          <Stack
            spacing={3}
            minH="50vh"
            alignItems={'center'}
            justifyContent="center"
          >
            <FaQuestion color="gray" size={50} />
            <Text
              textAlign="center"
              color={'gray.500'}
              fontSize="2xl"
              fontWeight={'bold'}
            >
              {('How can I help you today?')}
            </Text>
            <Text
              fontWeight={500}
              fontSize="lg"
              textAlign="center"
              color={'gray.500'}
            >
              {('An AI Platform for Consultation and Automatic SOP Template Generator! ')}
            </Text>
            <Stack pt={5}>
              <DynamicButton
                action={'custom'}
                title={('Start Conversation')}
                variant={'solid'}
                color={'blue'}
                onClick={handleOpenChat}
              />
            </Stack>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}

export default SopAiPage;
