/* eslint-disable no-undef */
import axios from 'axios';

const FLOWISE_API_KEY = process.env.REACT_APP_FLOWISE_KEY;
const FLOWISE_HOST = process.env.REACT_APP_FLOWISE_HOST;

export const callFlowiseApi = async (chat, globalState) => {
  const { uid, currentProject } = globalState;

  const query = {
    question: chat,
    overrideConfig: {
      sessionId: `${uid}-${currentProject}`,
    },
  };

  try {
    const response = await axios.post(FLOWISE_HOST, query, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${FLOWISE_API_KEY}`,
      },
    });

    return response.data;
  } catch (error) {
    throw new Error
  }
};
