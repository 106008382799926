/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import {
  Avatar,
  AvatarBadge,
  Box,
  Button,
  HStack,
  Icon,
  Input,
  Spacer,
  Spinner,
  Stack,
  Text,
  useToast,
  Tooltip,
  useColorMode,
} from '@chakra-ui/react';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import parse from 'html-react-parser';
import { IoSend } from 'react-icons/io5';
import DropboxUploader from '../../../Components/DropBox/DropboxUploader';
import {
  getSingleDocumentFirebase,
  updateDocumentFirebase,
} from '../../../Api/firebaseApi';
import { db } from '../../../Config/firebase';
import {
  addDoc,
  collection,
  doc,
  getCountFromServer,
  increment,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import { FiCopy } from 'react-icons/fi';
import { callFlowiseApi } from '../../../Api/FlowiseApi';
import _axios from '../../../Api/AxiosBarrier';
import { getTokenSessions } from '../../../Api/ApiRole';

function ChatViewSop({
  openChat,
  id,
  companyId,
  notif,
  userId,
  accessTokenDb,
  globalState,
}) {
  const messagesEndRef = useRef(null);

  const { colorMode } = useColorMode();
  const [isModalOpen, setModalOpen] = useState(false);
  const [shareLink, setShareLink] = useState('');
  const [value, setValue] = useState('');
  const [dataCustomer, setDataCustomer] = useState('');
  const [sessionsUsers, setSessionsUsers] = useState('')

  const toast = useToast();

  const [messageLoading, setMessageLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const itemsPerPage = 5;

  const [chat, setChat] = useState('');
  const [chatData, setChatData] = useState([]);
  const [sopUsage, setSopUsage] = useState(0);

  const handleShareLinkChange = (x) => {
    if (x !== '') {
      setShareLink({ link: x.link, type: x.type });
      const { link, type } = x;
      let htmlContent = '';

      if (type === 'image') {
        htmlContent = `<p><img src="${link}" alt="Image" width="500px" /></p><br/> <p>file: <a href=${link} rel="noopener noreferrer" target="_blank">${JSON.stringify(
          link
        )}</a></p>`;
      } else if (type === 'audio') {
        htmlContent = `<p><iframe class="ql-video" frameborder="0" allowfullscreen="true" src=${link}></iframe></p><br/> <p>file: <a href=${link} rel="noopener noreferrer" target="_blank">${JSON.stringify(
          link
        )}</a></p>`;
      } else if (type === 'video') {
        htmlContent = `<p><iframe class="ql-audio" frameborder="0" allowfullscreen="true" src=${link}></iframe></p><br/> <p>file: <a href=${link} rel="noopener noreferrer" target="_blank">${JSON.stringify(
          link
        )}</a></p>`;
      } else {
        htmlContent = `<p>file: <a href=${link} rel="noopener noreferrer" target="_blank">${JSON.stringify(
          link
        )}</a></p><br/> `;
      }

      setValue((prevContent) => prevContent + ` ${htmlContent}`);
    }
  };

  const getDataCustomer = async () => {
    if (!id) return;
    try {
      const docRef = doc(db, 'chat_customer', id);
      const unsubscribe = onSnapshot(docRef, (doc) => {
        if (doc.exists()) {
          const data = doc.data();
          setDataCustomer(data);
          setSopUsage(data?.sopUsage ? data?.sopUsage * 50 : 'No Data');
        }
      });
      return unsubscribe;
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  const getDataChat = async () => {
    if (!id) return;

    const startIndex = (currentPage - 1) * itemsPerPage;
    const limitValue = startIndex + itemsPerPage;
    const q = query(
      collection(db, 'chat'),
      where('projectId', '==', globalState.currentProject),
      // where('user', '==', globalState.uid),
      where('chatId', '==', id),
      orderBy('createdAt', 'desc'),
      limit(limitValue)
    );

    return new Promise((resolve, reject) => {
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const chats = [];
        querySnapshot.forEach((doc) => {
          chats.push(doc.data());
        });
        setChatData(chats); // Set the chat data once outside the loop

        const collectionRef = collection(db, 'chat');
        const countQuery = query(
          collectionRef,
          where('projectId', '==', globalState.currentProject),
          // where('user', '==', globalState.uid),
          where('chatId', '==', id)
        );

        getCountFromServer(countQuery)
          .then((snapshot) => {
            const assessmentLength = snapshot.data().count;
            setTotalPages(Math.ceil(assessmentLength / itemsPerPage));
            resolve(); // Resolve the promise when both data and count are fetched
          })
          .catch((error) => {
            reject(error); // Reject the promise in case of an error
          });
      });

      // Return the unsubscribe function to clean up the listener when needed
      return unsubscribe;
    });
  };

  const getDataSessions = async() => {
    try {
      const res = await getTokenSessions(globalState)
      setSessionsUsers(res.customToken)
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  }


  useEffect(() => {
    getDataCustomer();
    getDataChat();
    getDataSessions()

    return () => {
      setChatData([]);
      setValue('');
      setSessionsUsers()
    };
  }, [id, openChat, currentPage]);

  const updateChatCustomer = async (data) => {
    const message = {
      message: data?.message,
      characters: data?.characters,
      createdAt: moment().unix(),
      lastUpdated: new Date(),
      status: 'unread',
    };
    try {
      await updateDocumentFirebase(
        'chat_customer',
        `${globalState.uid}-${globalState.currentProject}`,
        {
          ...message,
          sopUsage: increment(1),
        }
      );
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  const handleChat = async () => {
    if (chat.trim() === '') {
      return;
    }

    setMessageLoading(true);
    setChat('');

    const insertData = {
      companyId: globalState.currentCompany,
      projectId: globalState.currentProject,
      message: chat,
      characters: chat.length,
      type: 'human',
      chatId: id,
      user: globalState?.uid,
      status: 'unread',
      medium: 'web',
      createdAt: moment().unix(),
      lastUpdated: new Date(),
    };

    try {
      await addDoc(collection(db, 'chat'), insertData);
      // if (userDoc) await updateChatCustomer(insertData);

      const result = await callFlowiseApi(chat, globalState);
      const characterOri = result?.text?.length;

      // insert to firestore AI
      const aiReply = {
        companyId: globalState.currentCompany,
        projectId: globalState.currentProject,
        message: result.text,
        characters: characterOri,
        chatId: id,
        type: 'AI',
        user: 'AI',
        createdAt: moment().unix(),
        lastUpdated: new Date(),
        status: 'read',
      };
      const aiDoc = await addDoc(collection(db, 'chat'), aiReply);

      if (aiDoc) await updateChatCustomer(aiReply);

      setMessageLoading(false);
    } catch (error) {
      toast({
        title: 'Error!',
        description: error.message,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
      setChat('');
    } finally {
      setChat('');
      setMessageLoading(false);
    }
  };

  const handleCopy = (id) => {
    navigator.clipboard.writeText(id);
    toast({
      description: 'Copy to clipboard.',
      status: 'success',
      duration: 3000,
    });
  };

  const handleLoadMore = () => {
    setCurrentPage((prev) => prev + 1);
  };

  function openModal() {
    setModalOpen(true);
  }

  function closeModal() {
    setModalOpen(false);
  }

  return (
    <Stack spacing={5} shadow={'lg'} borderRadius={'lg'} >
      <Stack
        bgColor={colorMode === 'dark' ? 'gray.700' : 'blackAlpha.50'}
        borderRadius={'lg'}
        
      >
        <HStack
          p="5"
          spacing={5}
          bg={'blackAlpha.500'}
          borderTopRadius={'lg'}
          color={'white'}
        >
          <Avatar>
            <AvatarBadge
              boxSize="1.25em"
              bg="green.500"
              border={'2px solid white'}
            />
          </Avatar>
          <Text textTransform={'capitalize'} fontWeight={500} fontSize={'lg'}>
            SOP AI
          </Text>
          <Spacer />
          {notif === 0 ? null : (
            <Text color={'red.400'} fontWeight={500} fontSize={'sm'}>
              {notif} {('Notification')}
            </Text>
          )}
          <Text textTransform={'capitalize'} fontWeight={500} fontSize={'lg'}>
            {('Usage')}: {sopUsage}
          </Text>
        </HStack>

        {currentPage < totalPages ? (
          <Stack>
            <Button onClick={handleLoadMore} variant={'none'}>
              {('Load More')}
            </Button>
          </Stack>
        ) : (
          <></>
        )}

        <Stack
          overflowY="scroll"
          h={'500px'}
          px="5"
          py="4"
          direction={'column-reverse'}
          
        >
          {chatData?.length > 0 && (
            <>
              {chatData?.map((x, i) => (
                <HStack
                  key={i}
                  justifyContent={'flex-end'}
                  alignItems={'flex-start'}
                >
                  {x?.user === userId ? (
                    <Tooltip
                      label={
                        globalState?.users.find((user) => user.id === userId)
                          ?.name
                      }
                      aria-label="Name"
                    >
                      <Avatar
                        size="sm"
                        name={
                          globalState?.users.find((user) => user.id === userId)
                            ?.name
                        }
                      />
                    </Tooltip>
                  ) : (
                    <Avatar size="sm" />
                  )}
                  <Stack
                    w={'100%'}
                    textColor={colorMode === 'light' ? 'gray.700' : 'white'}
                    fontSize={'lg'}
                  >
                    <Text
                      color="fg.muted"
                      fontWeight={'semibold'}
                      textAlign={'baseline'}
                    >
                      {x.user !== userId ? 'SOP AI' : 'You'}
                    </Text>

                    {x?.media && (
                      <Stack spacing={0}>
                        {parse(x.media, {
                          replace: (domNode) => {
                            if (domNode.type === 'text') {
                              const textWithLinksReplaced =
                                domNode.data.replace(
                                  /(\b(?:https?:\/\/|www\.)[^\s]+)/g,
                                  (match) => {
                                    const url = match.startsWith('http')
                                      ? match
                                      : `https://${match}`;
                                    return `<a href="${url}" target="_blank">${match}</a>`;
                                  }
                                );
                              return parse(textWithLinksReplaced);
                            }
                          },
                        })}
                      </Stack>
                    )}

                    <Box color="fg.muted" lineHeight="tall">
                      {parse(
                        x.message
                          .replace(/(\*\*)(.*?)\1/g, '<b>$2</b>')
                          .replace(/(?:\r\n|\r|\n)/g, '<br>')
                          .replace(
                            /\[(.*?)\]\((.*?)\)/g,
                            `<a href="$2?sessions=${sessionsUsers}" target="_blank" style="text-decoration: underline; color: #5050fb;">$1</a>`
                          )
                      )}
                      <HStack justifyContent={'space-between'}>
                        <Text fontSize="xs">
                          {moment.unix(x.createdAt).fromNow()}
                        </Text>
                        <Button
                          size={'lg'}
                          variant={'none'}
                          justifyContent={'flex-end'}
                          onClick={() => handleCopy(x.message)}
                        >
                          <FiCopy />
                        </Button>
                      </HStack>
                    </Box>
                  </Stack>
                </HStack>
              ))}
              <div ref={messagesEndRef} />
            </>
          )}
        </Stack>

        {value !== '' && (
          <Stack
            bgColor={'blackAlpha.700'}
            overflowY={'scroll'}
            alignItems="center"
            justifyContent={'center'}
            h="full"
            position="absolute"
            p={10}
          >
            <Stack fontSize="lg" >
              {parse(value, {
                replace: (domNode) => {
                  if (domNode.type === 'text') {
                    const textWithLinksReplaced = domNode.data.replace(
                      /(\b(?:https?:\/\/|www\.)[^\s]+)/g,
                      (match) => {
                        const url = match.startsWith('http')
                          ? match
                          : `https://${match}`;
                        return `<a href="${url}" target="_blank">${match}</a>`;
                      }
                    );
                    return parse(textWithLinksReplaced);
                  }
                },
              })}
            </Stack>
          </Stack>
        )}
        <Stack>
          <HStack p="5" alignItems={'center'} justifyContent={'space-evenly'}>
            {/* <Button
              variant={'outline'}
              onClick={openModal}
              bg={'blackAlpha.100'}
            >
              <Icon as={BsPaperclip} fontSize="lg" color={'gray.500'} />
            </Button> */}
            <Input
              h={'50px'}
              bg={'blackAlpha.100'}
              border={'1px solid'}
              borderColor={'gray.200'}
              placeholder={('Type your message here')}
              value={chat}
              isDisabled={messageLoading}
              onChange={(e) => setChat(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleChat();
                }
              }}
            />
            {messageLoading ? (
              <Spinner />
            ) : (
              <Button
                variant={'outline'}
                bg={'blackAlpha.100'}
                h={'50px'}
                onClick={handleChat}
                isDisabled={messageLoading || chat.trim() === ''}
              >
                <Icon as={IoSend} fontSize="lg" color={'gray.500'} />
              </Button>
            )}
          </HStack>
        </Stack>
      </Stack>

      <DropboxUploader
        accessTokenDb={accessTokenDb}
        isActive={isModalOpen}
        onClose={closeModal}
        parentPath={`/${companyId || 'edrus'}/chat`}
        shareLink={shareLink}
        setShareLink={handleShareLinkChange}
      />
    </Stack>
  );
}

export default ChatViewSop;
