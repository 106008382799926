/* eslint-disable react/prop-types */
import React, { useEffect, useRef } from 'react';
import { Stack } from '@chakra-ui/react';
import { Chart } from 'chart.js/auto';

function ChartsPieKpi({ data, generate }) {
  const chartCanvasRef = useRef(null);
  const chartInstanceRef = useRef(null);

  useEffect(() => {
    const chartData = {
      labels: [
        ('Nett Profit'),
        ('Expense Ads'),
        ('Expense Product'),
        ('Expense Team'),
      ],
      datasets: [
        {
          data: [
            data?.nettProfit,
            data?.budgetMarketing,
            data?.expenseProduct,
            data?.expenseTeam,
          ], // data hardcode
          backgroundColor: [
            'rgba(255, 105, 97, 0.6)', // Soft Red
            'rgba(119, 221, 119, 0.6)', // Soft Green
            'rgba(119, 158, 203, 0.6)', // Soft Blue
            'rgba(255, 179, 71, 0.6)', // Soft Yellow
          ],
        },
      ],
    };

    // Destroy previous chart instance if exists
    if (chartInstanceRef.current) {
      chartInstanceRef.current.destroy();
    }

    // Create new chart instance
    const ctx = chartCanvasRef.current.getContext('2d');
    const newChartInstance = new Chart(ctx, {
      type: 'pie',
      data: chartData,
    });

    // Set the new chart instance
    chartInstanceRef.current = newChartInstance;

    // Cleanup function to destroy the chart instance on component unmount
    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }
    };
  }, [generate]);

  return (
    <Stack className="pie-chart" spacing={2} width="300px" height="300px">
      <canvas ref={chartCanvasRef}></canvas>
    </Stack>
  );
}

export default ChartsPieKpi;
