/* eslint-disable react/prop-types */
import { Button, HStack, Heading, Stack, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { getSingleDocumentFirebase } from '../../../Api/firebaseApi'

function SopViewTemplate({ data, onClose }) {

  const [dataDetail, setDataDetail] = useState({})

  const toast = useToast()

  const getData = async () => {
    try {
      const res = await getSingleDocumentFirebase('sop', data?.id)
      setDataDetail(res)
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
        duration: 3000
      });
    }
  }

  useEffect(() => {
    getData()

    return () => {
      setDataDetail({})
    }
  }, [data?.id])

  const handleCopy = (id) => {
    navigator.clipboard.writeText(id);
    toast({
      title: 'Deoapp',
      description: 'Copy to clipboard.',
      status: 'success',
    });
  };


  return (
    <Stack spacing={5}>
      <Heading size={'sm'}>{dataDetail?.description}</Heading>

      <div
        dangerouslySetInnerHTML={{ __html: dataDetail?.dataHtml || data?.data }}
        style={{ fontSize: '13px', width: '100%', overflow: 'hidden' }}
      />

      <HStack pb={5} alignItems={'flex-end'} justifyContent={'flex-end'}>
        <Button colorScheme='red'  onClick={onClose}>
          Close
        </Button>
        <Button
          colorScheme="blue"
          onClick={() => handleCopy(dataDetail?.dataHtml || data?.data)}
          variant={'outline'}
        >
          Copy
        </Button>
      </HStack>
    </Stack>
  )
}

export default SopViewTemplate