/* eslint-disable react/prop-types */
import React from 'react';
import {
  Stack,
  VStack,
  HStack,
  Text,
  Spacer,
  AvatarGroup,
  Avatar,
  useColorMode,
  Badge,
  Tag,
} from '@chakra-ui/react';

function CardMyKanban({
  Icon,
  onNavigate,
  title,
  userArray,
  tags, categoryId, type, categories
}) {

  const { colorMode } = useColorMode();


  return (
    <Stack shadow="base" >
      <VStack align="flex-start" w="full" h="full" justify="space-between"
        bgColor={colorMode === 'dark' ? 'gray.700' : 'white'}

      >
        <HStack spacing={3} px={4} pt={4} w="full" cursor={'pointer'} onClick={onNavigate}>
          <Stack cursor="pointer" >
            {Icon && <Icon size={50} />}
          </Stack>
          <Stack>
            <Text
              textTransform="capitalize"
              cursor="pointer"
              noOfLines={1}
              fontSize="sm"
              fontWeight="medium"
              onClick={onNavigate}
            >{title}
            </Text>
            {tags?.length > 0 && (
              <HStack>
                {tags?.map((x, i) => {
                  return(
                    <Badge colorScheme='green' key={i} fontSize={'2xs'} size={'2xs'}>{x}</Badge>
                  )
                } )}
              </HStack>
            )}
          </Stack>
        </HStack>
        {categoryId?.length > 0 &&

        <Stack px={3}>
          <Text
            fontWeight={500}
            cursor="pointer"
            fontSize={'10px'}
            noOfLines={1}
          >
            {'Category'}
          </Text>
          <HStack overflowX={'auto'}  sx={{
            '&::-webkit-scrollbar': {
              w: '1',
              h: '1',
            },
            '&::-webkit-scrollbar-track': {
              w: '1',
              h: '1',
            },
            '&::-webkit-scrollbar-thumb': {
              borderRadius: '10',
              bg: 'gray.300',
            },
          }} w={'200px'} >
            {categoryId?.map((category, index) => {
              return (
                <Stack key={index}>
                  <Tag size={'sm'} colorScheme="gray">
                    <Text noOfLines={1} fontSize={'xs'}>{categories?.find((x) => x?.id === category)?.name}</Text>
                  </Tag>
                </Stack>
              );
            })}
          </HStack>
        </Stack>}
        <HStack
          alignItems="center"
          justifyContent="space-around"
          w="full"
          p={3}
          mt={3}
          cursor={'pointer'}
          onClick={onNavigate}
        >
          <Stack spacing={0}>
            <Text spacing={3} fontSize="2xs">
              Project: All Project
            </Text>
           
          </Stack>
          <Spacer />
          {type && <Badge colorScheme='yellow' fontSize={'2xs'} size={'2xs'}>{type}</Badge>}
          {userArray?.length > 0 && (
            <AvatarGroup
              cursor="pointer"
              size="sm"
              max={5}
            >
              {userArray?.map((user, i) => (
                <Avatar key={i} name={user.name} />
              ))}
            </AvatarGroup>
          )}
        </HStack>
      </VStack>
    </Stack>
  )
}

export default CardMyKanban