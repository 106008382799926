import {  HStack, Heading, Input, SimpleGrid, Spacer,  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Stack, } from '@chakra-ui/react'
import React from 'react'
import BackButtons from '../../Components/Buttons/BackButtons'
import CardMyKanban from '../../Components/Card/CardMyKanban'
import { FcTemplate } from 'react-icons/fc'
  
function OkrTemplatePage() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  
  const data =[
    {id:1,title:'kodok',userArray:['id1','id2','id3']}
  ]
  
  const handleModal = () => {
    onOpen()
  }
  
  return (
    <Stack spacing={5} p={[1, 1, 5]}>
      <HStack>
        <BackButtons/>
        <Heading size={'md'}> KPI Template</Heading>
        <Spacer/>
        <Input placeholder='Search KPI' type='text' w='-moz-min-content'/>
      </HStack>
  
  
      <SimpleGrid columns='4' mt='2'>
        {data?.map((x, i) =>
          <CardMyKanban key={i} Icon={FcTemplate} title={x.title} onNavigate={() => handleModal(i)}/>
        )}
      </SimpleGrid>
  
  
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Modal Title</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* <Lorem count={2} /> */}
          </ModalBody>
  
          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose}>
                Close
            </Button>
            <Button variant='ghost'>Secondary Action</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  )
}
  
export default OkrTemplatePage
  