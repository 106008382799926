import { Box, Button, HStack, Input, SimpleGrid, Spacer,Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Progress,
  Text,
  Avatar,
  Badge,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  AvatarGroup,
  useDisclosure,
  Stack,
  Tooltip,
  useToast,
  Tag,
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderTrack,
  RangeSliderThumb, } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { FcDisplay, FcFile, FcNegativeDynamic, FcPositiveDynamic } from 'react-icons/fc'

import PropTypes from 'prop-types';
import { clientTypessense } from '../../../Api/Typesense';
import useUserStore from '../../../Hooks/Zustand/Store';
import { arrayRemove, arrayUnion, doc, setDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../../Config/firebase';
import { colorSchemeNumberUtil } from '../../../Utils/colorUtil';
import { useNavigate, useParams } from 'react-router-dom';
import { getUserByUid } from '../../../Utils/getUser';
import moment from 'moment';
import DynamicButton from '../../../Components/Buttons/DynamicButton';
import AlertDialogV2 from '../../../Components/AlertDialog/AlertDialogV2';
import SopViewTemplate from '../../Sop/Component/SopViewTemplate'
import periodeUtil, { calculateAverageProgressPercentage, calculateDurationTargetOkr } from '../../../Utils/periodeUtil';
import { ArrowDownIcon, ArrowUpIcon } from '@chakra-ui/icons';
import { formatFrice } from '../../../Utils/Helper';


KpiTableComponent.propTypes = {
  data: PropTypes.object.isRequired,
  setData:PropTypes.func.isRequired,
  setDataTeam:PropTypes.func.isRequired,
  dataTeam:PropTypes.array.isRequired,
};

function KpiTableComponent(props) {
  const globalState = useUserStore()
  const data = props.data;
  const param = useParams()
  const navigate = useNavigate()
  const toast = useToast()

  const [dataSearch, setDataSearch] = useState()
  const [dataDisplay, setDataDisplay] = useState()
  const [dataSop, setDataSop] = useState([])

  const { isOpen, onOpen, onClose } = useDisclosure()
  const [sopModalView, setSopModalView] = useState(false)
  const [sopActive, setSopActive] = useState({})

  const [alertDelete, setAlertDelete] = useState(false)
  const [loading, setLoading] = useState(false)
  const [active, setActive] = useState('')

  const [slider, setSlider] = useState([0, 100])
  const [sliderData, setSliderData] = useState([0, 100])
  const [sort, setSort] = useState({ name: 'statsProgress', sort: true })


  const handleAddKpi = async (x) => {

    try {
      await setDoc(doc(db, 'okr', param.id), {
        kpi: arrayUnion(x)
      }, { merge: true });

      await setDoc(doc(db, 'kpi', x), {
        okr: arrayUnion(param.id)
      }, { merge: true });

    } catch (error) {
      toast({
        title: 'Deoapp.com',
        duration: 3000,
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  }

  const handleDeleteKpi = async () => {
    setLoading(true);

    try {
      await updateDoc(doc(db, 'okr', param.id), {
        kpi: arrayRemove(active.id)
      });

      await updateDoc(doc(db, 'kpi', active.id), {
        okr: arrayRemove(param.id)
      });

    } catch (error) {
      toast({
        title: 'Deoapp.com',
        duration: 3000,
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    } finally {
      setLoading(false);
      setAlertDelete(false)
      setActive('')
      navigate(-1)
    }
  }

  const handleSearchKpi = (value) => {
    const sliderData = slider[1] === 100 ? `statsProgress:>=${slider[0]}` : `statsProgress:>=${slider[0]} && statsProgress:<=${slider[1]}`

    const searchParameters = {
      q: value?value:' ',
      query_by: 'title',
      filter_by: `${sliderData} && projectId: ${globalState.currentProject}`,

    };
    clientTypessense
      .collections('kpi')
      .documents()
      .search(searchParameters)
      .then((x) => {
        const newData = x.hits.map((y) => { return { ...y.document } })
        setDataSearch(newData)
      });
  }

  const calculateProgress = (dataOkr, dataKpi) => {
    const { periodStart, periodEnd } = periodeUtil(dataOkr);
  
    const newDataKpi = dataKpi.map((x) => {
      const { period } = x;
      const durationTargetOkr = calculateDurationTargetOkr(period, periodStart, periodEnd);
      
      return {
        ...x,
        durationTargetOkr
      };
    });

  
    return newDataKpi;
  };

  const getData = async (value) => {
    if (!data?.kpi?.length) return;

    globalState.setIsLoading(true)

    const sliderData = slider[1] === 100 ? `statsProgress:>=${slider[0]}` : `statsProgress:>=${slider[0]} && statsProgress:<=${slider[1]}`;
    const addSort = `${sort.name}:${sort.sort ? 'desc' : 'asc'}`;



    const searchParameters = {
      q: value || ' ',
      query_by: 'title',
      sort_by: addSort,
      filter_by: `${sliderData} && id: [${data.kpi.toString()}]`,
    };

    try {
      const [searchResults] = await Promise.all([
        clientTypessense.collections('kpi').documents().search(searchParameters),
      ]);

      const newData = searchResults?.hits?.map((hit) => hit?.document);

      const newDataCalculate = calculateProgress(data, newData)

      setDataDisplay(newDataCalculate);

      const averageProgress = calculateAverageProgressPercentage(newDataCalculate);


      const teamAll = newDataCalculate.flatMap((x) => x?.usersDisplay);
      const uniqueEmails = {};
      const filteredTeam = teamAll?.filter((user) => {
        if (uniqueEmails[user?.email]) {
          return false;
        } else {
          uniqueEmails[user?.email] = true;
          return true;
        }
      });


      
      props.setDataTeam({teamProgress: averageProgress,  teamArr: filteredTeam});


    } catch (error) {
      toast({
        title: 'Deoapp.com',
        duration: 3000,
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }finally{
      globalState.setIsLoading(false)

    }
  };

  useEffect(() => {
    getData()
  }, [data?.kpi, slider, sort])

  const handleAlertDelete = (x) => {
    setActive(x)
    setAlertDelete(true)
  }

  const getDataSop = async () => {
    const searchParameters = {
      q: '*',
      query_by: 'title',
      filter_by: `companyId: ${globalState.currentCompany}`,
      sort_by: '_text_match:desc',
      per_page: 100,
    };
    clientTypessense
      .collections('sop')
      .documents()
      .search(searchParameters)
      .then((x) => {
        const newData = x?.hits?.map((y) => {
          return { ...y.document };
        });
        setDataSop(newData)
      });

  }
  
  useEffect(() => {
    getDataSop()

    return () => {
    }
  }, [])

  const handleSopOpen = (data) => {
    setSopActive(data)
    setSopModalView(true)
  }

  const handleSopClose = () => {
    setSopModalView(false)
    setSopActive({})
  }


  return (
    <Box>
      <SimpleGrid p="2" columns={5} gap="2">
        <Stack>
          {/* <Select placeholder='Assignor' w='full'>
            <option >Assignor#1</option>
          </Select> */}
        </Stack>
        <Stack>
          {/* <Select placeholder='Assignee' w='full'>
            <option >Assignee#1</option>
          </Select> */}
        </Stack>
        <HStack>
          {/* <Input type='date' placeholder='Start Date' />
          <Input type='date' placeholder='End Date' /> */}
        </HStack>
        <HStack>
          {/* <Input type='number' placeholder='Start Progress' />
          <Input type='number' placeholder='End Progress' /> */}
        </HStack>
        <DynamicButton
          onClick={onOpen}
          action={'create'}
          title={('Add Kpi')}
          variant={'solid'}
        />
      </SimpleGrid>

      <HStack spacing={0}>
        <Text fontSize="sm" fontWeight={500}>
          {('Search By Progress')}
        </Text>
        <RangeSlider
          defaultValue={slider}
          min={0}
          max={100}
          step={5}
          onChange={(val) => setSliderData(val)}
          onChangeEnd={(val) => setSlider(val)}
        >
          <RangeSliderTrack bg="red.100" mr="2">
            <RangeSliderFilledTrack bg="tomato" />
          </RangeSliderTrack>
          <RangeSliderThumb displayName="kodok" boxSize={8} index={0}>
            <Text fontSize="2xs">{sliderData[0]}%</Text>
          </RangeSliderThumb>
          <RangeSliderThumb boxSize={8} index={1}>
            <Tooltip label={sliderData[1] === 100 ? '100%+' : sliderData[1]}>
              <Text fontSize="2xs">
                {sliderData[1] === 100 ? '100%+' : sliderData[1]}
              </Text>
            </Tooltip>
          </RangeSliderThumb>
        </RangeSlider>
      </HStack>

      <TableContainer>
        <Table overflowX="auto">
          <Thead>
            <Tr>
              <Th textAlign="center">KPI</Th>
              <Th
                textAlign="center"
                onClick={() =>
                  setSort({ name: 'statsProgress', sort: !sort.sort })
                }
              >
                Status & {('Progress')}
                {sort.name === 'statsProgress' ? (
                  sort.sort ? (
                    <ArrowDownIcon />
                  ) : (
                    <ArrowUpIcon />
                  )
                ) : (
                  <></>
                )}
              </Th>
              <Th>{('Pic')}</Th>
              <Th>{('Assignee')}</Th>
              <Th>{('Period')}</Th>
              <Th>{('File')}</Th>
              <Th>{('Updated')}</Th>
              <Th>{('Action')}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {dataDisplay?.map((x, i) => (
              <Tr key={i}>
                <Td>
                  <HStack
                    spacing={1}
                    alignItems={'flex-start'}
                    justifyContent={'flex-start'}
                  >
                    <Stack>
                      <Icon as={FcDisplay} boxSize={10} />
                    </Stack>
                    <Stack>
                      <Tooltip label={x.title} aria-label="A tooltip">
                        <Stack spacing={1}>
                          <Text
                            cursor="pointer"
                            fontWeight={500}
                            noOfLines={1}
                            onClick={() => navigate(`/kpi/list/${x.id}`)}
                            fontSize={'lg'}
                          >
                            {x.title}
                          </Text>
                          <Text fontWeight={500} fontSize="xs">
                            {('Assignor')}:{' '}
                            {getUserByUid(globalState?.users, x?.createdBy)
                              ?.email || ''}
                          </Text>
                        </Stack>
                      </Tooltip>
                      <HStack overflowX={'auto'} w={'200px'}>
                        {x?.tags?.length > 0 &&
                          x?.tags?.map((y, index) => {
                            return (
                              <Stack key={index}>
                                <Tag size={'sm'} colorScheme="green">
                                  <Text fontSize={'xs'}>{y}</Text>
                                </Tag>
                              </Stack>
                            );
                          })}
                      </HStack>
                    </Stack>
                  </HStack>
                </Td>
                <Td>
                  <HStack>
                    <Box>
                      <Text textAlign="right" fontSize="2xs">
                        {x?.isAvarage
                          ? `${('Average')}: ${parseInt(x.statsAverage)}`
                          : `Total: ${formatFrice(x.statsTotalActual)}`}
                      </Text>
                      <Progress
                        borderRadius="md"
                        value={x?.statsProgress / x.durationTargetOkr}
                        colorScheme={colorSchemeNumberUtil(x.statsProgress)}
                        minW="36"
                      />
                      <Text textAlign="right" fontSize="2xs">
                        Target:{' '}
                        {x.isAvarage
                          ? parseInt(x.target)
                          : formatFrice(
                            parseInt(x.target) * x.durationTargetOkr
                          )}
                      </Text>
                    </Box>
                    {x?.achivement === 'high' ? (
                      <HStack>
                        <Icon as={FcPositiveDynamic} boxSize={10} />
                      </HStack>
                    ) : (
                      <HStack>
                        <Icon as={FcNegativeDynamic} boxSize={10} />
                      </HStack>
                    )}
                    <Badge
                      borderRadius="md"
                      w="full"
                      textAlign="center"
                      fontSize="xl"
                      p="2"
                      colorScheme={colorSchemeNumberUtil(
                        x?.statsProgress / x?.durationTargetOkr
                      )}
                    >
                      {formatFrice(x?.statsProgress / x?.durationTargetOkr)}%
                    </Badge>
                  </HStack>
                </Td>
                <Td>
                  {x?.pic?.length > 0 && (
                    <AvatarGroup cursor="pointer" size="sm" max={4}>
                      {x?.pic.map((user, i) => {
                        return (
                          <Avatar
                            key={i}
                            size={'sm'}
                            name={user?.name}
                            src={user?.image || user?.name}
                          />
                        );
                      })}
                    </AvatarGroup>
                  )}
                </Td>
                <Td>
                  {x?.usersDisplay?.length > 0 && (
                    <AvatarGroup cursor="pointer" size="sm" max={4}>
                      {x?.usersDisplay.map((user, i) => {
                        return (
                          <Avatar
                            key={i}
                            size={'sm'}
                            name={user?.name}
                            src={user?.image || user?.name}
                          />
                        );
                      })}
                    </AvatarGroup>
                  )}
                </Td>
                <Td>
                  <Badge
                    w={'full'}
                    textAlign="center"
                    colorScheme={
                      x?.period === 'daily'
                        ? 'green'
                        : x?.period === 'weekly'
                          ? 'yellow'
                          : 'blue'
                    }
                  >
                    {x?.period?.toUpperCase()}{' '}
                  </Badge>
                </Td>
                <Td>
                  {x?.sopId?.length > 0 && (
                    <HStack spacing={-1}>
                      {x?.sopId?.map((y, i) => {
                        const findSop = dataSop?.find((x) => x.id === y);

                        return (
                          <Tooltip
                            key={i}
                            label={findSop?.title}
                            aria-label="Name"
                          >
                            <Box cursor={'pointer'}>
                              <FcFile
                                size={25}
                                onClick={() => handleSopOpen(findSop)}
                              />
                            </Box>
                          </Tooltip>
                        );
                      })}
                    </HStack>
                  )}
                </Td>
                <Td>
                  <Stack>
                    <Text fontSize="xs" fontWeight={500}>
                      {moment.unix(x.lastUpdated).fromNow()}
                    </Text>
                  </Stack>
                </Td>
                <Td>
                  <HStack>
                    <DynamicButton action={'read'} variant={'solid'} onClick={() => handleSopOpen(x)}/>
                    <DynamicButton
                      action={'delete'}
                      variant={'solid'}
                      onClick={() => handleAlertDelete(x)}
                    />
                  </HStack>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Modal isOpen={isOpen} onClose={onClose} size="2xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{('Add Kpi For Project')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              <Input
                type="text"
                placeholder={('Search Kpi')}
                onChange={(e) => handleSearchKpi(e.target.value)}
              />

              {dataSearch?.map((x, i) => (
                <HStack key={i} m="1" p="1" shadow="base">
                  <Box>
                    <FcDisplay size="35" />
                  </Box>
                  <Box>
                    <Text fontSize="lg">{x.title}</Text>
                    <Text fontSize="sm">{x.description}</Text>
                  </Box>
                  <Spacer />
                  <Button
                    colorScheme="green"
                    onClick={() => handleAddKpi(x.id)}
                  >
                    +
                  </Button>
                </HStack>
              ))}
            </Box>
          </ModalBody>

          <ModalFooter>
            {/* <Button colorScheme='blue' mr={3} onClick={onClose}>
              Close
            </Button>
            <Button variant='ghost'>Secondary Action</Button> */}
          </ModalFooter>
        </ModalContent>
      </Modal>

      <AlertDialogV2
        isLoading={loading}
        isOpen={alertDelete}
        onClose={() => setAlertDelete(false)}
        title={`Delete Kpi ${data?.title}`}
        onAction={handleDeleteKpi}
      />

      <Modal isOpen={sopModalView} onClose={handleSopClose} size={'2xl'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{sopActive?.title || 'Untitled'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <SopViewTemplate data={sopActive} onClose={handleSopClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default KpiTableComponent
