import React from 'react'
import MainMenuComponent from '../../Components/Menus/MainMenuComponent'

function SopPage() {
  return (
    <MainMenuComponent menuName='SOP' />

  )
}

export default SopPage
