import {
  Box, HStack, Heading, Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Spacer,
  Input,
  Progress,
  Text,
  Avatar,
  Badge,
  SimpleGrid,
  Icon,
  AvatarGroup,
  Select,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Stack,
  useToast,
  Tooltip,
  Tag,
  Button
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import BackButtons from '../../Components/Buttons/BackButtons'
import { FcElectricalSensor } from 'react-icons/fc'
import { useNavigate } from 'react-router-dom'
import OkrNewComponent from './Component/OkrNewComponent'
import useUserStore from '../../Hooks/Zustand/Store'
import { clientTypessense } from '../../Api/Typesense'
import moment from 'moment'
import { colorSchemeNumberUtil } from '../../Utils/colorUtil'
import DynamicButton from '../../Components/Buttons/DynamicButton'
import AlertDialogV2 from '../../Components/AlertDialog/AlertDialogV2'
import { deleteDocumentFirebase, getCollectionFirebase } from '../../Api/firebaseApi'
import { getUserByUid } from '../../Utils/getUser'
import AlertArrayNull from '../../Components/AlertDialog/AlertArrayNull'
import OkrEditComponent from './Component/OkrEditComponent'
import periodeUtil, { calculateAverageProgressPercentage, calculateDurationTargetOkr } from '../../Utils/periodeUtil'

function OkrListPage() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [dataOrigin, setDataOrigin] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const globalState = useUserStore();

  const [alertDelete, setAlertDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState('');

  const [modalEdit, setModalEdit] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 8;

  const toast = useToast();

  const handleOkrOpen = (data) => {
    setActive(data);
    setAlertDelete(true);
  };
  const handleOkrClose = () => {
    setAlertDelete(false);
    setActive('');
  };

  const getProgress = async (data, periodeStart, periodeEnd) => {
    // const coll = collection(db, 'kpi');
    // const q = query(coll, where('okr', 'array-contains', data.id));

    // const snapshot = await getAggregateFromServer(q, {
    //   average: average('statsProgress')
    // });

    let progressValue = '';

    let filteredTeam = [];

    if (data?.kpi?.length > 0) {
      const searchParameters = {
        q: '',
        query_by: 'title, tags',
        filter_by: `id: [${data.kpi.toString()}]`,
      };

      const [searchResults] = await Promise.all([
        clientTypessense
          .collections('kpi')
          .documents()
          .search(searchParameters),
      ]);

      const newData = searchResults?.hits?.map((hit) => hit.document);

      const newDataKpi = newData.map((x) => {
        const { period } = x;
        const durationTargetOkr = calculateDurationTargetOkr(
          period,
          periodeStart,
          periodeEnd
        );

        return {
          ...x,
          durationTargetOkr,
        };
      });

      progressValue = calculateAverageProgressPercentage(newDataKpi);

      const teamAll =
        newData?.length > 0 &&
        newData.flatMap((x) => x?.usersDisplay?.map((y) => y));
      const teamAllArr = teamAll || [];

      const uniqueEmails = {};
      filteredTeam = teamAllArr?.filter((user) => {
        if (uniqueEmails[user?.email]) {
          return false; // Email sudah ada, tidak termasuk dalam hasil filter
        } else {
          uniqueEmails[user?.email] = true;
          return true; // Email unik, termasuk dalam hasil filter
        }
      });
    }

    return { progress: progressValue, dataTeam: filteredTeam };
  };

  const getData = async () => {
    globalState.setIsLoading(true);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const conditions = [
      {
        field: 'companyId',
        operator: '==',
        value: globalState.currentCompany,
      },
      {
        field: 'projectId',
        operator: '==',
        value: globalState.currentProject,
      },
    ];
    const sortBy = { field: 'lastUpdated', direction: 'desc' };
    const limitValue = startIndex + itemsPerPage;
    try {
      const x = await getCollectionFirebase(
        'okr',
        conditions,
        sortBy,
        limitValue
      );

      const newDataWithProgress = await Promise.all(
        x.map(async (x) => {
          const { periodStart, periodEnd } = periodeUtil(x);

          const progress = await getProgress(x, periodStart, periodEnd);

          return { ...x, ...progress, periodStart, periodEnd };
        })
      );

      setData(newDataWithProgress);
      setDataOrigin(newDataWithProgress);
      setTotalPages(Math.ceil(newDataWithProgress.length / itemsPerPage));
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        duration: 3000,
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    } finally {
      globalState.setIsLoading(false);
    }
  };

  const handleTypesenseSearch = async (q, filter) => {
    try {
      const arr = Object.entries(filter || {});
      const searchParameters = {
        q: q ? q : '*',
        query_by: 'title, tags',
        filter_by: `projectId:${globalState.currentProject} && companyId:${
          globalState.currentCompany
        } ${arr?.map((x) => `&&${x[0]}:${x[1]}`).join(' ')}`,
        per_page: q ? 8 : itemsPerPage,
        page: q ? 1 : currentPage,
      };

      const timeoutPromise = new Promise((resolve, reject) => {
        setTimeout(() => {
          reject(new Error('Request timeout'));
        }, 10000); // Timeout dalam milidetik (contoh: 5000ms)
      });

      const searchPromise = clientTypessense
        .collections('okr')
        .documents()
        .search(searchParameters);

      const res = await Promise.race([searchPromise, timeoutPromise]);

      const newData = res?.hits?.map((y) => ({ ...y.document }));

      const newDataWithProgress = await Promise.all(
        newData.map(async (x) => {
          const { periodStart, periodEnd } = periodeUtil(x);

          const progress = await getProgress(x, periodStart, periodEnd);

          return { ...x, ...progress, periodStart, periodEnd };
        })
      );

      if (q || filter) {
        setData([...newDataWithProgress]);
      } else {
        setData(dataOrigin);
      }

      setTotalPages(Math.ceil(res.found / itemsPerPage));
    } catch (error) {
      toast({
        title: ('Error!'),
        description: error.message,
        duration: 3000,
        status: 'error',
      });
    }
  };

  useEffect(() => {
    getData();
  }, [globalState.currentProject, globalState.currentCompany, currentPage]);

  // useEffect(() => {
  //   handleTypesenseSearch();
  // }, [search]);

  const handleLoadMore = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const handleDeleteFile = async () => {
    setLoading(true);

    if (data.createdBy !== globalState.uid) {
      if (
        globalState.roleCompany !== 'owner' &&
        globalState.roleProject === 'user'
      ) {
        return toast({
          title: ('Alert'),
          description: ('You dont have any access to do this action'),
          status: 'warning',
          duration: 9000,
          isClosable: true,
        });
      }
    }
    try {
      await deleteDocumentFirebase('okr', active?.id);

      toast({
        title: 'Success',
        description: 'okr file deleted',
        duration: 3000,
        status: 'success',
      });
    } catch (error) {
      toast({
        title: ('Error!'),
        description: 'Failed to delete okr file' + error,
        duration: 3000,
        status: 'error',
      });
    } finally {
      setLoading(false);
      setAlertDelete(false);
      setActive('');
      navigate(-1);
    }
  };

  const handleEditOpen = (data) => {
    setModalEdit(true);
    setActive(data);
  };

  const handleEditClose = () => {
    setActive('');
    setModalEdit(false);
  };

  return (
    <Stack p={[1, 1, 5]} spacing={5}>
      <HStack>
        <BackButtons />
        <Box>
          <Heading size={'md'}>OKR</Heading>
        </Box>
      </HStack>
      <SimpleGrid shadow="base" p="2" columns={[1, 3, 5]} gap="2">
        <Select
          placeholder={('Period')}
          onChange={(e) => {
            handleTypesenseSearch('', { periodType: e.target.value });
          }}
        >
          <option value="Q">{('Quarter')}</option>
          <option value="y">{('Annual')}</option>
        </Select>

        <Spacer />
        <Spacer />
        {/* <Input type='text' />
        <Input type='text' /> */}
        <Input
          type="text"
          placeholder={('Search')}
          onChange={(e) => handleTypesenseSearch(e.target.value)}
        />
        <DynamicButton
          action={'create'}
          title={('add Okr')}
          onClick={onOpen}
          variant={'solid'}
        />
      </SimpleGrid>

      {data?.length > 0 ? (
        <TableContainer>
          <Table overflowX="auto">
            {/* <TableCaption>Imperial to metric conversion factors</TableCaption> */}
            <Thead>
              <Tr>
                <Th textAlign="center">{('Objective')}</Th>
                <Th>Status & {('Progress')}</Th>
                <Th>KPI</Th>
                <Th>{('Assignor')}</Th>
                <Th>{('Assignee')}</Th>
                <Th>{('Period')}</Th>
                <Th>{('Start')}</Th>
                <Th>{('End')}</Th>
                <Th>{('Action')}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data?.map((x, i) => {
                return (
                  <Tr key={i}>
                    <Td>
                      <HStack>
                        <Icon as={FcElectricalSensor} boxSize={10} />
                        <Stack>
                          <Text
                            fontWeight={500}
                            cursor="pointer"
                            noOfLines={1}
                            onClick={() => navigate(`/okr/view/${x.id}`)}
                          >
                            {x.title}
                          </Text>
                          <HStack overflowX={'auto'}  sx={{
                            '&::-webkit-scrollbar': {
                              w: '2',
                              h: '3',
                            },
                            '&::-webkit-scrollbar-track': {
                              w: '2',
                              h: '3',
                            },
                            '&::-webkit-scrollbar-thumb': {
                              borderRadius: '10',
                              bg: 'gray.300',
                            },
                          }} w={'200px'}>
                            {x?.tags?.length > 0 &&
                              x?.tags?.map((y, index) => {
                                return (
                                  <Stack key={index}>
                                    <Tag size={'sm'} colorScheme="green">
                                      <Text fontSize={'xs'}>{y}</Text>
                                    </Tag>
                                  </Stack>
                                );
                              })}
                          </HStack>
                        </Stack>
                      </HStack>
                    </Td>
                    <Td>
                      <HStack>
                        <Progress
                          borderRadius="md"
                          value={x.progress}
                          colorScheme={colorSchemeNumberUtil(x.progress)}
                          minW="36"
                        />
                        <Badge
                          borderRadius="md"
                          colorScheme={colorSchemeNumberUtil(x.progress)}
                          w="full"
                          textAlign="center"
                          fontSize="xl"
                          p="2"
                        >
                          {x.progress ? parseInt(x.progress) : 0}%
                        </Badge>
                      </HStack>
                    </Td>
                    <Td>
                      <Badge
                        borderRadius="md"
                        colorScheme="teal"
                        w="full"
                        textAlign="center"
                        fontSize="xl"
                        p="2"
                      >
                        {x?.kpi?.length ? x.kpi.length : 0}
                      </Badge>
                    </Td>
                    <Td>
                      <Tooltip
                        key={i}
                        label={
                          getUserByUid(globalState?.users, x?.createdBy)
                            ?.name || ''
                        }
                        aria-label="Name"
                      >
                        <Avatar
                          size={'sm'}
                          name={
                            getUserByUid(globalState?.users, x?.createdBy)
                              ?.name || ''
                          }
                          src={
                            getUserByUid(globalState?.users, x?.createdBy)
                              ?.image || ''
                          }
                        />
                      </Tooltip>
                    </Td>
                    <Td>
                      {x?.dataTeam?.length > 0 && (
                        <AvatarGroup cursor="pointer" size="sm" max={4}>
                          {x?.dataTeam.map((user, i) => {
                            return (
                              <Avatar
                                key={i}
                                size={'sm'}
                                name={user?.name}
                                src={user?.image || user?.name}
                              />
                            );
                          })}
                        </AvatarGroup>
                      )}
                    </Td>
                    <Td>
                      <Badge
                        fontSize="md"
                        p="1"
                        colorScheme={
                          x?.periodType === 'Q'
                            ? 'green'
                            : x?.periodType === 'y'
                              ? 'blue'
                              : 'yellow'
                        }
                      >
                        {`${x?.periodType?.toUpperCase()}${moment
                          .unix(x?.periodTimestamp)
                          .startOf(x.periodType)
                          .add(
                            x.periodTarget,
                            x.periodType === 'Q' ? 'quarters' : 'years'
                          )
                          .format('Q-YYYY')}`}
                      </Badge>
                    </Td>
                    <Td>
                      <Text fontSize={'sm'} fontWeight={500}>
                        {moment.unix(x?.periodStart).format('YYYY-MM-DD')}
                      </Text>
                    </Td>
                    <Td>
                      <Text fontSize={'sm'} fontWeight={500}>
                        {moment.unix(x?.periodEnd).format('YYYY-MM-DD')}
                      </Text>
                    </Td>
                    <Td>
                      <HStack>
                        <DynamicButton
                          action={'update'}
                          variant={'solid'}
                          onClick={() => handleEditOpen(x)}
                        />
                        <DynamicButton
                          action={'delete'}
                          variant={'solid'}
                          onClick={() => handleOkrOpen(x)}
                        />
                      </HStack>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
      ) : (
        <AlertArrayNull titleData={'OKR'} action={('Add Okr')} />
      )}

      <Stack alignItems={'center'} justifyContent="center" py={5}>
        <Box>
          {currentPage <= totalPages && (
            <Button colorScheme={'blue'} fontSize="sm" onClick={handleLoadMore}>
              {('Load More')}
            </Button>
          )}
        </Box>
      </Stack>

      <Modal isOpen={isOpen} onClose={onClose} size="2xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{('add Okr')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* <KpiNewPage/> */}
            <OkrNewComponent />
          </ModalBody>
        </ModalContent>
      </Modal>

      <AlertDialogV2
        isLoading={loading}
        isOpen={alertDelete}
        onClose={handleOkrClose}
        title={(`delete Okr ${active?.title}`)}
        onAction={handleDeleteFile}
      />

      <Modal isOpen={modalEdit} onClose={handleEditClose} size="2xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{('Edit')} KPI</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <OkrEditComponent
              dataOkr={active}
              onClose={handleEditClose}
              globalState={globalState}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Stack>
  );
}

export default OkrListPage;