import React, { useEffect } from 'react'
import { Navigate, useParams, useSearchParams } from 'react-router-dom';
import { signInWithCustomToken } from 'firebase/auth';
import { useToast } from '@chakra-ui/react';
import useUserStore from '../../Hooks/Zustand/Store';
import { auth } from '../../Config/firebase';

const RedirectElementSessions = () => {
  const [searchParam] = useSearchParams()

  const toast = useToast()
  const param = useParams()

  const globalState = useUserStore();


  const sessions = searchParam.get('sessions')
  const companyId = searchParam.get('companyId')
  const projectId = searchParam.get('projectId')

  const loginSessions = async () => {
    localStorage.clear();

    try {
      const userCredential = await signInWithCustomToken(auth, sessions);
      const user = userCredential.user;
      if(user){

        toast({
          title: 'Login Successful',
          description: `You have successfully logged in as ${user.email} `,
          status: 'success',
          duration: 3000,
          isClosable: true,
        }); 
        if(companyId || projectId){
          globalState.setCurrentCompany(companyId)
          globalState.setCurrentProject(projectId)
          localStorage.setItem('currentCompany', companyId);
          localStorage.setItem('currentProject', projectId);

        }

    
      }
    } catch (error) {
      toast({
        title: 'Login Error',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      }); 
    }

  }


  useEffect(() => {
    if (sessions === null) return
    loginSessions()

    return () => {
    }
  }, [sessions])
  

  if(param?.segmen5){
    return <Navigate to={`/${param?.segmen1}/${param?.segmen2}/${param?.segmen3}/${param.segmen4}/${param.segmen5}`} />
  }

  if(param?.segmen4){
    return <Navigate to={`/${param?.segmen1}/${param?.segmen2}/${param?.segmen3}/${param.segmen4}`} />
  }
  if(param?.segmen3){
    return <Navigate to={`/${param?.segmen1}/${param?.segmen2}/${param?.segmen3}`} />
  }
  if(param?.segmen2){
    return <Navigate to={`/${param?.segmen1}/${param?.segmen2}`} />
  }
  else{
    return <Navigate to={`/${param?.segmen1}`} />
  }

}

export default RedirectElementSessions