import {
  Box, Button, HStack, Heading, Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton, Input, SimpleGrid, Spacer, useDisclosure, Stack, useToast,
  Select,
  Text
} from '@chakra-ui/react'
import React, { useState } from 'react'
import BackButtons from '../../Components/Buttons/BackButtons'
import DynamicButton from '../../Components/Buttons/DynamicButton'
import CardMyKanban from '../../Components/Card/CardMyKanban'
import { useNavigate } from 'react-router-dom'
import { addDocumentFirebase, getCollectionFirebase } from '../../Api/firebaseApi'
import useUserStore from '../../Hooks/Zustand/Store'
import { useEffect } from 'react'
import { onGetClientTypesenseFront } from '../../Api/Typesense'
import { FcFolder, FcImport, FcMoneyTransfer, FcPackage, FcTodoList } from 'react-icons/fc'
import AlertArrayNull from '../../Components/AlertDialog/AlertArrayNull'
import SopModalTemplate from './Component/SopModalTemplate'

function SopListPage() {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [newTitle, setNewTitle] = useState();
  const [data, setData] = useState({sop : [], typeActive:'all',search:'', totalPages:1, currentPage:1, typeFilter:{type:'', categoryId: ''}, categories:  []});
  const globalState = useUserStore();
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  // hardcode data
  const dataType = ['all', 'income', 'product',  'expense' ];
  const adminId = '0BZDboI1UP5SoXFV1Z4n';
  const itemsPerPage = 12;

  const [modalTemplate, setModalTemplate] = useState(false);

  const handleSave = async () => {
    if (!newTitle) return;
    setLoading(true);
    try {
      const res = await addDocumentFirebase(
        'sop',
        {
          title: newTitle,
          projectId: globalState.currentProject,
          isTemplate: false,
        },
        globalState.currentCompany
      );
      navigate('/sop/list/' + res);
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        duration: 3000,
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleTypesenseSearch = async (q) => {
    globalState.setIsLoading(true);
    //typesense parameters
    const query = q ? q : '*';
    let fieldName = 'title, tags';
    const sort = '_text_match:desc';
    let filter = `companyId:=[${globalState?.currentCompany}]`;
 
    if (data?.typeFilter?.type) {
      fieldName += ',type' ;
      filter += ` && type:="${data?.typeFilter?.type}"`;
    }
    if (data?.typeFilter?.categoryId) {
      fieldName += ',categoryId' ;
      filter += ` && categoryId:=[${data?.typeFilter?.categoryId}]`;
    }
    try {
      const searchResults = await onGetClientTypesenseFront(
        query,
        'sop',
        fieldName,
        filter,
        itemsPerPage,
        data?.currentPage,
        sort
      );

      const hits = searchResults.hits.map((hit) => hit.document);
      if (data?.currentPage === 1) {
        setData((prev) => ({
          ...prev,
          sop: hits,
          totalPages: Math.ceil(searchResults?.found / itemsPerPage)
        }));
      } else {
        setData((prev) => ({
          ...prev,
          sop: [...data.sop, ...hits],
          totalPages: Math.ceil(searchResults?.found / itemsPerPage)
        }));
      }
      globalState.setIsLoading(false);
    } catch (error) {
      globalState.setIsLoading(false);
      toast({
        title: 'Deoapp.com',
        duration: 3000,
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  const getDataCategories = async () => {
    try{
      globalState.setIsLoading(true);
      const resCategories = await getCollectionFirebase('sop_categories', [{ field: 'companyId', operator: '==', value: adminId }], null, null, null)
      setData((prev) => ({...prev, categories:resCategories}))
      globalState.setIsLoading(false);
    } catch (error) {
      globalState.setIsLoading(false);
      toast({
        title: 'Deoapp.com',
        duration: 3000,
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };
  useEffect(() => {
    handleTypesenseSearch(data?.search)
  }, [globalState.currentCompany, data?.currentPage, data?.typeFilter]);

  useEffect(() => {
    getDataCategories()
  }, [])
  const handleLoadMore = () => {
    setData((prev) => ({...prev, currentPage: (prev?.currentPage + 1)}))
  };

  const handleOpenModal = () => {
    setModalTemplate(true);
  };

  const handleCloseModal = () => {
    setModalTemplate(false);
  };

  return (
    <Stack p={[1, 1, 5]}>
      <HStack  flexDirection={['column', 'column', 'row']}>
        <BackButtons />
        <Heading size={'md'}> SOP</Heading>
        <Spacer />
        <Stack align={'end'}>
          <HStack>
            <HStack>
              <Input
                variant={'outline'}
                placeholder={'Search Sop'}
                onChange={(e) => setData((prev) => ({...prev, search: e.target.value, currentPage:1}))}
                type="text"
                w="sm"
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleTypesenseSearch(e.target.value);
                  }
                }}
              />
              <DynamicButton  action={'read'} variant={'outline'} onClick={() => handleTypesenseSearch(data?.search)} />
            </HStack>
            <DynamicButton
              title={('Add Sop')}
              action={'create'}
              variant={'solid'}
              onClick={onOpen}
            />

            <DynamicButton
              title={('See Template')}
              action={'read'}
              variant={'solid'}
              onClick={handleOpenModal}
            />

          </HStack>
          <Select  maxW={{
            md: '2xs',
          }}
          value={data?.typeFilter?.categoryId}
          placeholder="Filter All Categories" onChange={(e) => {
            setData((prev) => ({...prev, typeFilter: {...prev.typeFilter, categoryId: e.target.value}, currentPage:1}))
          }
          }
          >
            {data?.categories?.map((category, i) => { return(
              <option key={i} value={category?.id}>{category?.name}</option>
            )})}
          </Select>
        </Stack>
      </HStack>
      <Stack p={[1, 1, 5]} >
        {dataType.length > 0 && (
          <SimpleGrid columns={[1, 2, 4]} gap={5}>
            {dataType.map((x, index) => {
              const dataView =
                x === 'product' ? (
                  <FcPackage  size={50} />
                ) : x === 'income' ? (
                  <FcMoneyTransfer  size={50} />
                )  : x === 'expense' ? (
                  <FcImport  size={50} />
                )  : (
                  <FcFolder   size={50} />
                );
              return (
                <Stack
                  borderRadius={'md'}
                  borderWidth={2}
                  shadow={'md'}
                  borderColor={x === data?.typeActive ? '#ffd600' : 'blackAlpha.300'}
                  key={index}
                  cursor="pointer"
                  onClick={() => setData((prev) => ({...prev, typeActive: x, currentPage:1, typeFilter: {...prev.typeFilter, type: x === 'all' ? '' : x}}))}
                >
                  <Stack
                    alignItems={'center'}
                    h="150px"
                    justifyContent="center"
                  >
                    <Box>{dataView}</Box>
                    <Text textTransform={'capitalize'}>{x}</Text>
                  </Stack>
                </Stack>
              );
            })}
          </SimpleGrid>
        )}
      </Stack>
      {data?.sop?.length > 0 ? (
        <SimpleGrid columns={{base: 1, sm: 2,md: 3, xl: 4}} mt="2" gap="2">
          {data?.sop?.map((x, i) => (
          
            <CardMyKanban
              key={i}
              Icon={FcTodoList}
              onNavigate={() => navigate(`/sop/list/${x.id}`)}
              title={x.title}
              userArray={x.userArray}
              tags={x.tags}
              categories={data?.categories}
              categoryId={x.categoryId}
              type={x.type}
            />
          ))}
        </SimpleGrid>
      ) : (
        <AlertArrayNull titleData={'SOP'} action={('Add Sop')} />
      )}

      <Stack alignItems={'center'} justifyContent="center" py={5}>
        <Box>
          {data?.currentPage < data?.totalPages && (
            <Button colorScheme={'blue'} fontSize="sm" onClick={handleLoadMore}>
              {('Load More')}
            </Button>
          )}
        </Box>
      </Stack>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{('Add Sop')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              type="text"
              placeholder={('Title')}
              onChange={(e) => setNewTitle(e.target.value)}
            />
            {/* <Lorem count={2} /> */}
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="green"
              isLoading={loading}
              mr={3}
              onClick={() => handleSave()}
            >
              {('Save')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <SopModalTemplate
        isOpen={modalTemplate}
        onClose={handleCloseModal}
        globalStateProps={globalState}
      />
    </Stack>
  );
}

export default SopListPage;

