/* eslint-disable */
import React from 'react';
import SopViewPage from '../Pages/Sop/SopViewPage';
import KpiPage from '../Pages/Kpi/KpiPage';
import KpiListPage from '../Pages/Kpi/KpiListPage';
import KpiViewPage from '../Pages/Kpi/KpiViewPage';
import KpiTemplatePage from '../Pages/Kpi/KpiTemplatePage';
import KpiSimulatorPage from '../Pages/Kpi/KpiSimulatorPage';

const KpiRouter = [
    {
        path: '/kpi',
        element: <KpiPage />,
    },
    {
        path: '/kpi/list',
        element: < KpiListPage />,
    },
    {
        path: '/kpi/list/:id',
        element: <KpiViewPage />,
    },
    {
        path: '/kpi/template',
        element: <KpiTemplatePage />,
    },
    {
        path: '/kpi/simulator',
        element: <KpiSimulatorPage />,
      },
];

export default KpiRouter;
