import { create } from 'zustand';

const companyItem = localStorage.getItem('currentCompany');
const pojectItem = localStorage.getItem('currentProject');


const useUserStore = create((set) => ({
  isLoggedIn: false,
  isLoading: false,
  companies: [],
  projects: [],
  currentCompany: companyItem || '',
  currentProject: pojectItem || '',
  profileKey: '',
  uid: '',
  name: '',
  email: '',
  currentXenditId: '',
  roleCompany: '',
  roleProject: '',
  authFirebaseToken : '',
  users: [],
  accessToken: '',
  anggaranData: {},
  anggaranId:'',
  userType: 'user',
  accountingData:{},
  expired:false,
  role: {},
  apps: [],



  setUid: (data) => {
    set({
      uid: data,
    });
  },

  setName: (data) => {
    set({
      name: data,
    });
  },

  setEmail: (data) => {
    set({
      email: data,
    });
  },

  setIsLoggedIn: (data) => {
    set({
      isLoggedIn: data,
    });
  },

  setIsLoading: (data) => {
    set({
      isLoading: data,
    });
  },

  setCompanies: (data) => {
    set({
      companies: data,
    });
  },

  setCurrentCompany: (data) => {
    set({
      currentCompany: data,
    });
  },

  setProjects: (data) => {
    set({
      projects: data,
    });
  },

  setCurrentProject: (data) => {
    set({
      currentProject: data,
    });
  },

  setRoleCompany: (data) => {
    set({
      roleCompany: data,
    });
  },

  setRoleProject: (data) => {
    set({
      roleProject: data,
    });
  },

  setCurrentXenditId: (data) => {
    set({
      currentXenditId: data,
    });
  },

  setUsers: (data) => {
    set({
      users: data,
    });
  },
  setAccessToken: (data) => {
    set({
      accessToken: data,
    });
  },
  setAnggaranData: (data) => {
    set({
      anggaranData:data,
    });
  },

  setAccountingData: (data) => {
    set({
      accountingData:data,
    });
  },
  setAnggaranId: (data) => {
    set({
      anggaranId:data,
    });
  },

  setAuthFirebaseToken: (data) => {
    set({
      authFirebaseToken:data,
    });
  },

  setExpired:(data) => {
    set({
      expired:data
    })
  },

  setUserType:(data) => {
    set({
      userType:data
    })
  },

  setRole:(data) => {
    set({
      role:data
    })
  },

  setApps: (data) => {
    set({
      apps: data
    })
  }
}));

export default useUserStore;
